import React, { useEffect, useState, useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { useSelector } from "react-redux";
import * as THREE from "three";
import { tare } from "../../../../Connect/BluetoothHandler";
import "../Sphere/Sphere.css";
const Sphere = () => {
  let { deviceinfo } = useSelector((store) => store.devicedata);
  let [spherePositions, setSpherePositions] = useState({
    sphere1Pos: null,
    sphere2Pos: null,
    sphere3Pos: null,
  });

  let [displayone, setDisplayone] = useState();
  let [displaytwo, setDisplaytwo] = useState();
  let [provideCoordinate, setProvideCoordinate] = useState({
    sphere1Pos: false,
    sphere2Pos: false,
    sphere3Pos: false,
  });

  useEffect(() => {
    if (deviceinfo[0]?.mode === 4) {
      const sphere1Pos = [0, 0, 0];
      setProvideCoordinate((prev) => ({
        ...prev,
        sphere1Pos: true,
      }));
      setSpherePositions((prev) => ({
        ...prev,
        sphere1Pos,
      }));
    }

    if (provideCoordinate.sphere1Pos) {
      const sphere2Pos = [
        deviceinfo[0]?.yp * 2 || 0, // x
        deviceinfo[0]?.zp * 2 || 0, // y
        deviceinfo[0]?.xp * 2 || 0, // z
      ];
      setDisplayone(sphere2Pos);
      setProvideCoordinate((prev) => ({
        ...prev,
        sphere2Pos: true,
      }));
      setSpherePositions((prev) => ({
        ...prev,
        sphere2Pos,
      }));
    }

    if (provideCoordinate.sphere1Pos && provideCoordinate.sphere2Pos) {
      const sphere3Pos = [
        (deviceinfo[0]?.yp + deviceinfo[1]?.yp) * 2 || 0, // x
        (deviceinfo[0]?.zp + deviceinfo[1]?.zp) * 2 || 0, // y
        (deviceinfo[0]?.xp + deviceinfo[1]?.xp) * 2 || 0, // z
      ];

      setDisplaytwo(sphere3Pos);
      setProvideCoordinate((prev) => ({
        ...prev,
        sphere3Pos: true,
      }));
      setSpherePositions((prev) => ({
        ...prev,
        sphere3Pos,
      }));
    }
  }, [deviceinfo, provideCoordinate.sphere1Pos, provideCoordinate.sphere2Pos]);

  return (
    <div className="container">
      <button
        type="button"
        className="btn btn-outline-primary"
        onClick={() => {
          tare(0);
          tare(1);
        }}
      >
        Tare
      </button>

      {/* Display sphere positions */}
      <div className="sphere-positions">{displayone}</div>

      <div>
        {"Sphere 2"}
        {displaytwo}
      </div>

      <div className="">
        <Canvas
          style={{
            width: "100%",
            height: "100%",
            border: "1px solid black",
          }}
        >
          <directionalLight color="white" intensity={1} position={[5, 10, 5]} castShadow />
          {/* Spheres */}
          {provideCoordinate.sphere1Pos && provideCoordinate.sphere2Pos && provideCoordinate.sphere3Pos && (
            <>
              <SphereComponent position={spherePositions.sphere1Pos} size={[1 * 0.5, 32, 32]} color="blue" />
              <SphereComponent position={spherePositions.sphere2Pos} size={[1 * 0.5, 32, 32]} color="orange" />
              <SphereComponent position={spherePositions.sphere3Pos} size={[1 * 0.5, 32, 32]} color="red" />

              {/* Connecting Lines */}
              <ConnectingLine start={spherePositions.sphere1Pos} end={spherePositions.sphere2Pos} />
              <ConnectingLine start={spherePositions.sphere2Pos} end={spherePositions.sphere3Pos} />
            </>
          )}
        </Canvas>
      </div>
    </div>
  );
};

const SphereComponent = ({ position, size, color }) => {
  return (
    <mesh position={position}>
      <sphereGeometry args={size} />
      <meshBasicMaterial color={color} />
    </mesh>
  );
};

const ConnectingLine = ({ start, end }) => {
  const ref = useRef();
  useFrame(() => {
    if (ref.current) {
      ref.current.geometry.setFromPoints([new THREE.Vector3(...start), new THREE.Vector3(...end)]);
    }
  });

  return (
    <line ref={ref}>
      <bufferGeometry />
      <lineBasicMaterial color="black" />
    </line>
  );
};

export default Sphere;
