import React, { useRef } from "react";
import Navbar from "../../Components/Navbar";

export default function Info() {
  // Empty dependency array ensures this effect runs only once after the initial render

  let range = useRef();

  let r = () => {
    console.log(range.current.value);
  };
  return (
    <>
      <Navbar activeNavtab={"Info"} />
      <section className="bg-light-red p-5 h-100 w-100">
        <input
          type="range"
          ref={range}
          className="form-range"
          defaultValue="0"
        />
        <button onClick={r}>th</button>
      </section>
    </>
  );
}
