import React, { useEffect, useState } from "react";
import "./FlappyBird.css";
import PlayerData from "../../../../../Components/PlayerData";
import Slider from "./Slider";
import { useSelector } from "react-redux";
import { tare } from "../../../../Connect/BluetoothHandler";

const FlappyBird = ({ playerData }) => {
  let { deviceinfo } = useSelector((store) => store.devicedata);

  let [value, setValue] = useState({
    value1: 0, //speed
    value2: 0, //sustain
    value3: 0, //randomness
    value4: 0, //precision
  });

  let handleSliderChange = (sliderId, newValue) => {
    setValue((prevValue) => ({
      ...prevValue,
      [sliderId]: newValue,
    }));
  };

  const [displayrpy, setDisplayrpy] = useState({
    select: "",
    rpy: 0,
    rpyvalue: 0,
  });

  const [birdPosition, setBirdPosition] = useState(90); // Initial position in vh
  const [isGameOver, setIsGameOver] = useState(false);
  const [pipes, setPipes] = useState([]);
  const [pipeSpeed, setPipeSpeed] = useState(0.33); // Adjusted speed for vh/vw       speed
  const [pipeGap, setPipeGap] = useState(35); // Pipe gap in vh      precision       adjust code
  const [pipeGenerationPosition, setPipeGenerationPosition] = useState(66); // Position in vw to generate new pipes
  const [heightMin, setHeightMin] = useState(85); // Min height in vh (adjusted)
  const [heightMax, setHeightMax] = useState(5); // Max height in vh (adjusted)
  const [lastGapPositions, setLastGapPositions] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  const [consecutivePipes, setConsecutivePipes] = useState(10); // max min pipe max interval    randomness     add formula
  const [gapRepeatCount, setGapRepeatCount] = useState(3); // repeat max and min pipe     sustain
  const [currentGapRepeat, setCurrentGapRepeat] = useState(0);
  const [ROM, setROM] = useState(0); // Range of Motion
  const [ROMLimit, setROMLimit] = useState(100); // ROM limit   end position in mod/ angle
  const [initialPipeSpeed, setInitialPipeSpeed] = useState(0.33); // Store initial speed   speed
  const [pipeLeftEdge, setpipeLeftEdge] = useState(100);
  const [pipeRightEdge, setpipeRightEdge] = useState(106);
  const [birdBox, setBirdBox] = useState({});

  let handeldisplayrpy = (rpy, rpyvalue, select) => {
    setDisplayrpy({ rpy: rpy, rpyvalue: rpyvalue, select: select });
    setROMLimit(Math.abs(rpyvalue));
    setROM(Math.abs(rpyvalue));
  };

  useEffect(() => {
    if (displayrpy.select === "roll") {
      if (ROMLimit > Math.abs(deviceinfo[0]?.roll)) {
        setROM(
          Math.max(
            (Math.abs(deviceinfo[0]?.roll) / ROMLimit) * (heightMin - heightMax)
          )
        );
      } else {
        setROM((prev) => prev);
      }
    }
    if (birdBox.right > pipeLeftEdge && birdBox.left < pipeRightEdge) {
      setBirdPosition((prev) => prev);
    } else if (displayrpy.select === "pitch") {
      setBirdPosition(deviceinfo[0]?.pitch);
    } else if (displayrpy.select === "yaw") {
      setBirdPosition(deviceinfo[0]?.yaw);
    }
  }, [
    deviceinfo,
    displayrpy.select,
    ROMLimit,
    heightMax,
    heightMin,
    birdBox.bottom,
    birdBox.left,
    birdBox.right,
    birdBox.top,
    pipeLeftEdge,
    pipeRightEdge,
    pipes.bottomHeight,
    pipes.topHeight,
  ]);

  // const handleKeyPress = useCallback(
  //   (event) => {
  //     if (birdPosition < 80) {
  //       setROM((prev) => Math.max(prev - 1 / ROMLimit, 0));
  //       console.log("above");
  //       if (
  //         birdBox.right > pipeLeftEdge &&
  //         birdBox.left < pipeRightEdge &&
  //         birdBox.top < pipes[0].topHeight
  //       ) {
  //         setROM((prev) => Math.max(prev + 1 / ROMLimit, 0));
  //         console.log(pipes[0].topHeight, "k");
  //       }
  //       setIsGameOver(false); // Reset game over state
  //     } else if (birdPosition >= 80) {
  //       console.log("below");
  //       setROM((prev) => Math.min(prev + 1 / ROMLimit, 1)); // Decrease ROM, ensuring it doesn't go above 1
  //       setIsGameOver(false); // Reset game over state
  //     }
  //   },
  //   [
  //     birdPosition,
  //     ROMLimit,
  //     birdBox.top,
  //     pipeLeftEdge,
  //     pipeRightEdge,
  //     pipes,
  //     birdBox.left,
  //     birdBox.right,
  //   ]
  // );

  useEffect(() => {
    setBirdPosition(ROM);
  }, [ROM]);

  // const handleVisibilityChange = () => {
  //   setIsPaused(document.hidden);
  // };

  // useEffect(() => {
  //   window.addEventListener("keydown", handleKeyPress);
  //   document.addEventListener("visibilitychange", handleVisibilityChange);
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyPress);
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, [handleKeyPress]);

  useEffect(() => {
    const generatePipe = () => {
      let gapPosition;

      if (currentGapRepeat > 0) {
        gapPosition = lastGapPositions[lastGapPositions.length - 1];
        setCurrentGapRepeat(currentGapRepeat - 1);
      } else if (
        lastGapPositions.length >= consecutivePipes &&
        !lastGapPositions.includes(heightMin)
      ) {
        gapPosition = heightMin;
        setCurrentGapRepeat(gapRepeatCount - 1);
      } else if (
        lastGapPositions.length >= consecutivePipes &&
        !lastGapPositions.includes(heightMax)
      ) {
        gapPosition = heightMax;
        setCurrentGapRepeat(gapRepeatCount - 1);
      } else {
        do {
          gapPosition = Math.random() * (heightMin - heightMax) + heightMax;
        } while (
          (lastGapPositions.length > 0 &&
            Math.abs(
              lastGapPositions[lastGapPositions.length - 1] - gapPosition
            ) < 5) || // Adjusted for vh
          (lastGapPositions.length > 1 &&
            Math.abs(
              lastGapPositions[lastGapPositions.length - 2] - gapPosition
            ) < 5) // Adjusted for vh
        );
      }

      const topHeight = gapPosition - (pipeGap * gapPosition) / 100 / 2;
      const bottomHeight =
        100 - gapPosition - (pipeGap * (100 - gapPosition)) / 100 / 2;

      setPipes((prev) => [
        ...prev,
        { id: Date.now(), topHeight, bottomHeight, left: 100 }, // Start from 100vw
      ]);

      setLastGapPositions((prev) => {
        const newPositions = [...prev, gapPosition];
        return newPositions.length > consecutivePipes
          ? newPositions.slice(1)
          : newPositions;
      });
    };

    const movePipes = () => {
      setPipes((prev) =>
        prev
          .map((pipe) => ({ ...pipe, left: pipe.left - pipeSpeed }))
          .filter((pipe) => pipe.left > -6)
      );
    };

    const intervalId = setInterval(() => {
      if (!isPaused) {
        movePipes();
        if (
          pipes.length === 0 ||
          pipes[pipes.length - 1].left <= pipeGenerationPosition
        ) {
          generatePipe();
        }
      }
    }, 30);

    return () => clearInterval(intervalId);
  }, [
    pipeSpeed,
    pipeGap,
    heightMin,
    heightMax,
    lastGapPositions,
    isPaused,
    consecutivePipes,
    gapRepeatCount,
    currentGapRepeat,
    pipeGenerationPosition,
    pipes,
  ]);

  useEffect(() => {
    if (isGameOver) {
      setPipeSpeed(0);
    } else {
      setPipeSpeed(initialPipeSpeed);
    }
  }, [isGameOver, initialPipeSpeed]);

  useEffect(() => {
    const birdBox = {
      top: birdPosition,
      bottom: birdPosition + 5, // Assuming the bird height is 5vh
      left: 10, // Assuming the bird is at 10vw from the left
      right: 15, // Assuming the bird width is 5vw
    };
    setBirdBox(birdBox);
    pipes.forEach((pipe) => {
      const pipeLeftEdge = pipe.left;
      setpipeLeftEdge(pipeLeftEdge);
      const pipeRightEdge = pipe.left + 6; // Assuming each pipe has a width of 6vw
      setpipeRightEdge(pipeRightEdge);

      if (birdBox.right > pipeLeftEdge && birdBox.left < pipeRightEdge) {
        if (
          birdBox.top < pipe.topHeight ||
          birdBox.bottom > 100 - pipe.bottomHeight - 5
        ) {
          setIsGameOver(true);
        } else {
          setIsGameOver(false);
        }
      }
    });
  }, [pipes, birdPosition]);

  return (
    <>
      <PlayerData playerData={playerData} />
      <div
        className="container-fluid my-5 px-5 row gap-5"
        style={{ height: "70vh" }}
      >
        <div className="col-4 p-5 h-100 bg-white rounded-4 ">
          <div className="row d-flex justify-content-center align-items-center fw-bolder h-25 rounded-4">
            <h1
              className="fw-bolder text-red text-center"
              style={{ fontSize: "3vw" }}
            >
              Start Position
            </h1>
          </div>
          <div
            className="row bg-red d-flex justify-content-center align-items-center fw-bolder h-75 my-4 rounded-4 clickable"
            onClick={() => {
              tare(0);
            }}
          >
            <h1
              className="fw-bolder text-white text-center"
              style={{ fontSize: "3vw" }}
            >
              Start Position
            </h1>
          </div>
        </div>
        <div className="col p-5 h-100 bg-white rounded-4">
          <div className="row d-flex justify-content-center align-items-center fw-bolder h-25 rounded-4 clickable">
            <h1
              className="fw-bolder text-red text-center"
              style={{ fontSize: "3vw" }}
            >
              End Position
            </h1>
          </div>
          <div className="row d-flex justify-content-center align-items-center fw-bolder h-75 my-4 rounded-4 gx-5">
            <div className="col-6 h-100 pb-4">
              <div
                className="row bg-red d-flex justify-content-center align-items-center fw-bolder h-50 rounded-4 clickable"
                onClick={() => {
                  handeldisplayrpy(
                    `X:${deviceinfo[0].roll}°`,
                    deviceinfo[0].roll,
                    "roll"
                  );
                }}
              >
                <h1
                  className="fw-bolder text-white text-center"
                  style={{ fontSize: "3vw" }}
                >
                  X :{deviceinfo[0].roll}
                  <sup>°</sup>
                </h1>
              </div>
              <div
                className="row bg-red d-flex justify-content-center align-items-center fw-bolder h-50 my-4 rounded-4 clickable"
                onClick={() => {
                  handeldisplayrpy(
                    `Y: ${deviceinfo[0].pitch}°`,
                    deviceinfo[0].pitch,
                    "pitch"
                  );
                }}
              >
                <h1
                  className="fw-bolder text-white text-center"
                  style={{ fontSize: "3vw" }}
                >
                  Y :{deviceinfo[0].pitch}
                  <sup>°</sup>
                </h1>
              </div>
            </div>
            <div className="col-6 h-100 pb-4">
              <div
                className="row bg-red d-flex justify-content-center align-items-center fw-bolder h-50 rounded-4 clickable"
                onClick={() => {
                  handeldisplayrpy(
                    `Z: ${deviceinfo[0].yaw}°`,
                    deviceinfo[0].yaw,
                    "yaw"
                  );
                }}
              >
                <h1
                  className="fw-bolder text-white text-center"
                  style={{ fontSize: "3vw" }}
                >
                  Z : {deviceinfo[0].yaw}
                  <sup>°</sup>
                </h1>
              </div>
              <div className="row bg-red d-flex justify-content-center align-items-center fw-bolder h-50 my-4 rounded-4">
                <h1
                  className="fw-bolder text-white text-center"
                  style={{ fontSize: "3vw" }}
                >
                  {displayrpy.rpy}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Slider onSliderChange={handleSliderChange} />
      <div className="game-area">
        <div className="bird" style={{ top: `${birdPosition}vh` }}></div>
        {pipes.map((pipe) => (
          <div key={pipe.id}>
            <div
              className="pipe"
              style={{
                top: 0,
                height: `${pipe.topHeight}vh`,
                left: `${pipe.left}vw`,
                width: "6vw",
              }}
            ></div>
            <div
              className="pipe"
              style={{
                bottom: 0,
                height: `${pipe.bottomHeight}vh`,
                left: `${pipe.left}vw`,
                width: "6vw",
              }}
            ></div>
          </div>
        ))}
        {isGameOver && <div className="game-over">Game Over</div>}
        <div>
          <label>Max Height:</label>
          <input
            type="number"
            value={heightMin}
            onChange={(e) => setHeightMin(Number(e.target.value))}
          />
        </div>
        <div>
          <label>Min Height:</label>
          <input
            type="number"
            value={heightMax}
            onChange={(e) => setHeightMax(Number(e.target.value))}
          />
        </div>
        <div>
          <label>Pipe Generation Position:</label>
          <input
            type="number"
            value={pipeGenerationPosition}
            onChange={(e) => setPipeGenerationPosition(Number(e.target.value))}
          />
        </div>
        <div>
          <label>ROM Limit:</label>
          <input
            type="number"
            value={ROMLimit}
            onChange={(e) => setROMLimit(Number(e.target.value))}
          />
        </div>
      </div>
    </>
  );
};

export default FlappyBird;
