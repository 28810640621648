import React, {
  useEffect,
  useState,
  // useRef,
  useMemo,
  useCallback,
} from "react";
import Navbar from "../../Components/Navbar";
import Plot from "react-plotly.js";
import { useSelector } from "react-redux";
import { tare } from "../Connect/BluetoothHandler";

const MAX_DATA_POINTS = 500; // Maximum number of data points to retain

export default function Admin() {
  const { deviceinfo } = useSelector((store) => store.devicedata);
  const [x, setX] = useState([]);
  const [y, setY] = useState([]);
  const [z, setZ] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  // const prevDeviceInfoRef = useRef(deviceinfo);

  const handleStartPlotting = useCallback(() => {
    setIsPaused(false);
  }, []);

  const handlePausePlotting = useCallback(() => {
    setIsPaused(true);
  }, []);

  const handleTare = useCallback(() => {
    tare(0);
  }, []);

  const handleReset = useCallback(() => {
    setX([]);
    setY([]);
    setZ([]);
  }, []);

  useEffect(() => {
    if (deviceinfo[0]?.mode === 12 && !isPaused) {
      setX((prev) => {
        const newX = [...prev, ...deviceinfo[0]?.xp];
        return newX.length > MAX_DATA_POINTS
          ? newX.slice(-MAX_DATA_POINTS)
          : newX;
      });

      setY((prev) => {
        const newY = [...prev, ...deviceinfo[0]?.yp];
        return newY.length > MAX_DATA_POINTS
          ? newY.slice(-MAX_DATA_POINTS)
          : newY;
      });

      setZ((prev) => {
        const newZ = [...prev, ...deviceinfo[0]?.zp];
        return newZ.length > MAX_DATA_POINTS
          ? newZ.slice(-MAX_DATA_POINTS)
          : newZ;
      });
    }
  }, [deviceinfo, isPaused]);

  const plotData = useMemo(
    () => [
      {
        x: x,
        y: y,
        z: z,
        mode: "lines+markers",
        type: "scatter3d",
        marker: {
          size: 5,
          opacity: 0.8,
        },
        willReadFrequently: true,
      },
    ],
    [x, y, z]
  );

  const layout = useMemo(
    () => ({
      title: "Real-time 3D Bubble Chart",
      scene: {
        aspectmode: "cube", // Maintain cubic aspect ratio
        xaxis: {
          title: "X Axis",
          autorange: false,
          range: [-1000, 1000],
          fixedrange: true,
          tickmode: "array",
          tickvals: [-1000, 0, 1000],
          scaleanchor: "y", // Link scaling with y-axis
          scaleratio: 1, // 1:1 ratio with y-axis
        },
        yaxis: {
          title: "Y Axis",
          autorange: false,
          range: [-1000, 1000],
          fixedrange: true,
          tickmode: "array",
          tickvals: [-1000, 0, 1000],
          scaleanchor: "z", // Link scaling with z-axis
          scaleratio: 1, // 1:1 ratio with z-axis
        },
        zaxis: {
          title: "Z Axis",
          autorange: false,
          range: [-1000, 1000],
          fixedrange: true,
          tickmode: "array",
          tickvals: [-1000, 0, 1000],
          scaleratio: 1, // 1:1 ratio with other axes
        },
      },
    }),
    []
  );

  return (
    <>
      <Navbar activeNavtab={"Admin"} />
      <div className="mb-2">
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={handleTare}
        >
          Tare
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={handleReset}
        >
          Reset
        </button>
        <button
          type="button"
          className="btn btn-success"
          onClick={handleStartPlotting}
        >
          Start Plotting
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={handlePausePlotting}
        >
          Pause Plotting
        </button>
      </div>
      <div style={{ width: "100%", height: "80vh" }}>
        <Plot
          data={plotData}
          layout={layout}
          useResizeHandler
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </>
  );
}
