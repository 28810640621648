import React, { useState } from "react";
import "./Slider.css";

export default function Slider({ onSliderChange }) {
  return (
    <div className="border border1-1 border-danger px-5 mb-5">
      <div
        className="row m-0 p-0 bg-white rounded-4"
        style={{ height: "100vh" }}
      >
        <RangeInput
          onSliderChange={onSliderChange}
          sliderId="value1"
          name="Speed"
        />
        <RangeInput
          onSliderChange={onSliderChange}
          sliderId="value2"
          name="Sustain"
        />
        <RangeInput
          onSliderChange={onSliderChange}
          sliderId="value3"
          name="Randomness"
        />
        <RangeInput
          onSliderChange={onSliderChange}
          sliderId="value4"
          name="Precision"
        />
      </div>
    </div>
  );
}

const RangeInput = ({ onSliderChange, sliderId, name }) => {
  let [v, setV] = useState(0);
  const handleChange = (event) => {
    onSliderChange(sliderId, event.target.value);
    setV(event.target.value);
  };

  return (
    <div className="col-6 p-0  d-flex align-items-center justify-content-center">
      <div className=" h-75 w-75 rounded-4 px-3 r ">
        <p className="text-center  fs-1 fw-bold text-red ">
          {name}
        </p>
        <div className=" h-25  d-flex align-items-center justify-content-center ">
          <input
            type="range"
            className="form-range custom-thumb position-relative "
            min="0"
            max="100"
            defaultValue="0"
            onChange={handleChange}
          />
        </div>
        <div className="h-25 d-flex align-items-center justify-content-center fs-1 fw-bold text-red ">
          <p>{v}</p>
        </div>
      </div>
    </div>
  );
};