import React, { useState, useMemo, useRef, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Cylinder, Sphere, OrbitControls, PerspectiveCamera, Text } from "@react-three/drei";
import * as THREE from "three";
import { useSelector } from "react-redux";
import { tare } from "../../../../Connect/BluetoothHandler";

// RotatedPill component
const RotatedPill = React.forwardRef(({ x1, y1, z1 }, ref) => {
  const axis = useMemo(() => new THREE.Vector3(x1, y1, z1).normalize(), [x1, y1, z1]);
  const quaternion = useMemo(() => {
    const quaternion = new THREE.Quaternion();
    const defaultAxis = new THREE.Vector3(0, 1, 0);
    quaternion.setFromUnitVectors(defaultAxis, axis);
    return quaternion;
  }, [axis]);
  const position = useMemo(() => new THREE.Vector3(x1 / 2, y1 / 2, z1 / 2), [x1, y1, z1]);
  const radius = 0.6;
  const length = 10;

  return (
    <group ref={ref} position={position.toArray()} quaternion={quaternion}>
      <Cylinder args={[radius, radius, length, 32]} castShadow receiveShadow>
        <meshStandardMaterial color="#FFFACD" />
      </Cylinder>
      <Sphere args={[radius, 32, 32]} position={[0, length / 2, 0]} castShadow receiveShadow>
        <meshStandardMaterial color="#FFFACD" />
      </Sphere>
      <Sphere args={[radius, 32, 32]} position={[0, -length / 2, 0]} castShadow receiveShadow>
        <meshStandardMaterial color="#F6E3BA" />
      </Sphere>
    </group>
  );
});

// Room component
function Room({ holdTime, completionCount }) {
  const roomSize = 40;

  // Updated positions for the text on the opposite wall
  const textPosition = [0, 10, roomSize / 2 - 1.5]; // Position of the "Hold Time" text on the opposite wall
  const rectWidth = 15; // Width of the rectangle
  const rectHeight = 15; // Height of the rectangle

  // Position of the "Count" text below the "Hold Time" text with a margin
  const margin = 6; // Margin between rectangles
  const countPosition = [0, 10 - rectHeight - margin, roomSize / 2 - 1.5]; // Adjust Y position for margin

  return (
    <>
      {/* Floor */}
      <mesh position={[0, -roomSize / 2, 0]} receiveShadow>
        <boxGeometry args={[roomSize * 2, 1, roomSize]} />
        <meshStandardMaterial color="#708090" />
      </mesh>

      {/* Walls */}
      <mesh position={[0, 0, roomSize / 2]} receiveShadow>
        <boxGeometry args={[roomSize * 2, roomSize, 1]} />
        <meshStandardMaterial color="#aec6cf" />//#81f1ff
      </mesh>
      <mesh position={[roomSize, 0, 0]} receiveShadow>
        <boxGeometry args={[1, roomSize, roomSize]} />
        <meshStandardMaterial color="#81f1ff" />
      </mesh>
      <mesh position={[-roomSize, 0, 0]} receiveShadow>
        <boxGeometry args={[1, roomSize, roomSize]} />
        <meshStandardMaterial color="#AFEEEE" />
      </mesh>

      {/* Hold Time Display */}
      <group position={textPosition} rotation={[0, Math.PI, 0]}> {/* Rotated to face the viewer */}
        {/* Black Background Rectangle */}
        <mesh position={[-28, -2, 0]}>
          <planeGeometry args={[rectWidth, rectHeight]} />
          <meshBasicMaterial color="black" />
        </mesh>

        {/* Text */}
        <Text
          position={[-28, 3, 0.01]} // Slightly raised to be above the background
          fontSize={2} // Adjust font size for better visibility
          color="white"
          anchorX="center"
          anchorY="middle"
          textAlign="center"
          depthTest={false}  // Ensures the text is always on top
        >
          Hold
        </Text>
        <Text
          position={[-28, -3, 0.01]} // Adjust position to place it below "Hold"
          fontSize={5} // Larger font size for holdTime
          color="white"
          anchorX="center"
          anchorY="middle"
          textAlign="center"
          depthTest={false}
        >
          {holdTime}
        </Text>
      </group>

      {/* Count Display */}
      <group position={countPosition} rotation={[0, Math.PI, 0]}> {/* Rotated to face the viewer */}
        {/* Black Background Rectangle */}
        <mesh position={[28, 19, 0]}>
          <planeGeometry args={[rectWidth, rectHeight]} />
          <meshBasicMaterial color="black" />
        </mesh>

        {/* Text */}
        <Text
          position={[28, 24, 0.01]} // Slightly raised to be above the background
          fontSize={2}
          color="white"
          anchorX="center"
          anchorY="middle"
          textAlign="center"
          depthTest={false}  // Ensures the text is always on top
        >
          Count  
        </Text>
        <Text
          position={[28, 18, 0.01]} // Adjust position to place it below "Hold"
          fontSize={5} // Larger font size for holdTime
          color="white"
          anchorX="center"
          anchorY="middle"
          textAlign="center"
          depthTest={false}
        >
         {completionCount}
        </Text>
      </group>
    </>
  );
}

// Function to calculate Sphere radius
const calculateSphereR = (precision) => {
  const precisionRad = precision * (Math.PI / 180);
  return 10 * Math.sqrt(2 - 2 * Math.cos(precisionRad));
};

// SphereHandler component
function SphereHandler({
  x1,
  y1,
  z1,
  currentSphereIndex,
  setCurrentSphereIndex,
  incrementCompletionCount,
  generationStopped,
  startTimer,
  setHoldTime,
  isSecondSphereActive,
  secondSpherePosition,
}) {
  const [isColliding, setIsColliding] = useState(false);
  const [holdTimeout, setHoldTimeout] = useState(null);
  const [isHolding, setIsHolding] = useState(false);
  const [holdStartTime, setHoldStartTime] = useState(null);

  // States to manage visibility of spheres
  const [sphereVisible, setSphereVisible] = useState([true, true]); // Array to handle visibility of both spheres

  // Initialize sphere positions
  const [fixedSpherePosition, setFixedSpherePosition] = useState(null);

  useEffect(() => {
    if (currentSphereIndex === 0 && fixedSpherePosition === null) {
      setFixedSpherePosition(new THREE.Vector3(x1, y1, z1));
      console.log("Initialized first sphere position:", x1, y1, z1);
    }
  }, [currentSphereIndex, x1, y1, z1, fixedSpherePosition]);

  // Use a ref to keep track of the pill's position
  const pillPosition = useRef(new THREE.Vector3(x1, y1, z1));

  useFrame(() => {
    // Update pill's position in each frame
    pillPosition.current.set(x1, y1, z1);
    const sphereRadius = calculateSphereR(5); // Calculating the sphere's collision radius

    // Start collision logic only if the second sphere is active
    if (!generationStopped && isSecondSphereActive) {
      // Handle collision and holding for the first sphere
      if (fixedSpherePosition && currentSphereIndex === 0 && sphereVisible[0]) {
        handleCollision(fixedSpherePosition, sphereRadius, 0);
      }

      // Handle collision and holding for the second sphere
      if (secondSpherePosition && currentSphereIndex === 1 && sphereVisible[1]) {
        handleCollision(secondSpherePosition, sphereRadius, 1);
      }
    }
  });

  const handleCollision = (spherePosition, sphereRadius, sphereIndex) => {
    const distanceToSphere = pillPosition.current.distanceTo(spherePosition);

    if (distanceToSphere <= sphereRadius) {
      if (!isColliding) {
        setIsColliding(true);
        setIsHolding(true);
        setHoldStartTime(Date.now());
        console.log(`Collision with sphere ${sphereIndex + 1} detected. Starting hold timer...`);

        const timeout = setTimeout(() => {
          setIsHolding(false);
          setIsColliding(false);
          const nextIndex = (sphereIndex + 1) % 2; // Toggle between 0 and 1
          setCurrentSphereIndex(nextIndex);
          setHoldTime(0); // Reset hold time

          if (sphereIndex === 1) {
            incrementCompletionCount(); // Increment completion count on the second sphere
          }
          // Toggle sphere visibility back after hold
          const newVisibility = [...sphereVisible];
          newVisibility[sphereIndex] = false;
          newVisibility[(sphereIndex + 1) % 2] = true; // Toggle visibility for the next sphere
          setSphereVisible(newVisibility);
          console.log(`Completed hold on sphere ${sphereIndex + 1}. Switching sphere.`);
        }, 3000); // Hold time duration (e.g., 3 seconds)

        setHoldTimeout(timeout);
        if (sphereIndex === 0) {
          startTimer(); // Start timer on the first sphere
        }
      } else {
        // Update hold time if inside the sphere
        setHoldTime(((Date.now() - holdStartTime) / 1000).toFixed(2));
      }
    } else {
      if (isColliding) {
        setIsColliding(false);
        setIsHolding(false);
        clearTimeout(holdTimeout);
        setHoldTime(0); // Reset hold time after collision ends
        setHoldStartTime(null); // Reset hold start time
        console.log(`Lost collision with sphere ${sphereIndex + 1}. Resetting.`);
      }
    }
  };

  return (
    <>
      {/* Render the first sphere only if it is visible */}
      {sphereVisible[0] && fixedSpherePosition && (
        <mesh position={fixedSpherePosition.toArray()} castShadow receiveShadow>
          <sphereGeometry args={[calculateSphereR(5), 32, 32]} />
          <meshStandardMaterial
            color={currentSphereIndex === 0 && isHolding ? "yellow" : "rgb(100, 255, 180)"}
            wireframe
            transparent
            opacity={0.6}
          />
        </mesh>
      )}

      {/* Render the second sphere only if it is visible */}
      {isSecondSphereActive && sphereVisible[1] && secondSpherePosition && (
        <mesh
          position={secondSpherePosition.toArray()}
          castShadow
          receiveShadow // Enable shadows for the second sphere
        >
          <sphereGeometry args={[calculateSphereR(5), 32, 32]} />
          <meshStandardMaterial
            color={
              isColliding && currentSphereIndex === 1 ? "yellow" : "rgb(100, 255, 180)"
            } // Change color to red during collision
            wireframe
            transparent
            opacity={0.6}
          />
        </mesh>
      )}
    </>
  );
}

function MainCylinder() {
  const { deviceinfo } = useSelector((store) => store.devicedata);
  const [currentSphereIndex, setCurrentSphereIndex] = useState(null);
  const [completionCount, setCompletionCount] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [generationStopped, setGenerationStopped] = useState(false);
  const [holdTime, setHoldTime] = useState(0); // Add holdTime state
  const [isSecondSphereActive, setIsSecondSphereActive] = useState(false); // State for second sphere
  const [secondSpherePosition, setSecondSpherePosition] = useState(null); // Position for second sphere

  const pillRef = useRef();

  const handleGenerateSphere = () => {
    setCurrentSphereIndex(0);
    setStartTime(null);
    setCompletionCount(0);
    setElapsedTime(0);
    setGenerationStopped(false);
    setHoldTime(0); // Reset hold time
  };

  const handleGenerateSecondSphere = () => {
    setSecondSpherePosition(new THREE.Vector3(deviceinfo[0]?.yp * 10, deviceinfo[0]?.zp * 10, deviceinfo[0]?.xp * 10)); // Set second sphere position based on current device values
    setIsSecondSphereActive(true); // Activate second sphere
  };

  const incrementCompletionCount = () => {
    setCompletionCount((prevCount) => prevCount + 1);
    setElapsedTime(((Date.now() - startTime) / 1000).toFixed(2));
  };

  const handleStopGeneration = () => {
    setGenerationStopped(true);
  };

  const startTimer = () => {
    if (startTime === null) {
      setStartTime(Date.now());
    }
  };

  useEffect(() => {
    let interval;
    if (startTime && !generationStopped) {
      interval = setInterval(() => {
        setElapsedTime(((Date.now() - startTime) / 1000).toFixed(2));
      }, 100);
    } else {
      setElapsedTime((prevTime) => prevTime);
    }
    return () => clearInterval(interval);
  }, [startTime, generationStopped]);

  return (
    <div >
      <div style={{ display: "flex", alignItems: "center", margin: "10px", gap: "10px" }}>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => {
            tare(0);
            tare(1);
          }}
        >
          Tare
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={handleGenerateSphere}
        >
          Start
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={handleGenerateSecondSphere} // Button for generating second sphere
        >
          Generate Second Sphere
        </button>
        <button
          type="button"
          className="btn btn-outline-danger"
          onClick={handleStopGeneration}
        >
          Stop
        </button>
        {/* <div style={{ fontSize: "1.2em" }}>
          Count: {completionCount}
        </div> */}

        {/* <div style={{ fontSize: "1.2em", color: "red" }}>
          Hold Time: {holdTime} Sec
        </div> */}
      </div>

      <Canvas shadows style={{ width: "100vw", height: "100vh", margin: 0, padding: 0 }}>
        <PerspectiveCamera
          makeDefault
          position={[0, 3, -7]}
          near={1}  // Increase near clipping to reduce flickering
          far={1000}
        />
        <ambientLight intensity={0.7} />  // Lowered ambient light intensity
        <directionalLight
          position={[-5, 5, -5]}
          intensity={1.5}  // Adjusted intensity for better shadows
          castShadow
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-near={1}
          shadow-camera-far={50}
          shadow-camera-left={-15}
          shadow-camera-right={15}
          shadow-camera-top={15}
          shadow-camera-bottom={-15}
        />
        <Room holdTime={holdTime} completionCount={completionCount} />

        <RotatedPill
          ref={pillRef}
          x1={deviceinfo[0]?.yp * 10}
          y1={deviceinfo[0]?.zp * 10}
          z1={deviceinfo[0]?.xp * 10}
        />

        <SphereHandler
          x1={deviceinfo[0]?.yp * 10}
          y1={deviceinfo[0]?.zp * 10}
          z1={deviceinfo[0]?.xp * 10}
          currentSphereIndex={currentSphereIndex}
          setCurrentSphereIndex={setCurrentSphereIndex}
          incrementCompletionCount={incrementCompletionCount}
          generationStopped={generationStopped}
          startTimer={startTimer}
          setHoldTime={setHoldTime}
          isSecondSphereActive={isSecondSphereActive}
          secondSpherePosition={secondSpherePosition}
        />

        <OrbitControls
          minDistance={55}
          maxDistance={55}
          zoomSpeed={1}
          enableRotate={true}
          enableZoom={true}
        />
      </Canvas>
    </div>
  );
}

export default MainCylinder;