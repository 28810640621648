import React, { Suspense, useEffect, useState } from "react";
import "./CarRace.css";
import { Canvas, useFrame } from "@react-three/fiber";
import { Physics } from "@react-three/cannon";
import { useSelector } from "react-redux";
import { mode14 } from "../../../../Connect/BluetoothHandler";

function checkCollision(cubePosition, blockPosition) {
  const cubeWidth = 10;
  const cubeHeight = 10;
  const blockWidth = 70;
  const blockHeight = 10;

  const xCollision =
    cubePosition[0] + cubeWidth / 2 > blockPosition[0] - blockWidth / 2 &&
    cubePosition[0] - cubeWidth / 2 < blockPosition[0] + blockWidth / 2;

  const yBuffer = 5;
  const yCollision =
    blockPosition[1] < cubePosition[1] + cubeHeight / 2 + yBuffer &&
    blockPosition[1] + blockHeight / 2 > cubePosition[1] - cubeHeight / 2 - yBuffer;

  return xCollision && yCollision;
}

function Cube({ position, setPosition }) {
  let { deviceinfo } = useSelector((store) => store.devicedata);
  //   const [moveDirection, setMoveDirection] = useState({ left: false, right: false });
  const [speed, setSpeed] = useState(0);

  useEffect(() => {
    if (deviceinfo[0]?.mode === 14) {
      if (deviceinfo[0]?.yaws === 1) {
        setSpeed(90);
      } else if (deviceinfo[0]?.yaws === -1) {
        setSpeed(-90);
      } else {
        setSpeed(0);
      }
    }
    // const handleKeyDown = (e) => {
    //   if (e.key === "a" || e.key === "A") setMoveDirection((dir) => ({ ...dir, left: true }));
    //   if (e.key === "d" || e.key === "D") setMoveDirection((dir) => ({ ...dir, right: true }));
    // };
    // const handleKeyUp = (e) => {
    //   if (e.key === "a" || e.key === "A") setMoveDirection((dir) => ({ ...dir, left: false }));
    //   if (e.key === "d" || e.key === "D") setMoveDirection((dir) => ({ ...dir, right: false }));
    // };
    // window.addEventListener("keydown", handleKeyDown);
    // window.addEventListener("keyup", handleKeyUp);

    // return () => {
    //   window.removeEventListener("keydown", handleKeyDown);
    //   window.removeEventListener("keyup", handleKeyUp);
    // };
  }, [deviceinfo]);

  useFrame(() => {
    let newPos = [...position];
    // if (moveDirection.left) {
    //   newPos[0] -= speed;
    // }
    // if (moveDirection.right) {
    //   newPos[0] += speed;
    // }

    // const floorWidth = 270;
    // const cubeSize = 10;
    // const leftBoundary = -floorWidth / 2 + cubeSize / 2;
    // const rightBoundary = floorWidth / 2 - cubeSize / 2;

    // newPos[0] = Math.max(leftBoundary, Math.min(speed, rightBoundary));
    newPos[0] = speed;

    setPosition(newPos);
  });

  return (
    <mesh position={position}>
      <boxGeometry args={[10, 10, 10]} />
      <meshStandardMaterial color="blue" />
    </mesh>
  );
}

function Block({ position, setPosition, speed, shouldGenerate }) {
  useFrame(() => {
    if (shouldGenerate) {
      if (position[1] < -150) {
        setPosition([position[0], 120, 0]); // Reset position if block moves out of view
      } else {
        setPosition((prevPosition) => [prevPosition[0], prevPosition[1] - speed, prevPosition[2]]);
      }
    }
  });

  if (!shouldGenerate) {
    return null; // Skip rendering this block if it shouldn't generate
  }

  return (
    <mesh position={position}>
      <boxGeometry args={[70, 10, 10]} />
      <meshStandardMaterial color="yellow" />
    </mesh>
  );
}

function Floor({ blockSpeed, blockGenerationSpeed }) {
  const [cubePosition, setCubePosition] = useState([0, -50, 0]);
  const [block1Position, setBlock1Position] = useState([0, 120, 0]);
  const [block2Position, setBlock2Position] = useState([-90, 120, 0]);
  const [block3Position, setBlock3Position] = useState([90, 120, 0]);
  const [, setCollisionCount] = useState(0);
  const [iteration, setIteration] = useState(0);

  const [shouldGenerateBlock1, setShouldGenerateBlock1] = useState(true);
  const [shouldGenerateBlock2, setShouldGenerateBlock2] = useState(true);
  const [shouldGenerateBlock3, setShouldGenerateBlock3] = useState(true);

  useFrame(() => {
    const regenerationY = -140 + blockGenerationSpeed * 20; // Adjust regeneration point based on generation speed

    if (
      checkCollision(cubePosition, block1Position) ||
      checkCollision(cubePosition, block2Position) ||
      checkCollision(cubePosition, block3Position)
    ) {
      setCollisionCount((prevCount) => prevCount + 1);
      setCubePosition([0, -50, 0]);
      setBlock1Position([0, 120, 0]);
      setBlock2Position([-90, 120, 0]);
      setBlock3Position([90, 120, 0]);
      // alert("Collision detected");
    }

    // Block generation logic: alternate between lanes
    if (block1Position[1] < regenerationY || block2Position[1] < regenerationY || block3Position[1] < regenerationY) {
      setIteration((prevIteration) => prevIteration + 1);

      if (iteration % 2 === 0) {
        // First iteration: middle block (block1) doesn't generate
        setShouldGenerateBlock1(false);
        setShouldGenerateBlock2(true);
        setShouldGenerateBlock3(true);
      } else {
        // Second iteration: randomly disable left (block2) or right (block3)
        const randomLane = Math.random() < 0.5 ? "left" : "right";
        if (randomLane === "left") {
          setShouldGenerateBlock2(false);
          setShouldGenerateBlock3(true);
        } else {
          setShouldGenerateBlock2(true);
          setShouldGenerateBlock3(false);
        }
        setShouldGenerateBlock1(true);
      }
      // Adjust block generation speed based on accuracy setting
      const generationInterval = 120 / blockGenerationSpeed; // adjust the interval based on accuracy setting
      setBlock1Position([0, generationInterval, 0]);
      setBlock2Position([-90, generationInterval, 0]);
      setBlock3Position([90, generationInterval, 0]);
    }
  });

  return (
    <>
      <mesh position={[-90, -0.5, 0]}>
        {/* Black Road (Left Road) */}
        <planeGeometry args={[89, 300]} />
        <meshStandardMaterial color="black" />
      </mesh>
      <mesh position={[0, -0.5, 0]}>
        {/* white Road (Middle Road) */}
        <planeGeometry args={[89, 300]} />
        <meshStandardMaterial color="white" />
      </mesh>
      <mesh position={[90, -0.5, 2]}>
        {/* Blue Road (Right Road) */}
        <planeGeometry args={[89, 300]} />
        <meshStandardMaterial color="grey" />
      </mesh>

      <Cube position={cubePosition} setPosition={setCubePosition} />

      <Block
        position={block1Position}
        setPosition={setBlock1Position}
        speed={blockSpeed}
        shouldGenerate={shouldGenerateBlock1}
      />
      <Block
        position={block2Position}
        setPosition={setBlock2Position}
        speed={blockSpeed}
        shouldGenerate={shouldGenerateBlock2}
      />
      <Block
        position={block3Position}
        setPosition={setBlock3Position}
        speed={blockSpeed}
        shouldGenerate={shouldGenerateBlock3}
      />
    </>
  );
}

function CameraSetup() {
  useFrame(({ camera }) => {
    camera.position.set(0, 10, 150); // Set top-down view position
    camera.lookAt(0, 10, 0); // Lock the camera to look at the center
  });
  return null;
}

function CarRace() {
  const [blockSpeed, setBlockSpeed] = useState(0.4);
  const [blockGenerationSpeed, setBlockGenerationSpeed] = useState(1); // new state for block generation speed

  const handlePrecisionChange = (e) => {
    const newSpeed = parseFloat(e.target.value);
    setBlockSpeed(newSpeed);
  };

  const handleAccuracyChange = (e) => {
    // define handleAccuracyChange here
    const newGenerationSpeed = parseFloat(e.target.value);
    setBlockGenerationSpeed(newGenerationSpeed);
  };

  return (
    <div className="first">
      <div style={{ flexGrow: 1 }}>
        <Canvas style={{ width: "100%", height: "100%" }}>
          <Physics>
            <ambientLight intensity={0.5} />
            <directionalLight position={[2, 5, 5]} />
            <Suspense fallback={null}>
              <Floor blockSpeed={blockSpeed} blockGenerationSpeed={blockGenerationSpeed} />
            </Suspense>
            <CameraSetup />
          </Physics>
        </Canvas>
      </div>

      {/* Precision Bar */}
      <div className="precision-bar">
        <label>Speed: </label>
        <input type="range" min="0.1" max="2" step="0.1" value={blockSpeed} onChange={handlePrecisionChange} />
        <span>{blockSpeed.toFixed(1)}x Speed</span>
      </div>
      {/* Accuracy Bar */}
      <div className="accuracy-bar">
        <label>Accuracy: </label>
        <input type="range" min="0.5" max="5" step="0.1" value={blockGenerationSpeed} onChange={handleAccuracyChange} />
        <span>{blockGenerationSpeed.toFixed(1)}x Generation Speed</span>
      </div>

      <div>
        <button
          type="button"
          className="btn btn-warning"
          onClick={() => {
            mode14();
          }}
        >
          Mode 14
        </button>
      </div>
    </div>
  );
}

export default CarRace;
