let GameSelection = (activeTab, name) => {
  if (activeTab === "Upper Extremity") {
    if (name === "Hand Flexion") {
      console.log("BBBBBBBBBBBBB");
      return "BolbGame";
    } else if (name === "Leg Flexion") {
      console.log("lllllll");
      return "FlappyBird";
    } else if (name === "Shoulder Flexion") {
      return "NewBolb";
    } else if (name === "Elbow Flexion") {
      return "Sphere";
    } else {
      console.log("kkkkkkkkk");
    }
  } else if (activeTab === "Full Body") {
    if (name === "BalanceAssesment") {
      console.log("workouBalance");
      return "BalanceAssesment";
    }
  } else if (activeTab === "Lower Extremity") {
    return null;
  } else {
    return null;
  }
};

export default GameSelection;
