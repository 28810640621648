import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { tare } from "../../../Connect/BluetoothHandler";
import calculateCoordinates from './BalanceAssesmentScore'

export default function BalanceAssessment() {
  const canvasRef = useRef(null);

  let [level, setLevel] = useState({ difficulty: "Easy", difficultyPercentage: 0.75 });

  let { currentView } = useSelector((store) => store.currentView); // Access the currentView from Redux store

  let { deviceinfo } = useSelector((store) => store.devicedata);

  let [scorePlot, setScorePlot] = useState({
    visible: false,
    ap: [],
    mt: [],
  });

  let [plotValue, setPlotValue] = useState({
    ap: [],
    mt: [],
  });

  let [button, setButton] = useState({
    start: true,
    stop: false,
    saveAndNotSave: false,
  });

  let [countDown, setCountDown] = useState({
    boolean: false,
    count: 15,
    minute: 0,
    second: 15,
  });

  let [centreDegree, setCenterDegree] = useState(15);
  let [balanceAssessmentScore, setBalancdAssessmentScore] = useState({
    centre: 0,
    anterior: 0,
    posterior: 0,
    left: 0,
    right: 0,
  });

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const width = canvas.width;
    const height = canvas.height;
    const radius = Math.min(width, height) / 2;

    // Clear canvas
    ctx.clearRect(0, 0, width, height);
    ctx.strokeStyle = "transparent";
    ctx.stroke();

    // // Move origin to center
    // ctx.translate(width / 2, height / 2);

    // Draw circle
    ctx.beginPath();
    ctx.arc(height / 2, height / 2, radius, 0, 2 * Math.PI);
    ctx.fillStyle = "#33B3A6";
    ctx.fill();
    ctx.closePath();

    // LevelCircle
    ctx.beginPath();
    ctx.arc(height / 2, height / 2, radius * level.difficultyPercentage, 0, 2 * Math.PI);
    ctx.strokeStyle = "red";
    ctx.stroke();
    ctx.closePath();

    // // Draw x-axis and y-axis
    // ctx.beginPath();
    // // ctx.moveTo(-radius, 0);
    // ctx.lineTo(height / 2, 0);
    // // ctx.moveTo(0, -radius);
    // ctx.lineTo(height / 2, height);

    // ctx.strokeStyle = "gray";
    // ctx.stroke();

    // ctx.beginPath();
    // ctx.lineTo(0, height / 2);
    // ctx.lineTo(height, height / 2);
    // ctx.strokeStyle = "gray";
    // ctx.stroke();

    // Draw diagonal lines bisecting the quadrants
    if (scorePlot.visible) {
      ctx.beginPath();
      // First diagonal line (1st and 3rd quadrants)
      ctx.moveTo(-radius / 2 ** (1 / 2) + height / 2, radius / 2 ** (1 / 2) + height / 2); // Start from top left
      ctx.lineTo(radius / 1.4142 + height / 2, -radius / 1.4142 + height / 2); // End at bottom right
      // Second diagonal line (2nd and 4th quadrants)
      ctx.moveTo(-radius / 1.4142 + height / 2, -radius / 1.4142 + height / 2); // Start from bottom left
      ctx.lineTo(radius / 1.4142 + height / 2, radius * 0.7071 + height / 2); // End at top right
      ctx.strokeStyle = "blue"; // Color for the bisecting lines
      ctx.lineWidth = 2; // Width for the lines
      ctx.stroke();
    }

    // Scale factor for better visualization
    const range = 20;

    if (scorePlot.visible) {
      scorePlot.ap.forEach((point, index) => {
        ctx.beginPath();
        ctx.arc(
          ((scorePlot.mt[index] / range) * height) / 2 + height / 2,
          (-(point / range) * height) / 2 + height / 2,
          height / 80,
          0,
          2 * Math.PI
        ); // Negative y for canvas coordinates
        ctx.fillStyle = "yellow";
        ctx.fill();
        ctx.closePath();
      });
    } else {
      // Plot each point
      plotValue.ap.forEach((point) => {
        ctx.beginPath();
        ctx.arc(
          ((plotValue.mt[0] / range) * height) / 2 + height / 2,
          (-(point / range) * height) / 2 + height / 2,
          height / 20,
          0,
          2 * Math.PI
        ); // Negative y for canvas coordinates
        ctx.fillStyle = "yellow";
        ctx.fill();
        ctx.closePath();
      });
    }

    // return () => {
    //   // Reset canvas translation on cleanup
    //   ctx.translate(-width / 2, -height / 2);
    // };
  }, [plotValue.ap, plotValue.mt, scorePlot.ap, scorePlot.mt, scorePlot.visible, level.difficultyPercentage]);

  useEffect(() => {
    let intervalId;

    if (countDown.boolean && (countDown.minute > 0 || countDown.second > 0)) {
      intervalId = setInterval(() => {
        setCountDown((prev) => {
          if (prev.second > 0) {
            return { ...prev, second: prev.second - 1 };
          } else if (prev.minute > 0) {
            return { ...prev, minute: prev.minute - 1, second: 59 };
          } else {
            clearInterval(intervalId);
            // setButton({ start: true, stop: false, saveAndNotSave: true });
            return { ...prev, boolean: false, second: 0 };
          }
        });
      }, 1000);
    } else if (countDown.boolean && countDown.minute === 0 && countDown.second === 0) {
      // Ensure the interval is cleared when the countdown reaches zero
      clearInterval(intervalId);
      setButton({ start: false, stop: false, saveAndNotSave: true });

      setScorePlot((prev) => ({
        ...prev,
        visible: true,
      }));

      handleClick(countDown.count);
    }

    return () => clearInterval(intervalId);
  }, [countDown]);

  useEffect(() => {
    if (currentView !== "BalanceAssessment") {
      setCountDown({ boolean: false, count: 15, minute: 0, second: 15 });
      setButton({ start: true, stop: false, saveAndNotSave: false });
    }
  }, [currentView]);

  useEffect(() => {
    if (deviceinfo[0]?.mode === 2) {
      let ap = -1 * deviceinfo[0].ap;
      let mt = deviceinfo[0].mt;

      // Calculate distance from center
      let distance = Math.sqrt(ap * ap + mt * mt);

      // If distance is greater than 20, adjust the values
      if (distance > 20) {
        let factor = 20 / distance;
        ap *= factor;
        mt *= factor;
      }

      setPlotValue({
        ap: [ap],
        mt: [mt],
      });

      if (scorePlot.visible === false && button.start === false) {
        console.log("update");
        setScorePlot((prev) => ({
          ...prev,
          ap: [...prev.ap, ap],
          mt: [...prev.mt, mt],
        }));
      }
    }
  }, [deviceinfo, scorePlot.visible, button.start]);

  useEffect(() => {
    if (button.saveAndNotSave) {
      const result = calculateCoordinates(scorePlot.ap, scorePlot.mt, centreDegree);
      setBalancdAssessmentScore(result);
    }
  }, [button.saveAndNotSave, scorePlot.ap, scorePlot.mt, centreDegree]);

  const handleClick = (time) => {
    setCountDown({
      boolean: false,
      count: time,
      minute: Math.floor(time / 60),
      second: time % 60,
    });
  };

  return (
    <div className="mt-5">
      <style>
        {`
          .disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        `}
      </style>
      <div className="mx-5">
        <div className="bg-white text-red mt-5 mb-3 py-2 text-center fs-3 fw-bold">Levels</div>
        <div className={`row m-0 p-0 text-center fs-3 bg-white ${!button.start ? "disabled" : ""}`}>
          <div
            className={`col m-0 py-2 ${level.difficulty === "Easy" ? "text-white bg-red" : "text-red bg-white"}`}
            onClick={() => {
              if (button.start) {
                setLevel({ difficulty: "Easy", difficultyPercentage: 0.75 });
                setCenterDegree(15);
              }
            }}
          >
            Easy
          </div>
          <div
            className={`col m-0 py-2 ${level.difficulty === "Medium" ? "text-white bg-red" : "text-red bg-white"}`}
            onClick={() => {
              if (button.start) {
                setLevel({ difficulty: "Medium", difficultyPercentage: 0.5 });
                setCenterDegree(15);
              }
            }}
          >
            Medium
          </div>
          <div
            className={`col m-0 py-2 ${level.difficulty === "Hard" ? "text-white bg-red" : "text-red bg-white"}`}
            onClick={() => {
              if (button.start) {
                setLevel({ difficulty: "Hard", difficultyPercentage: 0.25 });
                setCenterDegree(5);
              }
            }}
          >
            Hard
          </div>
        </div>
        <div className={`row mx-0 my-3 p-0 text-center fs-3 bg-white ${!button.start ? "disabled" : ""}`}>
          <div
            className={`col m-0 py-2 ${countDown.count === 15 ? "text-white bg-red" : "text-red bg-white"}`}
            onClick={() => {
              if (button.start) handleClick(15);
            }}
          >
            15
          </div>
          <div
            className={`col m-0 py-2 ${countDown.count === 30 ? "text-white bg-red" : "text-red bg-white"}`}
            onClick={() => {
              if (button.start) handleClick(30);
            }}
          >
            30
          </div>
          <div
            className={`col m-0 py-2 ${countDown.count === 45 ? "text-white bg-red" : "text-red bg-white"}`}
            onClick={() => {
              if (button.start) handleClick(45);
            }}
          >
            45
          </div>
          <div
            className={`col m-0 py-2 ${countDown.count === 120 ? "text-white bg-red" : "text-red bg-white"}`}
            onClick={() => {
              if (button.start) handleClick(120);
            }}
          >
            2 Minutes
          </div>
        </div>
        <div className="row mx-0 my-3 p-0  bg-white">
          <div className="col-9 m-0 py-2   text-red bg-white">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum vitae dolores veniam eveniet sequi,
            suscipit asperiores, voluptas quaerat minima natus recusandae dignissimos facilis assumenda modi voluptate
            fugit quos similique rerum?
          </div>
          <div className="col-3 m-0 py-1   text-red bg-white">
            {" "}
            <button
              type="button"
              className="btn bg-red rounded-pill w-75 fs-3 text-white  shadow"
              onClick={() => tare(0)}
            >
              Calibrate
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="row  mt-1">
          <div className="col m-0 p-0 d-flex  justify-content-center align-items-center">
            {button.start ? (
              <button
                type="button"
                className="btn bg-red rounded-pill w-75 fs-3 text-white my-3 shadow"
                onClick={() => {
                  setCountDown((prev) => ({
                    ...prev,
                    boolean: true,
                  }));

                  setButton({
                    start: false,
                    stop: true,
                    saveAndNotSave: false,
                  });

                  setScorePlot((prev) => ({
                    ...prev,
                    visible: false,
                    ap: [],
                    mt: [],
                  }));
                }}
              >
                Start
              </button>
            ) : button.stop ? (
              <button
                type="button"
                className="btn bg-red rounded-pill w-75 fs-3 text-white my-3 shadow"
                onClick={() => {
                  console.log(scorePlot.ap, scorePlot.mt);
                  setButton({
                    start: true,
                    stop: false,
                    saveAndNotSave: true,
                  });

                  handleClick(countDown.count);

                  setScorePlot((prev) => ({
                    ...prev,
                    visible: true,
                  }));
                }}
              >
                Stop
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="col m-0 p-0 d-flex  justify-content-center align-items-center">
            {button.saveAndNotSave && (
              <button
                type="button"
                className="btn bg-red rounded-pill w-75 fs-3 text-white my-3 shadow"
                onClick={() => {
                  setButton({ ...button, start: true, saveAndNotSave: false });
                  setScorePlot({
                    visible: false,
                    ap: [],
                    mt: [],
                  });
                }}
              >
                Don't Save
              </button>
            )}
          </div>
          <div className="col m-0 p-0 d-flex  justify-content-center align-items-center">
            {button.saveAndNotSave && (
              <button type="button" className="btn bg-red rounded-pill w-75 fs-3 text-white my-3 shadow">
                Save
              </button>
            )}
          </div>
        </div>
        <div className="row  py-4 ">
          <div className="col m-0 p-0 d-flex justify-content-center align-items-center text-red">
            <div className=" display-6">
              <div className="row py-3">
                <div className="col fw-medium text-center ">
                  <div className=" fw-medium">Time:</div>
                  <div className="">
                    {countDown.minute < 10 ? `0${countDown.minute}` : countDown.minute}:
                    {countDown.second < 10 ? `0${countDown.second}` : countDown.second}
                  </div>
                  <div className=" fw-medium">Level: </div>
                  <div className="">{level.difficulty}</div>
                  <div className=" fw-medium">Center:</div>
                  <div className="">{`${balanceAssessmentScore.centre}%`}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="col m-0  px-0 d-flex justify-content-center align-items-center position-relative">
            <canvas
              ref={canvasRef}
              width={window.innerHeight * 0.9}
              height={window.innerHeight * 0.9}
              style={{ borderRadius: "50%" }}
            />
          </div>

          <div className="col m-0 p-0 d-flex justify-content-center align-items-center text-red">
            <div className=" display-6">
              <div className="row py-3">
                <div className="col fw-medium text-start ">
                  <div className=" fw-medium">Front: </div>
                  <div className="">{` ${balanceAssessmentScore.anterior}%`}</div>
                  <div className=" fw-medium ">Back: </div>
                  <div className="">{` ${balanceAssessmentScore.posterior}%`}</div>
                  <div className=" fw-medium">Left :</div>
                  <div className="">{` ${balanceAssessmentScore.left}%`}</div>
                  <div className=" fw-medium">Right :</div>
                  <div className="">{` ${balanceAssessmentScore.right}%`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
