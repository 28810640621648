import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { pitch, position1, roll, yaw } from "../../../../Connect/BluetoothHandler";
import ProgressBar from "./ProgressBar";

export default function Counter() {
  let { deviceinfo } = useSelector((store) => store.devicedata);
  let max = deviceinfo[0].r2 === undefined ? 0 : deviceinfo[0].r2;
  // let maxValues = [max, max + 1, max + 2, max + 3];
  let P = deviceinfo[0].p === undefined ? 0 : deviceinfo[0].p;
  let value = deviceinfo[0].angle_1 === undefined ? 0 : deviceinfo[0].angle_1;
  let count = deviceinfo[0].count;
  let [seconds, setSecond] = useState(0);
  let [minute, setMinute] = useState(0);
  let [sps, setSps] = useState(0);
  let [spm, setSpm] = useState(sps);
  let [eps, setEps] = useState(0);
  let [epm, setEpm] = useState(eps);

  useEffect(() => {
    let intervalId;
    if (Math.abs(value) < 4 && P === 0 && max !== 0) {
      intervalId = setInterval(() => {
        let newseconds = (seconds + 1) % 60;
        setSecond(newseconds);

        if (newseconds === 0) {
          setMinute((prevminute) => prevminute + 1);
        }

        setSps((prevsps) => (prevsps + 1) % 60);
        if (sps === 59) {
          // setSpm(sps);
          setSpm((prevspm) => prevspm + 1);
        }
      }, 1000);
    } else if (Math.abs(max - value) < 4 && P === 1 && max !== 0) {
      intervalId = setInterval(() => {
        let newseconds = (seconds + 1) % 60;
        setSecond(newseconds);
        setEps((preveps) => (preveps + 1) % 60);
        if (newseconds === 0) {
          setMinute((prevminute) => prevminute + 1);
        }
        if (eps === 59) {
          // setSpm(sps);
          setEpm((prevepm) => prevepm + 1);
        }
      }, 1000);
    } else {
      setSecond(0);
      setMinute(0);
      if (count === 0) {
        setSps(0);
        setSpm(0);
        setEps(0);
        setEpm(0);
      }
    }
    return () => clearInterval(intervalId);
  }, [value, max, P, seconds, sps, eps, count]);

  return (
    <div>
      <div className="container-fluid py-5 m-0 bg-light-red">
        <section className="px-5 my-5">
          <div className="container-fluid px-5 row gap-5" style={{ height: "70vh" }}>
            <div className="col-4 p-5 h-100 bg-white rounded-4 ">
              <div className="row  d-flex justify-content-center align-items-center fw-bolder h-25 rounded-4">
                <h1 className="fw-bolder text-red text-center" style={{ fontSize: "3vw" }}>
                  Start Position
                </h1>
              </div>
              <div
                className="row btn bg-red  d-flex justify-content-center align-items-center fw-bolder h-75 my-4 rounded-4"
                onClick={() => position1(0)}
              >
                <h1 className="fw-bolder text-white text-center" style={{ fontSize: "3vw" }}>
                  Start Position
                </h1>
              </div>
            </div>
            <div className="col p-5 h-100 bg-white rounded-4">
              <div className="row  d-flex justify-content-center align-items-center fw-bolder h-25 rounded-4">
                <h1 className="fw-bolder text-red text-center" style={{ fontSize: "3vw" }}>
                  End Position
                </h1>
              </div>
              <div className="row d-flex justify-content-center align-items-center fw-bolder h-75 my-4 rounded-4 gx-5">
                <div className="col-6 h-100 pb-4">
                  <div
                    className="row bg-red   d-flex justify-content-center align-items-center fw-bolder h-50  rounded-4"
                    onClick={() => {
                      roll(0);
                    }}
                  >
                    <h1 className="fw-bolder text-white text-center" style={{ fontSize: "3vw" }}>
                      X :{deviceinfo[0].roll}
                      <sup>°</sup>
                    </h1>
                  </div>
                  <div
                    className="row bg-red   d-flex justify-content-center align-items-center fw-bolder h-50 my-4 rounded-4"
                    onClick={() => {
                      pitch(0);
                    }}
                  >
                    <h1 className="fw-bolder text-white text-center" style={{ fontSize: "3vw" }}>
                      Y :{deviceinfo[0].pitch}
                      <sup>°</sup>
                    </h1>
                  </div>
                </div>
                <div className="col-6 h-100 pb-4">
                  <div
                    className="row bg-red   d-flex justify-content-center align-items-center fw-bolder h-50  rounded-4"
                    onClick={() => {
                      yaw(0);
                    }}
                  >
                    <h1 className="fw-bolder text-white text-center" style={{ fontSize: "3vw" }}>
                      Z : {deviceinfo[0].yaw}
                      <sup>°</sup>
                    </h1>
                  </div>
                  <div className="row bg-red   d-flex justify-content-center align-items-center fw-bolder h-50 my-4 rounded-4">
                    <h1 className="fw-bolder text-white text-center" style={{ fontSize: "3vw" }}>
                      {deviceinfo[0].r2}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="container-fluid px-5 py-4 bg-light-red" style={{ height: "100vh" }}>
        <div
          className="row mx-5 bg-white justify-content-center align-items-center rounded-4"
          style={{ height: "22vh" }}
        >
          <div className="col-3">
            <p className="text-red text-center fw-semibold  pt-3" style={{ fontSize: "3rem" }}>
              Start Position
            </p>
          </div>
          <div className="col-6">
            <ProgressBar value={deviceinfo[0].angle_1} max={deviceinfo[0].r2} P={deviceinfo[0].p} />
          </div>
          <div className="col-3">
            <p className="text-red text-center fw-semibold  pt-3" style={{ fontSize: "3rem" }}>
              End Position
            </p>
          </div>
        </div>
        <div className="row mt-5 mx-5">
          <div className="col-3">
            <div className="row justify-content-center gy-5">
              <div
                className="bg-white rounded-4 d-flex justify-content-center align-items-center flex-column"
                style={{ height: "30vh" }}
              >
                <div className=" w-100 ">
                  <div className=" text-red text-center fw-semibold" style={{ fontSize: "5rem" }}>
                    {P === 0
                      ? (minute < 10 ? "0" + minute : minute) + ":" + (seconds < 10 ? "0" + seconds : seconds)
                      : "00:00"}
                  </div>
                  <div className=" text-red fw-semibold text-center" style={{ fontSize: "2.5rem" }}>
                    Hold
                  </div>
                </div>
              </div>
              <div
                className="bg-white rounded-4 d-flex justify-content-center align-items-center flex-column"
                style={{ height: "30vh" }}
              >
                <div className=" w-100 ">
                  <div className=" text-red text-center fw-semibold" style={{ fontSize: "5rem" }}>
                    {(spm < 10 ? "0" + spm : spm) + ":" + (sps < 10 ? "0" + sps : sps)}
                  </div>
                  <div className="text-center text-red fw-semibold lh-1" style={{ fontSize: "2.5rem" }}>
                    Total Seconds
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 px-5">
            <div className="row justify-content-center gy-5">
              <div
                className="bg-white rounded-4 d-flex justify-content-center align-items-center flex-column"
                style={{ height: "30vh" }}
              >
                <div className=" w-100 ">
                  <div className=" text-red text-center fw-semibold" style={{ fontSize: "5rem" }}>
                    <p>alert</p>
                  </div>
                </div>
              </div>
              <div
                className="bg-white rounded-4 d-flex justify-content-center align-items-center flex-column"
                style={{ height: "30vh" }}
              >
                <div className=" w-100 ">
                  <div className=" text-red text-center fw-semibold" style={{ fontSize: "5rem" }}>
                    {deviceinfo[0].count}
                  </div>
                  <div className=" text-red fw-semibold text-center" style={{ fontSize: "2.5rem" }}>
                    Repetitions
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="row justify-content-center gy-5">
              <div
                className="bg-white rounded-4 d-flex justify-content-center align-items-center flex-column"
                style={{ height: "30vh" }}
              >
                <div className="w-100">
                  <div className="text-red text-center fw-semibold" style={{ fontSize: "5rem" }}>
                    {P === 1
                      ? (minute < 10 ? "0" + minute : minute) + ":" + (seconds < 10 ? "0" + seconds : seconds)
                      : "00:00"}
                  </div>
                  <div className="text-center text-red fw-semibold" style={{ fontSize: "2.5rem" }}>
                    Hold
                  </div>
                </div>
              </div>
              <div
                className="bg-white rounded-4 d-flex justify-content-center align-items-center flex-column"
                style={{ height: "30vh" }}
              >
                <div className="w-100">
                  <div className="text-red text-center fw-semibold" style={{ fontSize: "5rem" }}>
                    {(epm < 10 ? "0" + epm : epm) + ":" + (eps < 10 ? "0" + eps : eps)}
                  </div>
                  <div className="text-center text-red fw-semibold lh-1 " style={{ fontSize: "2.5rem" }}>
                    Total Seconds
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
