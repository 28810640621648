import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Connect from "./Pages/Connect/Connect";
import Application from "./Pages/Application/Application";
import { Provider } from "react-redux";
import deviceStore from "./store/store";
import Info from "./Pages/Info/Info";
import Admin from "./Pages/Admin/Admin";
import DoctorAddPatients from "./Pages/DoctorAddPatients/DoctorAddPatients";
import Activity from "./Pages/Application/Activity/Activity";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Connect />,
  },
  {
    path: "DoctorAddPatients",
    element: <DoctorAddPatients />,
  },

  {
    path: "Application",
    element: <Application />,
  },
  {
    path: "Activity",
    element: <Activity />,
  },
  {
    path: "Admin",
    element: <Admin />,
  },
  {
    path: "Info",
    element: <Info />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={deviceStore}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
