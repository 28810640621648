// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --valueHeight: 10vh;
    --valueWidth: var(--valueHeight);
    --light-red: #ffbdbb;
}

.custom-thumb::-webkit-slider-thumb {
    height: var(--valueHeight) !important;
    width: var(--valueWidth) !important;
    border-radius: 50% !important;
    background-color: white !important;
    /* Adjust slightly outwards to cover border */
    position: relative !important;
    top: calc(50% - (var(--valueHeight)/2)) !important;
    border: 10px solid red !important;
    /* Slightly thicker border */
}

.custom-thumb::-webkit-slider-runnable-track {
    height: 3.5vh !important;
    background-color: red !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Application/Workout/Game/FlappyBird/Slider.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gCAAgC;IAChC,oBAAoB;AACxB;;AAEA;IACI,qCAAqC;IACrC,mCAAmC;IACnC,6BAA6B;IAC7B,kCAAkC;IAClC,6CAA6C;IAC7C,6BAA6B;IAC7B,kDAAkD;IAClD,iCAAiC;IACjC,4BAA4B;AAChC;;AAEA;IACI,wBAAwB;IACxB,gCAAgC;AACpC","sourcesContent":[":root {\r\n    --valueHeight: 10vh;\r\n    --valueWidth: var(--valueHeight);\r\n    --light-red: #ffbdbb;\r\n}\r\n\r\n.custom-thumb::-webkit-slider-thumb {\r\n    height: var(--valueHeight) !important;\r\n    width: var(--valueWidth) !important;\r\n    border-radius: 50% !important;\r\n    background-color: white !important;\r\n    /* Adjust slightly outwards to cover border */\r\n    position: relative !important;\r\n    top: calc(50% - (var(--valueHeight)/2)) !important;\r\n    border: 10px solid red !important;\r\n    /* Slightly thicker border */\r\n}\r\n\r\n.custom-thumb::-webkit-slider-runnable-track {\r\n    height: 3.5vh !important;\r\n    background-color: red !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
