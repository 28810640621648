import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../Components/Navbar";
import { changeWifi } from "../Connect/BluetoothHandler";
import Cookies from 'js-cookie';
import axios from "axios";

export default function Admin() {
  const wifinameRef = useRef(null);
  const wifipasswordRef = useRef(null);
  const [loading, setLoading] = useState(true);

  let ChangeWifi = (e) => {
    e.preventDefault();
    // // Get form and validate inputs
    const form = e.target;
    form.checkValidity();
    // // Check validity of the form
    if (!form.checkValidity()) {
      e.stopPropagation(); // Prevent the form from submitting
    } else {
      changeWifi(wifinameRef.current.value, wifipasswordRef.current.value);
    }
    form.classList.add("was-validated");

    // Add your additional form handling logic here Apply validation classes
  };

  const handleLogout = async () => {
    const token = Cookies.get('token'); // Get the token from cookies
    if (!token) { 
      console.error('No token found. Unable to logout.');
      return;
    }

    try {
      const response = await axios.post('https://go.raycura.com:5000/api/auth/logout', {}, {
        headers: {
          Authorization: `Bearer ${token}` // Include the token in the header
        },
        withCredentials: true // Important for cookie handling
      });

      if (response.status === 200) {
        console.log('Logged out successfully');
        Cookies.remove('token'); // Clear the token from cookies
        window.location.href = '/'; // Redirect to the login page
      } else {
        console.error('Logout failed:', response.data);
      }
    } catch (error) {
      console.error('Error during logout:', error);
      alert('An error occurred during logout. Please try again.');
    }
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = Cookies.get('token');
      if (!token) {
        window.location.href = "/"; // Redirect to login if no token
        return;
      }

      try {
        const response = await axios.get('https://go.raycura.com:5000/api/auth/verify-token', {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.status === 200) {
          console.log('Token verified, user is authenticated.');
          setLoading(false)

        } else {
          console.log('Token verification failed.');
          window.location.href = "/";
        }
      } catch (error) {
        console.error('Authentication check error:', error);
        window.location.href = "/";
      }
    };

    checkAuthentication();
  }, []);
  
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar activeNavtab={"Admin"} />
      <div className="row m-0 p-5 bg-light-red vh-100">
        <div className="card border-0 w-50 h-50 m-0 p-0">
          <div className="card-body m-0 bg-light-red">
            <h3 className="card-title text-center">Connect to Wi-Fi</h3>
            <form className="m-0 p-0 needs-validation" noValidate onSubmit={ChangeWifi}>
              <div className="mb-3">
                <label htmlFor="wifiname" className="form-label">
                  Wi-Fi Name
                </label>
                <div className="input-group has-validation">
                  <input
                    type="text"
                    className="form-control"
                    id="wifiname"
                    placeholder="Enter Wi-Fi name"
                    ref={wifinameRef}
                    required
                  />
                  <div className="invalid-feedback">Wi-Fi name is required.</div>
                </div>
              </div>

              <div className="mb-4">
                <label htmlFor="wifipassword" className="form-label">
                  Password
                </label>
                <div className="input-group has-validation">
                  <input
                    type="password"
                    className="form-control"
                    id="wifipassword"
                    placeholder="Enter password"
                    ref={wifipasswordRef}
                    required
                  />
                  <div className="invalid-feedback">Password is required.</div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary btn-lg">
                Save
              </button>
            </form>
          </div>
        </div>
        <div>
          <button type="button" className="btn btn-success" onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </>
  );
}