import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

export default function BolbGame() {
  let canvasRef = useRef(null);
  let { deviceinfo } = useSelector((store) => store.devicedata);
  let [sps, setSps] = useState(0);
  let [spm, setSpm] = useState(sps);
  let [gameRunning, setGamerunning] = useState(false);
  let [hit, setHit] = useState(0);
  let redBall = useRef({
    x: 300,
    y: 300,
    radius: 30,
    ballVelocityX: 1,
    ballVelocityY: 0,
  });

  let blackBall = useRef({
    x: 200,
    y: 300,
    radius: 30,
  });

  let moveRedBall = (roll, pitch, canvas) => {
    if (roll > 15) {
      roll = 15;
    }
    if (pitch > 15) {
      pitch = 15;
    }
    if (roll < -15) {
      roll = -15;
    }
    if (pitch < -15) {
      pitch = -15;
    }
    const xRange = 18; // Maximum absolute value of roll
    const yRange = 18; // Maximum absolute value of pitch
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    // Calculate x coordinate
    var nextX = canvasWidth / 2 + (roll / xRange) * (canvasWidth / 2);
    // Calculate y coordinate
    var nextY = canvasHeight / 2 + (pitch / yRange) * (canvasHeight / 2);
    // Update red ball position if within canvas bounds

    redBall.current.x = nextX;
    redBall.current.y = nextY;
  };

  let checkCollision = (redBall, blackBall, canvas) => {
    let distance = Math.sqrt(
      (redBall.x - blackBall.x) ** 2 + (redBall.y - blackBall.y) ** 2
    );
    if (distance < redBall.radius + blackBall.radius) {
      setHit((prevscore) => prevscore + 1);
      var angle = Math.random() * Math.PI * 2; // Generate a random angle
      var minDistanceFromCenter = 80 + blackBall.radius; // Minimum distance from redball
      var maxDistanceFromCenter = canvas.height / 2 - blackBall.radius; // Maximum distance from center within canvas bounds

      // Generate a random distance from center within allowed bounds
      var distanceFromCenter =
        Math.random() * (maxDistanceFromCenter - minDistanceFromCenter) +
        minDistanceFromCenter;

      // Calculate the new position of the black ball
      blackBall.x = canvas.width / 2 + Math.cos(angle) * distanceFromCenter;
      blackBall.y = canvas.height / 2 + Math.sin(angle) * distanceFromCenter;
    }
  };

  const drawBall = (ctx, canvas) => {
    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw red ball
    ctx.beginPath();
    ctx.arc(
      redBall.current.x,
      redBall.current.y,
      redBall.current.radius,
      0,
      Math.PI * 2
    );
    ctx.fillStyle = "#ff7223";
    ctx.fill();
    ctx.closePath();

    // Draw black ball
    ctx.beginPath();
    ctx.arc(
      blackBall.current.x,
      blackBall.current.y,
      blackBall.current.radius,
      0,
      Math.PI * 2
    );
    ctx.fillStyle = "black";
    ctx.fill();
    ctx.closePath();
  };

  useEffect(() => {
    let intervalid = setInterval(() => {
      if (gameRunning) {
        setSps((preveps) => (preveps + 1) % 60);
        if (sps === 59) {
          setSpm((prevepm) => prevepm + 1);
        }
      }
    }, 1000);
    return () => clearInterval(intervalid);
  }, [gameRunning, sps]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const gameLoop = () => {
      moveRedBall(deviceinfo[0].pitch, deviceinfo[0].roll, canvas);
      checkCollision(redBall.current, blackBall.current, canvas);
      drawBall(ctx, canvas);
    };
    const intervalId = setInterval(gameLoop, 1000 / 30); // 30 fps

    return () => clearInterval(intervalId);
  }, [deviceinfo]); // Empty dependency array ensures this effect runs only once after initial render

  return (
    <div className="mt-5 pt-5 px-5 container-fluid bg-white vh-100">
      <div className="row">
        <div className="col" style={{ height: " 90vh" }}>
          <div className="row d-flex h-100  justify-content-center align-items-center">
            <p
              className="fw-semibold lh-lg"
              id="timerDisplay"
              style={{ color: " #4c356b", fontSize: " 4vw" }}
            >
              Time:
              {(spm < 10 ? "0" + spm : spm) +
                ":" +
                (sps < 10 ? "0" + sps : sps)}
            </p>
            <p
              className="fw-semibold lh-lg"
              style={{ color: " #4c356b", fontSize: " 4vw" }}
            >
              Hit:{hit < 10 ? "0" + hit : hit}
            </p>

            <p
              className="fw-semibold lh-lg"
              id="missDisplay"
              style={{ color: " #4c356b", fontSize: " 4vw" }}
            >
              Miss
            </p>
            <p
              className="fw-semibold lh-lg"
              style={{ color: " #4c356b", fontSize: " 4vw" }}
            >
              percentage
            </p>
            <div className="d-flex row m-0 p-0">
              <div className="col-8">
                <button
                  className="btn rounded-pill   text-white w-100 fw-semibold"
                  style={{ fontSize: "3vw", backgroundColor: " #4c356b" }}
                  id="startButton"
                >
                  Start
                </button>
                <button
                  className="btn   d-none rounded-pill   text-white w-100 fw-semibold"
                  style={{ fontSize: "3vw", backgroundColor: " #4c356b" }}
                  id="stopButton"
                >
                  Stop
                </button>
              </div>
              <div className="col-4">
                <button
                  className="btn rounded-circle px-4 py-2  text-white fw-semibold"
                  style={{ fontSize: "3vw", backgroundColor: " #4c356b" }}
                  id="resetButton"
                >
                  ↺
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col d-flex justify-content-center align-items-center">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "85vh", width: " 85vh" }}
          >
            <canvas
              ref={canvasRef}
              width={window.innerHeight - (window.innerHeight * 15) / 100}
              height={window.innerHeight - (window.innerHeight * 15) / 100}
              style={{ border: "2px solid red", borderRadius: "100%" }}
            ></canvas>
          </div>
        </div>

        <div
          className="col"
          style={{ height: " 90vh", minWidth: " 10%", maxWidth: " 30%" }}
        >
          <div className="d-flex flex-column w-100 justify-content-evenly align-items-center h-100 px-2">
            <div
              className="p-2 w-100 rounded-pill text-center text-white fw-semibold level-button"
              style={{ fontSize: " 1.5vw", backgroundColor: " #4c356b" }}
              data-level="1"
            >
              Level 1
            </div>
            <div
              className="p-2 rounded-pill w-100 text-center text-white fw-semibold level-button"
              style={{ fontSize: " 1.5vw", backgroundColor: " #4c356b" }}
              data-level="2"
            >
              Level 2
            </div>
            <div
              className="p-2 rounded-pill w-100 text-center text-white fw-semibold level-button"
              style={{ fontSize: " 1.5vw", backgroundColor: " #4c356b" }}
              data-level="3"
            >
              Level 3
            </div>
            <div
              className="p-2 rounded-pill w-100 text-center text-white fw-semibold level-button"
              style={{ fontSize: " 1.5vw", backgroundColor: " #4c356b" }}
              data-level="4"
            >
              Level 4
            </div>
            <div
              className="p-2 rounded-pill w-100 text-center text-white fw-semibold level-button"
              style={{ fontSize: " 1.5vw", backgroundColor: " #4c356b" }}
              data-level="5"
            >
              Level 5
            </div>
            <div
              className="p-2 rounded-pill w-100 text-center text-white fw-semibold level-button"
              style={{ fontSize: " 1.5vw", backgroundColor: " #4c356b" }}
              data-level="6"
            >
              Level 6
            </div>
            <div
              className="p-2 rounded-pill w-100 text-center text-white fw-semibold level-button"
              style={{ fontSize: " 1.5vw", backgroundColor: " #4c356b" }}
              data-level="7"
            >
              Level 7
            </div>
            <div
              className="p-2 rounded-pill w-100 text-center text-white fw-semibold level-button"
              style={{ fontSize: " 1.5vw", backgroundColor: " #4c356b" }}
              data-level="8"
            >
              Level 8
            </div>
            <div
              className="p-2 rounded-pill w-100 text-center text-white fw-semibold level-button"
              style={{ fontSize: " 1.5vw", backgroundColor: " #4c356b" }}
              data-level="9"
            >
              Level 9
            </div>
            <div
              className="p-2 rounded-pill w-100 text-center text-white fw-semibold level-button"
              style={{ fontSize: " 1.5vw", backgroundColor: " #4c356b" }}
              data-level="10"
            >
              Level 10
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
