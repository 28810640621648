import React from "react";

export default function ProgressBar({ value, max, P }) {
  // const progress = (value / max) * 100;
  const getJustifyContent = () => {
    return P === 1 ? "flex-end" : "flex-start";
  };

  const getValue = () => {
    if (max < 0) {
      if (value > 0) {
        value = 0;
        return value;
      }
      if (value < max) {
        value = max;
        return value;
      }
      if (value > max) {
        return value;
      }
    } else if (max > -1) {
      if (value < 0) {
        value = 0;
        return value;
      }
      if (value > max) {
        value = max;
        return value;
      }
      if (value < max) {
        return value;
      }
    }
  };

  const getProgress = () => {
    return P === 1 ? 100 - (getValue() / max) * 100 : (getValue() / max) * 100;
  };
  return (
    <div
      className="progress bg-white rounded-pill  "
      style={{
        height: "35px",
        border: "4px solid red",
        display: "flex",
        justifyContent: getJustifyContent(),
      }}
    >
      <div
        className="progress-bar rounded-pill"
        role="progressbar"
        style={{
          width: `${getProgress()}%`,
          background: "red",
          transition: "width 0.2s ease-in-out",
        }}
      />
    </div>
  );
}
