import React from "react";

export default function PlayerData({
  playerData: { patientName, jointName, currentTime, formatDate, formatTime },
}) {
  return (
    <div className="mx-5 px-5 bg-white rounded-2">
      <div className="row">
        <div className="col-9">
          <p className="text-red fw-semibold fs-4 pt-3">
            {patientName} - {jointName}
          </p>
        </div>

        <div className="col-3">
          <p className="text-red text-end fw-semibold fs-4 pt-3">
            {currentTime ? (
              <>
                {formatDate(currentTime)}
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; {formatTime(currentTime)}
              </>
            ) : (
              "Loading..."
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
