import { createSlice } from "@reduxjs/toolkit";

const itemNameSlice = createSlice({
  name: "itemName",
  initialState: { itemname: "" },
  reducers: {
    addItemName: (state, action) => {
      state.itemname = action.payload.value;

      // console.log(state.ipAddress);
    },
    removeItemName: (state, action) => {
      console.log("removeip");
    },
  },
});

export const itemNameActions = itemNameSlice.actions;
export default itemNameSlice;
