import React, { useRef } from "react";
import Navbar from "../../Components/Navbar";

export default function Info() {
  // Empty dependency array ensures this effect runs only once after the initial render

  let range = useRef();

  let r = () => {
    console.log(range.current.value);
  };
  return (
    <>
      <Navbar activeNavtab={"Info"} />
      <section className="bg-light-red p-5 h-100 w-100">
        {/* First row */}
        <div className="row m-0 p-0 bg-white rounded ">
          <div className="row">
            <div className="col ">
              <ul className="list-unstyled mx-auto">
                <li className="d-flex fw-medium fs-4 lh-3">
                  <div className="col-3 d-flex justify-content-between ">
                    <strong className="text-red ">Doctor</strong>
                    <span>:</span>
                  </div>
                  <div className="col-9 px-2">Mr Tom</div>
                </li>
                <li className="d-flex fw-medium fs-4 lh-3">
                  <div className="col-3 d-flex justify-content-between">
                    <strong className="text-red">Mob No</strong>
                    <span>:</span>
                  </div>
                  <div className="col-9 px-2">123456789</div>
                </li>
                <li className="d-flex fw-medium fs-4 lh-3">
                  <div className="col-3 d-flex justify-content-between">
                    <strong className="text-red">Email</strong>
                    <span>:</span>
                  </div>
                  <div className="col-9 px-2">abc@gmail.com</div>
                </li>
              </ul>
            </div>
            <div className="col">
              <ul className="list-unstyled">
                <li className="d-flex fw-medium fs-4 ">
                  <div className="col-3 d-flex justify-content-between">
                    <strong className="text-red">Clinic</strong>
                    <span>:</span>
                  </div>
                  <div className="col-9 px-2">Your Clinic Name</div>
                </li>
                <li className="d-flex fw-medium fs-4 lh-3">
                  <div className="col-3 d-flex justify-content-between">
                    <strong className="text-red">Address</strong>
                    <span>:</span>
                  </div>
                  <div className="col-9 px-2">XYZ Nagpur</div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Second row */}
        <div className="row mx-0  my-5 px-0 pt-3 bg-white rounded px-5">
          <div className="py-3 ">
            <h2 className="text-center text-red fw-bold">Owned Devices</h2>
          </div>
          <div className="pb-4 row justify-content-around align-items-center w-100">
            <div className="col m-0 p-0 ">
              <div className="bg-primary rounded mx-auto " style={{ height: "20vw", width: "20vw" }}></div>
              <div className="fw-medium">
                <p className="text-center text-red pt-2">
                  <span className="fs-2">Better112234</span>
                  <br />
                  Software Version 1.1 <br />
                  Update available
                </p>
              </div>
            </div>
            <div className="col m-0 p-0 ">
              <div className="bg-primary rounded mx-auto " style={{ height: "20vw", width: "20vw" }}></div>
              <div className="fw-medium">
                <p className="text-center text-red pt-2">
                  <span className="fs-2">Better112234</span>
                  <br />
                  Software Version 1.1 <br />
                  Update available
                </p>
              </div>
            </div>
            <div className="col m-0 p-0 ">
              <div className="bg-primary rounded mx-auto " style={{ height: "20vw", width: "20vw" }}></div>
              <div className="fw-medium">
                <p className="text-center text-red pt-2">
                  <span className="fs-2">Better112234</span>
                  <br />
                  Software Version 1.1 <br />
                  Update available
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Third row */}
        <div className="row mx-0  my-5 pt-3 px-0  bg-white rounded">
          <div className="py-3">
            <h2 className="text-center text-red fw-bold">I have an idea</h2>
          </div>
          <div className="row pb-5 mb-2">
            <div className="col-8 px-5">
              <div className="bg-light-red rounded text-red fw-bold mx-4" style={{ height: "40vh" }}>
                Type Your Ideas here
              </div>
            </div>
            <div className="col-4 ">
              <div className="w-100 h-100 position-relative">
                <button type="button" className="btn bg-red text-white py-2 fs-4 w-100">
                  Upload
                </button>
                <p className=" text-red fw-bold">You can upload images and videos here.</p>
                <button
                  type="button"
                  className="btn bg-red text-center text-white py-2 fs-4 w-100 bottom-0  position-absolute"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>

        <label className="form-label">Example range</label>
        <input type="range" ref={range} className="form-range" defaultValue="0" />
        <button onClick={r}>th</button>
      </section>
    </>
  );
}
