import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { mode0, tare } from "../../../../Connect/BluetoothHandler";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const MAX_DATA_POINTS = 100; // Maximum number of data points in the FIFO queue

const MultiAxesModeTwelve = () => {
  const { deviceinfo } = useSelector((store) => store.devicedata);
  const [plotValue, setPlotValue] = useState({
    x: [],
    y: [],
    z: [],
    time: [],
    ax: [],
    ay: [],
    az: [],
    t: undefined,
    stepx: [],
    stepy: [],
    stepz: [],
    xr: [],
    zr: [],
    right: 0,
    left: 0,
    up: 0,
    down: 0,
    vertical: [],
    horizontal: [],
  });

  const [isPaused, setIsPaused] = useState(false);
  const [boolean, setBoolean] = useState(deviceinfo);
  const prevBooleanRef = useRef(deviceinfo);

  useEffect(() => {
    if (deviceinfo[0]?.mode === 0) {
      // Set boolean to empty array if mode is 0
      setBoolean([]);
    } else {
      // Compare current deviceinfo with previous deviceinfo
      const prevBoolean = prevBooleanRef.current;
      const isDifferent =
        JSON.stringify(deviceinfo) !== JSON.stringify(prevBoolean);

      if (isDifferent) {
        setBoolean(deviceinfo);
        prevBooleanRef.current = deviceinfo; // Update the ref with the new deviceinfo
      }
    }
  }, [deviceinfo]);

  const plotData = useMemo(() => {
    if (boolean.length > 0 && boolean[0]?.mode === 9) {
      return {
        x: boolean[0]?.x,
        y: boolean[0]?.y,
        z: boolean[0]?.z,
        time: boolean[0]?.time,
        ax: boolean[0]?.ax,
        ay: boolean[0]?.ay,
        az: boolean[0]?.az,
        t: boolean[0]?.t,
        stepx: boolean[0]?.stepx,
        stepy: boolean[0]?.stepy,
        stepz: boolean[0]?.stepz,
        xr: boolean[0]?.xr,
        zr: boolean[0]?.zr,
        right: boolean[0]?.right,
        left: boolean[0]?.left,
        up: boolean[0]?.up,
        down: boolean[0]?.down,
        vertical: boolean[0]?.vertical,
        horizontal: boolean[0]?.horizontal,
      };
    }
    return null;
  }, [boolean]);

  useEffect(() => {
    if (plotData && !isPaused) {
      setPlotValue((prev) => {
        // Create a new object with updated data
        const newPlotValue = {
          x: [...prev.x, plotData.x].slice(-MAX_DATA_POINTS),
          y: [...prev.y, plotData.y].slice(-MAX_DATA_POINTS),
          z: [...prev.z, plotData.z].slice(-MAX_DATA_POINTS),
          time: [...prev.time, plotData.time].slice(-MAX_DATA_POINTS),
          ax: [...prev.ax, plotData.ax].slice(-MAX_DATA_POINTS),
          ay: [...prev.ay, plotData.ay].slice(-MAX_DATA_POINTS),
          az: [...prev.az, plotData.az].slice(-MAX_DATA_POINTS),
          t: plotData.t,
          stepx: [...prev.stepx, plotData.stepx].slice(-MAX_DATA_POINTS),
          stepy: [...prev.stepy, plotData.stepy].slice(-MAX_DATA_POINTS),
          stepz: [...prev.stepz, plotData.stepz].slice(-MAX_DATA_POINTS),
          xr: [...prev.xr, plotData.xr].slice(-MAX_DATA_POINTS),
          zr: [...prev.zr, plotData.zr].slice(-MAX_DATA_POINTS),
          right: plotData.right,
          left: plotData.left,
          up: plotData.up,
          down: plotData.down,
          vertical: [...prev.vertical, plotData.vertical].slice(
            -MAX_DATA_POINTS
          ),
          horizontal: [...prev.horizontal, plotData.horizontal].slice(
            -MAX_DATA_POINTS
          ),
        };

        return newPlotValue;
      });
    }
  }, [plotData, isPaused]);

  const data = useMemo(
    () => ({
      labels: plotValue.time,
      datasets: [
        // {
        //   label: "x",
        //   data: plotValue.x,
        //   fill: false,
        //   backgroundColor: "blue",
        //   borderColor: "blue",
        // },
        // {
        //   label: "y",
        //   data: plotValue.y,
        //   fill: false,
        //   backgroundColor: "yellow",
        //   borderColor: "yellow",
        // },
        // {
        //   label: "z",
        //   data: plotValue.z,
        //   fill: false,
        //   backgroundColor: "red",
        //   borderColor: "red",
        // },
        {
          label: "ax",
          data: plotValue.ax,
          fill: false,
          backgroundColor: "green",
          borderColor: "green",
        },
        {
          label: "ay",
          data: plotValue.ay,
          fill: false,
          backgroundColor: "orange",
          borderColor: "orange",
        },
        {
          label: "az",
          data: plotValue.az,
          fill: false,
          backgroundColor: "purple",
          borderColor: "purple",
        },
        {
          label: "stepx",
          data: plotValue.stepx,
          fill: false,
          backgroundColor: "brown",
          borderColor: "brown",
        },
        {
          label: "stepy",
          data: plotValue.stepy,
          fill: false,
          backgroundColor: "violet",
          borderColor: "violet",
        },
        {
          label: "stepz",
          data: plotValue.stepz,
          fill: false,
          backgroundColor: "black",
          borderColor: "black",
        },
        {
          label: "xr",
          data: plotValue.xr,
          fill: false,
          backgroundColor: "yellow",
          borderColor: "yellow",
        },
        {
          label: "zr",
          data: plotValue.zr,
          fill: false,
          backgroundColor: "blue",
          borderColor: "blue",
        },
        {
          label: "vertical",
          data: plotValue.vertical,
          fill: false,
          backgroundColor: "red",
          borderColor: "red",
        },
        {
          label: "horizontal",
          data: plotValue.horizontal,
          fill: false,
          backgroundColor: "#8beddb",
          borderColor: "#8beddb",
        },
      ],
    }),
    [plotValue]
  );

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          title: {
            display: true,
            text: "Time",
          },
        },
        y: {
          title: {
            display: true,
            text: "Value",
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: true,
          text: "Multiple Axes ModeNine Chart",
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "x",
          },
        },
      },
    }),
    []
  );

  const handleStartPlotting = useCallback(() => {
    setIsPaused(false);
  }, []);

  const handlePausePlotting = useCallback(() => {
    setIsPaused(true);
  }, []);

  const handleStartClick = useCallback(() => {
    setPlotValue({
      x: [],
      y: [],
      z: [],
      time: [],
      ax: [],
      ay: [],
      az: [],
      t: undefined,
      stepx: [],
      stepy: [],
      stepz: [],
      xr: [],
      zr: [],
      right: 0,
      left: 0,
      up: 0,
      down: 0,
      vertical: [],
      horizontal: [],
    });
    tare(0);
  }, []);

  const handleMode0Click = useCallback(() => {
    mode0();
  }, []);

  return (
    <>
      <div className={plotValue.t === 1 ? `bg-red` : `bg-primary`}>
        {plotValue.t}
      </div>
      <div
        className="text-red"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          className={` border border-1 border-primary ${
            plotValue.up ? "bg-red text-white" : "text-red bg-white"
          } py-3`}
          style={{ width: "100%", textAlign: "center" }}
        >
          up
        </div>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <div
            className={`border border-1 border-primary ${
              plotValue.left ? "bg-red text-white" : "text-red bg-white"
            } py-3`}
            style={{ width: "50%", textAlign: "center" }}
          >
            left
          </div>
          <div
            className={` border border-1 border-primary ${
              plotValue.right ? "bg-red text-white" : "text-red bg-white"
            } py-3`}
            style={{ width: "50%", textAlign: "center" }}
          >
            right
          </div>
        </div>
        <div
          className={`border border-1 border-primary  ${
            plotValue.down ? "bg-red text-white" : "text-red bg-white"
          } py-3`}
          style={{ width: "100%", textAlign: "center" }}
        >
          down
        </div>
      </div>

      <div className="mb-2">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleStartClick}
        >
          Start
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleMode0Click}
        >
          Mode 0
        </button>
        <button
          type="button"
          className="btn btn-success"
          onClick={handleStartPlotting}
        >
          Start Plotting
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={handlePausePlotting}
        >
          Pause Plotting
        </button>
      </div>

      <div style={{ width: "100%", height: "68vh" }}>
        <Line data={data} options={options} />
      </div>
    </>
  );
};

export default MultiAxesModeTwelve;
