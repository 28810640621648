// Selection.jsx
import React, { useState } from "react";
import { bodypartstabs } from "./workoutTabs";
import { useDispatch } from "react-redux";
import { sequenceClickActions } from "../../../store/squenceclick";
import GameSelection from "./Game/GameSelection";

export default function SelectionTabsWorkout({ setCurrentView }) {
  // State to manage the active tab
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("Upper Extremity");
  return (
    <section className="container-fluid px-5 mt-1 py-3">
      <div className="mx-5 bg-white rounded-2 my-3 px-5">
        <div>
          <ul className="d-flex text-center p-0 flex-wrap border-3 border-bottom-dark-red list-unstyled">
            {Object.keys(bodypartstabs).map((bodypart) => {
              return (
                <li
                  key={bodypart}
                  className={`col fw-bold p-3 ${
                    activeTab === bodypart ? "bg-red text-white" : "text-red"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setActiveTab(bodypart);
                  }}
                >
                  {bodypart}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="container px-5">
          <div className="row">
            {
              // Render the active tab content
              Object.entries(bodypartstabs[activeTab]).map(([name, image]) => {
                return (
                  <div className="col-3 text-center p-3" key={name}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let selectedGame = GameSelection(activeTab, name);
                        setCurrentView(selectedGame);
                        // Pass the selected body part to Sequence Array
                        dispatch(
                          sequenceClickActions.addSequenceClick({
                            value: { [activeTab]: name },
                          })
                        );
                      }}
                    >
                      <div className="p-2">
                        <img
                          className="border border-danger mx-auto w-100 h-100 object-fit-contain rounded-5"
                          src={require(`../../../Images/${image}`)}
                          alt="Game"
                        />
                      </div>
                      <div className="text-center">
                        <h5>{name}</h5>
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    </section>
  );
}
