import React from "react";
import Block from "../../../Components/Block";
import { useDispatch } from "react-redux";
import { currentViewActions } from "../../../store/currentView";
import AppProgressBar from "../AppProgressBar";

export default function Activity() {
  // Component mount hone par 4 baar console par print karein
  const dispatch = useDispatch();

  const setCurrentView = (view) => {
    dispatch(currentViewActions.addView({ value: view })); // Dispatch the action to update the currentView
  };
  return (
    <div className="bg-light-red body">
      <AppProgressBar />

      <section className="container-fluid px-5 py-0 m-0">
        <div className="d-flex" style={{ height: "100vh" }}>
          <Block setCurrentView={setCurrentView}></Block>
        </div>
      </section>
    </div>
  );
}
