//ResetPassword.jsx

import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

const ResetPassword = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [secondPasswordVisible, setSecondPasswordVisible] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false); 
  const [otp, setOtp] = useState('');
  const [buttonText, setButtonText] = useState('Send OTP');
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [timer, setTimer] = useState(0);
  const [buttonVerifyText, setButtonVerifyText] = useState('Verify OTP');

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [timer]);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value));
  };

  const sendOtp = async () => {
    console.log('Sending OTP for email:', email);
    try {
      const response = await fetch('https://go.raycura.com:5000/api/auth/email-send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      console.log('Response from server:', data);
      if (response.status === 200) {
        setOtpSent(true);
        // setVerify(true); 
        setButtonText('Resend OTP');
        setTimer(60);
        alert(data.message);
      } else {
        alert(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
  };

  const verifyOtp = async () => {
    console.log('Verifying OTP for email:', email, 'with OTP:', otp);
    try {
      const response = await fetch('https://go.raycura.com:5000/api/auth/verify-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otpCode: otp }),
      });

      if (!response.ok) {
        const errorDetails = await response.text();
        console.error('Server responded with an error:', errorDetails);
        alert(`Error: ${errorDetails || 'OTP verification failed.'}`);
        return;
      }

      const data = await response.json();
      console.log('Response from server:', data);

      // console.log()
      if (data.statusText === "success") {
        console.log(9 - 8);
        setButtonVerifyText('OTP Verified');
        alert("OTP Verified Successfully");
        setOtpVerified(true);
      } else {
        alert(`${data.message || 'OTP verification failed.'}`);
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('An error occurred while verifying OTP. Please try again.');
    }
  };

  const changePassword = async () => {
    try {
      const response = await axios.post('https://go.raycura.com:5000/api/auth/change-password',{
        email,otp,password
      }, {
        headers: {
          'Content-Type': 'application/json',
        }, 
        body: JSON.stringify({ email, otp, password }),
      });

      if (response.status === 200) {
        alert("Password Changed Successfully");
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error changing password:', error);
      alert('An error occurred while changing the password.');
    }
    directtoLogin();
  };

  const directtoLogin = () => {
    return setTimeout(() => {
      window.location.href = '/'; 
    }, 2000);
  };

  const toggleSecondPasswordVisibility = () => {
    setSecondPasswordVisible(!secondPasswordVisible);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    if (value.length < 6) {
      setPasswordError('Password must be at least 6 characters.');
    } else {
      setPasswordError('');
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value !== password) {
      setConfirmPasswordError('Passwords do not match.');
    } else {
      setConfirmPasswordError('');
    }
  };

  useEffect(() => {
    setPasswordsMatch(password === confirmPassword);
  }, [password, confirmPassword]);

  return (
    <div className="vw-100 vh-100 position-relative">
      <div>
        <img className="vh-100 vw-100" src="Picture5.png" alt="img" />
      </div>
      <div
        className="position-absolute"
        style={{
          background: "rgb(221, 220, 213)",
          borderRadius: "2vw",
          boxShadow:
            "0.7vw 0.7vw 1.5vw rgba(20, 20, 20, 0.3), -0.7vw -0.7vw 1.5vw rgba(255, 255, 255, 0.9)",
          padding: "2.5vh",
          width: "30vw",
          transition: "box-shadow 0.3s ease",
          top: "6vh",
          right: "10vw",
        }}
      >
        <style>
          {`
            .clickable:active {
              box-shadow: inset 0.7vw 0.7vw 1.5vw #bebebe, inset -0.7vw -0.7vw 1.5vw #ffffff !important;
            }
          `}
        </style>
        <h2
          className="mb-4 text-center fw-bolder"
          style={{ color: "rgb(223, 112, 34)", fontSize: "2vw" }}
        >
          Reset Password
        </h2>
        <form>
          <div className="mb-3 position-relative">
            <FontAwesomeIcon
              icon={faEnvelope}
              style={{
                position: "absolute",
                top: "50%",
                left: "1.5vw",
                transform: "translateY(-50%)",
                fontSize: "2.5vh",
              }}
            />
            <input
              type="email"
              className="form-control ps-5 "
              placeholder="Email"
              required
              value={email}
              onChange={handleEmailChange}
              style={{
                background: "rgb(221, 220, 213)",
                border: "none",
                borderRadius: "1vw",
                boxShadow:
                  "inset 0.7vw 0.7vw 1.5vw #bebebe, inset -0.7vw -0.7vw 1.5vw #ffffff",
                padding: "0.5vw 2vh",
                outline: "none",
                width: "100%",
                marginBottom: "3vh",
                fontSize: "2.5vh",
              }}
              aria-label="Email"
              pattern=".+@.+\..+"
              title="Email should contain '@' and a domain name."
            />
          </div>
          <button
            type="button"
            className="btn w-100 mx-auto clickable d-flex justify-content-center"
            style={{
              background: "rgb(221, 220, 213)",
              border: "none",
              borderRadius: "1vw",
              padding: "0.5vw 2vh",
              outline: "none",
              marginBottom: "2vh",
              color: "rgb(26, 26, 26)",
              fontWeight: "bold",
              transition: "box-shadow 0.3s ease, transform 0.3s ease",
              boxShadow:
                "0.7vw 0.7vw 1.5vw #bebebe, -0.7vw -0.7vw 1.5vw #ffffff",
              fontSize: "2.5vh",
            }}
            onClick={sendOtp}
            disabled={timer !== 0 || otpVerified || !isEmailValid}
          >
            <FontAwesomeIcon className="me-2" />
            {timer === 0 ? buttonText : `Resend OTP in ${timer}s`}
          </button>
          {otpSent && (
            <>
              <div className="mb-3 position-relative">
                <FontAwesomeIcon
                  icon={faLock}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "1.5vw",
                    transform: "translateY(-50%)",
                    fontSize: "2.5vh",
                  }}
                />
                <input
                  type="text"
                  className="form-control ps-5"
                  placeholder="OTP"
                  required
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  style={{
                    background: "rgb(221, 220, 213)",
                    border: "none",
                    borderRadius: "1vw",
                    boxShadow:
                      "inset 0.7vw 0.7vw 1.5vw #bebebe, inset -0.7vw -0.7vw 1.5vw #ffffff",
                    padding: "0.5vh 2vw",
                    outline: "none",
                    width: "100%",
                    marginBottom: "3vh",
                    fontSize: "2.5vh",
                  }}

                />
              </div>
              <button
                type="button"
                className="btn w-100 mx-auto clickable d-flex justify-content-center"
                onClick={verifyOtp}
                style={{
                  background: "rgb(221, 220, 213)",
                  border: "none",
                  borderRadius: "1vw",
                  padding: "0.5vh 2vw",
                  outline: "none",
                  marginBottom: "3vh",
                  color: "rgb(26, 26, 26)",
                  fontWeight: "bold",
                  transition: "box-shadow 0.3s ease, transform 0.3s ease",
                  boxShadow:
                    "0.7vw 0.7vw 1.5vw #bebebe, -0.7vw -0.7vw 1.5vw #ffffff",
                  fontSize: "2.5vh",
                }}
                disabled={otpVerified}
              >
                <FontAwesomeIcon className="me-2" />
                {buttonVerifyText}
              </button>
            </>
          )}
          {otpVerified && (
            <>
              <div className="mb-3 position-relative">
                <FontAwesomeIcon
                  icon={faLock}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '1.5vw',
                    transform: 'translateY(-50%)',
                    fontSize: '2.5vh',
                  }}
                />
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  className="form-control ps-5"
                  placeholder="New Password"
                  value={password}
                  // required
                  onChange={handlePasswordChange}
                  style={{
                    background: 'rgb(221, 220, 213)',
                    border: 'none',
                    borderRadius: '1vw',
                    boxShadow: 'inset 0.7vw 0.7vw 1.5vw #bebebe, inset -0.7vw -0.7vw 1.5vw #ffffff',
                    padding: '0.5vh 2vw',
                    outline: 'none',
                    width: '100%',
                    marginBottom: '3vh',
                    fontSize: '2.5vh',
                  }}
                />
                <FontAwesomeIcon
                  icon={passwordVisible ? faEyeSlash : faEye}
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '1.5vw',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                  }}
                />
              </div>
              <div className="mb-3 position-relative">
                <FontAwesomeIcon
                  icon={faLock}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '1.5vw',
                    transform: 'translateY(-50%)',
                    fontSize: '2.5vh',
                  }}
                />
                <input
                  type={secondPasswordVisible ? 'text' : 'password'}
                  className="form-control ps-5"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  style={{
                    background: 'rgb(221, 220, 213)',
                    border: 'none',
                    borderRadius: '1vw',
                    boxShadow: 'inset 0.7vw 0.7vw 1.5vw #bebebe, inset -0.7vw -0.7vw 1.5vw #ffffff',
                    padding: '0.5vw 2vh',
                    outline: 'none',
                    width: '100%',
                    marginBottom: '3vh',
                    fontSize: '2.5vh',
                  }}
                />
                <FontAwesomeIcon
                  icon={secondPasswordVisible ? faEyeSlash : faEye}
                  onClick={toggleSecondPasswordVisibility}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '1.5vw',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                  }}
                />
              </div>

              {!passwordsMatch && (
                <div className="text-danger mb-3">Passwords do not match!</div>
              )}
              <button
                type="button"
                className="btn w-100 mx-auto clickable d-flex justify-content-center"
                onClick={changePassword}
                disabled={!passwordsMatch || passwordError || confirmPasswordError}
                style={{
                  background: "rgb(221, 220, 213)",
                  border: "none",
                  borderRadius: "1vw",
                  padding: "0.5vw 2vh",
                  outline: "none",
                  marginBottom: "2vh",
                  color: "rgb(26, 26, 26)",
                  fontWeight: "bold",
                  transition: "box-shadow 0.3s ease, transform 0.3s ease",
                  boxShadow:
                    "0.7vw 0.7vw 1.5vw #bebebe, -0.7vw -0.7vw 1.5vw #ffffff",
                  fontSize: "2.5vh",
                }}
              >
                Change Password
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
