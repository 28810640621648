import React, { useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Line } from "@react-three/drei";
import { useSelector } from "react-redux";
import { tare } from "../../../../Connect/BluetoothHandler";

function SpheresWithLine({ onGenerateFixedSphere }) {
  const { deviceinfo } = useSelector((store) => store.devicedata);

  // Position of the second sphere
  const sphere2Position = [
    deviceinfo[0]?.yp * 2 || 0, // x
    deviceinfo[0]?.zp * 2 || 0, // y
    deviceinfo[0]?.xp * 2 || 0, // z
  ];

  return (
    <>
      {/* First Sphere at position (0,0,0) */}
      <mesh position={[0, 0, 0]}>
        <sphereGeometry args={[0.2, 32, 32]} />
        <meshStandardMaterial color="hotpink" />
      </mesh>

      {/* Second Sphere */}
      <mesh
        position={sphere2Position}
        onClick={() => onGenerateFixedSphere(sphere2Position)} // Trigger fixed sphere creation
      >
        <sphereGeometry args={[0.2, 32, 32]} />
        <meshStandardMaterial color="red" />
      </mesh>

      {/* Line connecting the centers of the two spheres */}
      <Line
        points={[[0, 0, 0], sphere2Position]} // Array of points to connect
        color="yellow"
        lineWidth={10} // Adjust this to change the line width
      />
    </>
  );
}

function NewSphere() {
  const [fixedSpherePosition, setFixedSpherePosition] = useState(null);
  const { deviceinfo } = useSelector((store) => store.devicedata);

  const handleGenerateFixedSphere = (position) => {
    setFixedSpherePosition(position); // Set fixed sphere position to the position of `sphere2`
  };

  return (
    <div style={{ width: "100vw", height: "100vh", margin: 0, padding: 0, background: "lightblue" }}>
      <button
        type="button"
        className="btn btn-outline-primary"
        onClick={() => {
          tare(0);
          tare(1);
        }}
      >
        Tare
      </button>
      <Canvas style={{ width: "100%", height: "100%" }} camera={{ backgroundColor: "lightblue" }}>
        {/* Ambient and Directional Lighting */}
        <ambientLight intensity={0.7} />
        <directionalLight position={[0, 0, 5]} intensity={0.8} />

        <SpheresWithLine onGenerateFixedSphere={handleGenerateFixedSphere} />
        {fixedSpherePosition && (
          <mesh position={fixedSpherePosition}>
            <sphereGeometry args={[0.5, 32, 32]} /> {/* Larger sphere */}
            <meshStandardMaterial color="green" transparent opacity={0.5} />
          </mesh>
        )}
        <OrbitControls />
      </Canvas>
      <button
        style={{ position: "absolute", top: 20, left: 20 }}
        onClick={() =>
          handleGenerateFixedSphere([
            deviceinfo[0]?.yp * 2 || 0, // x
            deviceinfo[0]?.zp * 2 || 0, // y
            deviceinfo[0]?.xp * 2 || 0, // z
          ])
        }
      >
        Generate
      </button>
    </div>
  );
}

export default NewSphere;
