import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { mode8 } from "../../../../Connect/BluetoothHandler";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const MultiAxesModeEight = () => {
  const { deviceinfo } = useSelector((store) => store.devicedata);
  const [plotValue, setPlotValue] = useState({
    x: [],
    y: [],
    z: [],
    time: [],
  });

  const [boolean, setBoolean] = useState(deviceinfo);
  const prevBooleanRef = useRef(deviceinfo);

  useEffect(() => {
    if (deviceinfo[0]?.mode === 0) {
      // Set boolean to empty array if mode is 0
      setBoolean([]);
    } else {
      // Compare current deviceinfo with previous deviceinfo
      const prevBoolean = prevBooleanRef.current;
      const isDifferent =
        JSON.stringify(deviceinfo) !== JSON.stringify(prevBoolean);

      if (isDifferent) {
        setBoolean(deviceinfo);
        prevBooleanRef.current = deviceinfo; // Update the ref with the new deviceinfo
      }
    }
  }, [deviceinfo]);

  const plotData = useMemo(() => {
    if (boolean.length > 0 && boolean[0]?.mode === 8) {
      return {
        x: boolean[0]?.x,
        y: boolean[0]?.y,
        z: boolean[0]?.z,
        time: boolean[0]?.Time,
      };
    }
    return null;
  }, [boolean]);

  useEffect(() => {
    if (plotData) {
      setPlotValue((prev) => ({
        x: [...prev.x, ...plotData.x],
        y: [...prev.y, ...plotData.y],
        z: [...prev.z, ...plotData.z],
        time: [...prev.time, ...plotData.time],
      }));
    }
  }, [plotData]);

  const data = useMemo(
    () => ({
      labels: plotValue.time,
      datasets: [
        {
          label: "x",
          data: plotValue.x,
          fill: false,
          backgroundColor: "orange",
          borderColor: "orange",
        },
        {
          label: "y",
          data: plotValue.y,
          fill: false,
          backgroundColor: "yellow",
          borderColor: "yellow",
        },
        {
          label: "z",
          data: plotValue.z,
          fill: false,
          backgroundColor: "green",
          borderColor: "green",
        },
      ],
    }),
    [plotValue]
  );

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          title: {
            display: true,
            text: "Time",
          },
        },
        y: {
          title: {
            display: true,
            text: "Value",
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: true,
          text: "Multiple Axes Line Chart",
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "x",
          },
        },
      },
    }),
    []
  );

  const handleMode8Click = useCallback(() => {
    setPlotValue({
      x: [],
      y: [],
      z: [],
      time: [],
    });
    mode8();
  }, []);

  return (
    <>
      <div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleMode8Click}
        >
          Mode 8
        </button>
      </div>
      <div style={{ width: "100%", height: "68vh" }}>
        <Line data={data} options={options} />
      </div>
    </>
  );
};

export default MultiAxesModeEight;
