// sequenceclick.js
import { createSlice } from "@reduxjs/toolkit";

const sequenceClickSlice = createSlice({
  name: "sequenceClick",
  initialState: { sequence: [{DoctorName:"DoctoOne"}] },
  reducers: {
    addSequenceClick: (state, action) => {
      state.sequence = [...state.sequence, action.payload.value];
      console.log(state.sequence);
    },
    setSequenceClick: (state, action) => {
      state.sequence = [...action.payload.value];
      console.log(state.sequence);
    },
    removeSequenceClick: (state, action) => {
      // state.sequence.splice(action.payload.value);

      state.sequence = state.sequence.slice(0, action.payload.value);
      state.sequence = [...state.sequence];
    },
  },
});

export const sequenceClickActions = sequenceClickSlice.actions;
export default sequenceClickSlice;
