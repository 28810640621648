import React, { useEffect, useState } from "react";
// import { addPatient } from "../../store/patientsinfo";
import { sequenceClickActions } from "../../store/squenceclick";
import { currentViewActions } from "../../store/currentView";
import axios from 'axios';
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";


export default function DoctorAddPatients() {
  const [form, setForm] = useState({
    modalVisible: false,
    name: "",
    phoneNumber: "",
    gender: "male",
    birthdate: "",
    age: { years: "", months: "" },
    reason: "",
    searchQuery: "",
  });
  const dispatch = useDispatch()
  // const { patients } = useSelector((store) => store.patientsinfo);

  const [patients, setPatients] = useState([]);

  const handleAddPatient = async (e) => {
    e.preventDefault();
    const { name, phoneNumber, gender, birthdate, age, reason } = form;

    if (
      name &&
      phoneNumber &&
      gender &&
      birthdate &&
      age.years >= 0 &&
      age.months >= 0
    ) {
      try {
        
        const token = Cookies.get('token'); 

        if (!token) {
          alert('Authorization token is missing. Please log in again.');
          return;
        }

        // Sending patient data to the backend
        const response = await axios.post('https://go.raycura.com:5000/api/patients/add', {
          name,
          phoneNumber,
          gender,
          birthdate,
          age: `${age.years} years and ${age.months} months`, // Format as required
          reason: reason || "",
        }, {
          headers: {
            'Authorization': `Bearer ${token}`, // 
          }
        });

        // Dispatching action to update the Redux store if successful


        // Resetting form after successful addition
        setForm({
          modalVisible: false,
          name: "",
          phoneNumber: "",
          gender: "male",
          birthdate: "",
          age: { years: "", months: "" },
          reason: "",
          searchQuery: form.searchQuery,
        });

        alert(response.data.message); 
      } catch (error) {
        console.error('Error adding patient:', error);
        alert('There was an error adding the patient. Please try again.'); // Handle error case
      }
    } else {
      alert("Please fill in all required fields and ensure age is not negative.");
    }
  };

  const handleDeletePatient = async (patientName, phoneNumber) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete ${patientName}?`);
    if (!confirmDelete) return;
  
    try {
      const token = Cookies.get('token');
      if (!token) {
        alert('Authorization token is missing. Please log in again.');
        return;
      }
  
      const response = await axios.delete(`https://go.raycura.com:5000/api/patients/delete/${patientName}/${phoneNumber}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      console.log("Response from DELETE request:", response);

      if (response.status === 200) {
        setPatients((prevPatients) => 
          prevPatients.filter((patient) => !(patient.name === patientName && patient.phoneNumber === phoneNumber))
        );
        alert(response.data.message); 
      } else {
        alert('Error: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error deleting patient:', error.response?.data || error.message);
      alert('There was an error deleting the patient. Please try again.');
    }
  };
  

  useEffect(() => {
    const fetchPatients = async () => {
      try { 
        const token = Cookies.get('token');
        if (!token) {
          alert('Authorization token is missing. Please log in again.');
          return;
        }

        const response = await axios.get('https://go.raycura.com:5000/api/patients/list', {
          headers: { 'Authorization': `Bearer ${token}` },
        });

        console.log(response.data);
        setPatients(response.data);
      } catch (error) {
        if (error.response) {
          console.error('Server responded with an error:', error.response.data);
        } else if (error.request) {
          console.error('No response received:', error.request);
        } else {
          console.error('Error setting up the request:', error.message);
        }
        alert('Failed to load patients list.');
      }
    };

    fetchPatients();
  }, []);



  const calculateAge = (birthDate) => {
    const today = new Date();
    const dob = new Date(birthDate);

    let yearsDiff = today.getFullYear() - dob.getFullYear();
    let monthsDiff = today.getMonth() - dob.getMonth();
    let daysDiff = today.getDate() - dob.getDate();

    if (daysDiff < 0) {
      monthsDiff--;
      daysDiff =
        daysInMonth(today.getMonth(), today.getFullYear()) -
        dob.getDate() +
        today.getDate();
    }

    if (monthsDiff < 0) {
      yearsDiff--;
      monthsDiff = 12 + monthsDiff;
    }

    return { years: yearsDiff, months: monthsDiff };
  };

  const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const handleBirthdateChange = (e) => {
    const dob = e.target.value;
    setForm((prevForm) => ({
      ...prevForm,
      birthdate: dob,
      age: calculateAge(dob),
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const filteredPatients = patients.filter((patient) =>
    patient.name.toLowerCase().includes(form.searchQuery.toLowerCase())
  );

  return (
    <>
      <section className="container-fluid px-5 bg-light-red ">
        <div
          className="container-fluid bg-light-red "
          style={{ height: "90vh" }}
        >
          <div className="row my-4 ">
            <div className="col-6 position-relative">
              <input
                type="text"
                className="form-control rounded-pill border-danger"
                placeholder="Search"
                name="searchQuery"
                value={form.searchQuery}
                onChange={handleChange}
              />
              <button
                type="button"
                className="clickable btn bg-transparent border-0 position-absolute top-0 end-0 text-red rounded-pill me-4 pt-2"
              >
                <i className="fas fa-search"></i>
              </button>
            </div>
            <div className="col-2">
              <button
                type="button"
                className="btn bg-red text-white px-5 mb-3 py-1 rounded-pill fw-semibold"
                onClick={() =>
                  setForm((prevForm) => ({ ...prevForm, modalVisible: true }))
                }
              >
                <strong>Add</strong>
              </button>
            </div>
          </div>

          <div className="px-3 row  rounded-pill mt-3 pb-2 bg-white">
            <div className="row bg-white mt-2 p-2 rounded-2 text-center">
              <div className="col-2 text-red fw-semibold">Sr.No.</div>
              <div className="col-3 text-red fw-semibold text-start">Name</div>
              <div className="col-3 text-red fw-semibold">Phone Number</div>
            </div>
          </div>
          <div className="overflow-auto px-3" style={{ maxHeight: "75vh" }}>
            <style>
              {`
               .overflow-auto::-webkit-scrollbar {
                 display: none;
                }
               `}
            </style>
            {filteredPatients.map((patient, index) => (
              <div
                key={index}
                className="px-3 row d-flex  mt-3 pb-2 bg-white rounded-4 "
              >
                <div className="row border-bottom-light-red mt-2 p-2 text-center ">
                  <div className="col-2 fw-normal fw-semibold">{index + 1}</div>
                  <div className="col-3 fw-normal text-start">
                    <div
                      className="text-decoration-none text-dark"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(
                          currentViewActions.addView({ value: "Activity" })
                        );
                        dispatch(
                          sequenceClickActions.addSequenceClick({
                            value: { Patient: patient.name },
                          })
                        );
                      }}
                    >
                      {patient.name}
                    </div>
                  </div>
                  <div className="col-3 fw-normal">
                    <p className="bi text-red fw-bold">{patient.phoneNumber}</p>
                  </div>
                  <div className="col-2 fw-normal">
                    <button className="btn bg-red rounded-pill text-white fw-semibold w-75">
                      Edit
                    </button>
                  </div>
                  <div className="col-2 fw-normal">
                    <button className="btn bg-red rounded-pill text-white fw-semibold w-75"
                    onClick={() => handleDeletePatient(patient.name, patient.phoneNumber)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {form.modalVisible && (
        <div
          id="modal"
          className="modal d-flex justify-content-center align-items-center "
          style={{
            display: "block",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <div className="modal-dialog" style={{ maxWidth: "600px" }}>
            <div className="modal-content p-4 position-relative bg-light-red ">
              <span
                className="close position-absolute end-0 top-0 me-3 fs-2"
                onClick={() =>
                  setForm((prevForm) => ({ ...prevForm, modalVisible: false }))
                }
                style={{
                  cursor: "pointer",
                }}
              >
                &times;
              </span>
              <h2 className="mb-4 text-center">Patient</h2>
              <form id="addPatientForm" onSubmit={handleAddPatient}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label required">
                    Patient Name:
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    pattern="[A-Za-z\s]+"
                    value={form.name}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="number" className="form-label required">
                      Contact:
                    </label>
                    <input
                      type="tel"
                      id="number"
                      name="phoneNumber"
                      value={form.phoneNumber}
                      onChange={handleChange}
                      // pattern="[0-9]{10}"
                      className="form-control"
                      required
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="gender" className="form-label required">
                      Gender:
                    </label>
                    <select
                      id="gender"
                      name="gender"
                      value={form.gender}
                      onChange={handleChange}
                      className="form-control"
                      required
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="birthdate" className="form-label required">
                      Birthdate:
                    </label>
                    <input
                      type="date"
                      id="birthdate"
                      name="birthdate"
                      value={form.birthdate}
                      onChange={handleBirthdateChange}
                      className="form-control"
                      required
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="age" className="form-label required">
                      Age:
                    </label>
                    <input
                      type="text"
                      id="age"
                      name="age"
                      value={`${form.age.years} years and ${form.age.months} months`}
                      readOnly // Make the input read-only
                      className="form-control"
                      required
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="reason" className="form-label">
                    Reason of Visit:
                  </label>
                  <textarea
                    id="reason"
                    name="reason"
                    rows="4"
                    value={form.reason}
                    onChange={handleChange}
                    className="form-control"
                  ></textarea>
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="btn bg-red rounded-pill text-white fw-semibold w-50"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
