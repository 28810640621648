import React, { useRef } from "react";
import "./Slider.css";

export default function Slider({ onSliderChange }) {
  return (
    <div className="border border1-1 border-danger px-5 mb-5">
      <div className="row m-0 p-0 bg-white rounded-4" style={{ height: "100vh" }}>
        <RangeInput onSliderChange={onSliderChange} sliderId="speed" name="Speed" min={0.5} max={4} step={0.5} />
        <RangeInput onSliderChange={onSliderChange} sliderId="sustain" name="Sustain" min={2} max={10} />
        <RangeInput onSliderChange={onSliderChange} sliderId="randomness" name="Randomness" min={1} max={10} />
        <RangeInput onSliderChange={onSliderChange} sliderId="precision" name="Precision" min={2} max={10} />
        <RangeInput
          onSliderChange={onSliderChange}
          sliderId="repeatation"
          name="Repeatation"
          min={4}
          max={50}
          step={2}
        />
      </div>
    </div>
  );
}

const RangeInput = ({ onSliderChange, sliderId, name, min, max, step }) => {
  // Using useRef to track the slider value
  const valueRef = useRef(min);

  const handleChange = (event) => {
    valueRef.current = event.target.value; // Update the ref value
    onSliderChange(sliderId, valueRef.current); // Pass the value to the parent component
  };

  const displayName = (name) => {
    switch (name) {
      case "Speed":
        return "Response Time";
      case "Sustain":
        return "Hold Duration";
      case "Randomness":
        return "Randomness";
      case "Precision":
        return "Motor Precision";
      case "Repeatation":
        return "Repetition";
      default:
        return name; // Default case
    }
  };
  const unit = (name) => {
    switch (name) {
      case "Speed":
        return "sec";
      case "Sustain":
        return "sec";
      case "Randomness":
        return " ";
      case "Precision":
        return "Degrees";
      case "Repeatation":
        return " ";
      default:
        return name; // Default case
    }
  };
  return (
    <div className="col-6 p-0  d-flex align-items-center justify-content-center">
      <div className=" h-75 w-75 rounded-4 px-3 r ">
        <div className="text-center  fs-1 fw-bold text-red ">{displayName(name)}</div>
        <div className=" h-25  d-flex align-items-center justify-content-center ">
          <input
            type="range"
            className="form-range custom-thumb position-relative "
            min={min}
            max={max}
            defaultValue={valueRef.current}
            step={step}
            onChange={handleChange}
          />
        </div>
        <div className="h-25 d-flex align-items-center justify-content-center fs-1 fw-bold text-red ">
          <p>
            {valueRef.current} {unit(name)}
          </p>
        </div>
      </div>
    </div>
  );
};
