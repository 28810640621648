import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addPatient } from "../../store/patientsinfo";
import { sequenceClickActions } from "../../store/squenceclick";
import { currentViewActions } from "../../store/currentView";

export default function DoctorAddPatients() {
  const [form, setForm] = useState({
    modalVisible: false,
    name: "",
    phoneNumber: "",
    gender: "male",
    birthdate: "",
    age: { years: "", months: "" },
    reason: "",
    searchQuery: "",
  });
  const { patients } = useSelector((store) => store.patientsinfo);
  const dispatch = useDispatch();

  const handleAddPatient = (e) => {
    e.preventDefault();
    const { name, phoneNumber, gender, birthdate, age, reason } = form;
    if (
      name &&
      phoneNumber &&
      gender &&
      birthdate &&
      age.years >= 0 &&
      age.months >= 0
    ) {
      dispatch(
        addPatient({
          name,
          phoneNumber,
          gender,
          birthdate,
          age: `${age.years} years and ${age.months} months`,
          reason: reason || "", // Save empty string if reason is not provided
        })
      );
      setForm({
        modalVisible: false,
        name: "",
        phoneNumber: "",
        gender: "male",
        birthdate: "",
        age: { years: "", months: "" },
        reason: "",
        searchQuery: form.searchQuery,
      });
    } else {
      // Handle case where required fields are not filled or age calculation results in negative values
      alert(
        "Please fill in all required fields and ensure age is not negative."
      );
    }
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    const dob = new Date(birthDate);

    let yearsDiff = today.getFullYear() - dob.getFullYear();
    let monthsDiff = today.getMonth() - dob.getMonth();
    let daysDiff = today.getDate() - dob.getDate();

    if (daysDiff < 0) {
      monthsDiff--;
      daysDiff =
        daysInMonth(today.getMonth(), today.getFullYear()) -
        dob.getDate() +
        today.getDate();
    }

    if (monthsDiff < 0) {
      yearsDiff--;
      monthsDiff = 12 + monthsDiff;
    }

    return { years: yearsDiff, months: monthsDiff };
  };

  const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const handleBirthdateChange = (e) => {
    const dob = e.target.value;
    setForm((prevForm) => ({
      ...prevForm,
      birthdate: dob,
      age: calculateAge(dob),
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const filteredPatients = patients.filter((patient) =>
    patient.name.toLowerCase().includes(form.searchQuery.toLowerCase())
  );

  return (
    <>
      <section className="container-fluid px-5 bg-light-red ">
        <div
          className="container-fluid bg-light-red "
          style={{ height: "90vh" }}
        >
          <div className="row my-4 ">
            <div className="col-6 position-relative">
              <input
                type="text"
                className="form-control rounded-pill border-danger"
                placeholder="Search"
                name="searchQuery"
                value={form.searchQuery}
                onChange={handleChange}
              />
              <button
                type="button"
                className="clickable btn bg-transparent border-0 position-absolute top-0 end-0 text-red rounded-pill me-4 pt-2"
              >
                <i className="fas fa-search"></i>
              </button>
            </div>
            <div className="col-2">
              <button
                type="button"
                className="btn bg-red text-white px-5 mb-3 py-1 rounded-pill fw-semibold"
                onClick={() =>
                  setForm((prevForm) => ({ ...prevForm, modalVisible: true }))
                }
              >
                <strong>Add</strong>
              </button>
            </div>
          </div>

          <div className="px-3 row  rounded-pill mt-3 pb-2 bg-white">
            <div className="row bg-white mt-2 p-2 rounded-2 text-center">
              <div className="col-2 text-red fw-semibold">Sr.No.</div>
              <div className="col-3 text-red fw-semibold text-start">Name</div>
              <div className="col-3 text-red fw-semibold">Phone Number</div>
            </div>
          </div>
          <div className="overflow-auto px-3" style={{ maxHeight: "75vh" }}>
            <style>
              {`
               .overflow-auto::-webkit-scrollbar {
                 display: none;
                }
               `}
            </style>
            {filteredPatients.map((patient, index) => (
              <div
                key={index}
                className="px-3 row d-flex  mt-3 pb-2 bg-white rounded-4 "
              >
                <div className="row border-bottom-light-red mt-2 p-2 text-center ">
                  <div className="col-2 fw-normal fw-semibold">{index + 1}</div>
                  <div className="col-3 fw-normal text-start">
                    <div
                      className="text-decoration-none text-dark"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(
                          currentViewActions.addView({ value: "Activity" })
                        );
                        dispatch(
                          sequenceClickActions.addSequenceClick({
                            value: { Patient: patient.name },
                          })
                        );
                      }}
                    >
                      {patient.name}
                    </div>
                  </div>
                  <div className="col-3 fw-normal">
                    <p className="bi text-red fw-bold">{patient.phoneNumber}</p>
                  </div>
                  <div className="col-2 fw-normal">
                    <button className="btn bg-red rounded-pill text-white fw-semibold w-75">
                      Edit
                    </button>
                  </div>
                  <div className="col-2 fw-normal">
                    <button className="btn bg-red rounded-pill text-white fw-semibold w-75">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {form.modalVisible && (
        <div
          id="modal"
          className="modal d-flex justify-content-center align-items-center "
          style={{
            display: "block",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <div className="modal-dialog" style={{ maxWidth: "600px" }}>
            <div className="modal-content p-4 position-relative bg-light-red ">
              <span
                className="close position-absolute end-0 top-0 me-3 fs-2"
                onClick={() =>
                  setForm((prevForm) => ({ ...prevForm, modalVisible: false }))
                }
                style={{
                  cursor: "pointer",
                }}
              >
                &times;
              </span>
              <h2 className="mb-4 text-center">Patient</h2>
              <form id="addPatientForm" onSubmit={handleAddPatient}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label required">
                    Patient Name:
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    pattern="[A-Za-z\s]+"
                    value={form.name}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="number" className="form-label required">
                      Contact:
                    </label>
                    <input
                      type="tel"
                      id="number"
                      name="phoneNumber"
                      value={form.phoneNumber}
                      onChange={handleChange}
                      // pattern="[0-9]{10}"
                      className="form-control"
                      required
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="gender" className="form-label required">
                      Gender:
                    </label>
                    <select
                      id="gender"
                      name="gender"
                      value={form.gender}
                      onChange={handleChange}
                      className="form-control"
                      required
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="birthdate" className="form-label required">
                      Birthdate:
                    </label>
                    <input
                      type="date"
                      id="birthdate"
                      name="birthdate"
                      value={form.birthdate}
                      onChange={handleBirthdateChange}
                      className="form-control"
                      required
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="age" className="form-label required">
                      Age:
                    </label>
                    <input
                      type="text"
                      id="age"
                      name="age"
                      value={`${form.age.years} years and ${form.age.months} months`}
                      readOnly // Make the input read-only
                      className="form-control"
                      required
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="reason" className="form-label">
                    Reason of Visit:
                  </label>
                  <textarea
                    id="reason"
                    name="reason"
                    rows="4"
                    value={form.reason}
                    onChange={handleChange}
                    className="form-control"
                  ></textarea>
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="btn bg-red rounded-pill text-white fw-semibold w-50"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
