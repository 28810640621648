import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Activity from "../Application/Activity/Activity";
import DoctorAddPatients from "../DoctorAddPatients/DoctorAddPatients";
import Progress from "../Application/Progress/Progress";
import { useSelector } from "react-redux";
import { mode1, mode14, mode2, mode4 } from "../Connect/BluetoothHandler";
import Assessment from "./Assessment/Assessment";
import Workout from "../Application/Workout/Workout";
import Counter from "../Application/Counter/Counter";
import Cookies from "js-cookie";
import axios from "axios";
export default function Application() {
  const { currentView } = useSelector((store) => store.currentView); // Access the currentView from Redux store

  let [v, setv] = useState(<DoctorAddPatients />);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    if (["Assessment", "FlappyBirdMatter"].includes(currentView)) {
      mode1();
    } else if (["NewBolb", "Balance Assessment"].includes(currentView)) {
      mode2();
    } else if (currentView === "Counter") {
      mode4();
    } else if (["Workout", "Sphere"].includes(currentView)) {
      mode4();
    } else if (["Counter", "MainCylinder", "TwoCylinder"].includes(currentView)) {
      mode4();
    } else if (currentView === "ReflexGame") {
      mode14();
    }
  }, [currentView]);

  useEffect(() => {
    if (currentView === "Activity") {
      setv(<Activity />);
    } else if (["SelectionTabsAssessment", "Assessment", "Balance Assessment"].includes(currentView)) {
      setv(<Assessment />);
    } else if (currentView === "Progress") {
      setv(<Progress />);
    } else if (currentView === "DoctorAddPatients") {
      setv(<DoctorAddPatients />);
    } else if (
      [
        "SelectionTabsWorkout",
        "Workout",
        "Game",
        "BolbGame",
        "FlappyBirdMatter",
        "NewBolb",
        "Sphere",
        "ReflexGame",
        "CarRace",
      ].includes(currentView)
    ) {
      setv(<Workout />);
    } else if (
      ["SelectionTabsCounter", "Counter", "CountingReps", "MainCylinder", "NewSphere", "TwoCylinder"].includes(
        currentView
      )
    ) {
      setv(<Counter />);
    }
  }, [currentView]);

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = Cookies.get("token");
      if (!token) {
        window.location.href = "/";
        return;
      }

      try {
        const response = await axios.get("https://go.raycura.com:5000/api/auth/verify-token", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          console.log("Token is verified and user is authenticated");
          setLoading(false);
        } else {
          console.log("Token verification is failed");
          window.location.href = "/";
        }
      } catch (error) {
        console.log("Authentication check error:", error);
        window.location.href = "/";
      }
    };
    checkAuthentication();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="bg-light-red">
      {/* Navbar Component*/}
      <Navbar activeNavtab={"App"} />
      {v}
    </div>
  );
}
