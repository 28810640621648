import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { bleDevicesActions } from "../../store/bleDevices";
import { devicedataActions } from "../../store/deviceinfo";
import { useDispatch, useSelector } from "react-redux";
import { devices, disconnectDevice, isWebBluetoothEnabled, sendDataToDevice } from "./BluetoothHandler";
import Cookies from "js-cookie";
import axios from "axios";

export default function Connect() {
  const dispatch = useDispatch();
  const { deviceinfo } = useSelector((store) => store.devicedata);
  const { bleDevice } = useSelector((store) => store.bleDevices);
  const [batteryIcon, setBatteryIcon] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleconnect = () => {
    if (!devices.includes(-1)) {
      alert("Disconnect the Device");
    } else {
      for (let i = 0; i < devices.length; i++) {
        if (devices[i] === -1) {
          const updateDeviceData = (newDeviceData) => {
            dispatch(
              devicedataActions.addDeviceinfo({
                index: i,
                value: newDeviceData,
              })
            );
            dispatch(
              bleDevicesActions.addbleDevices({
                index: i,
                value: newDeviceData?.deviceId ?? "Unknown Device",
              })
            );
          };
          const updateBle = (index) => {
            // console.log(index);
            dispatch(
              bleDevicesActions.addbleDevices({
                index: index,
                value: -1,
              })
            );
          };
          isWebBluetoothEnabled(i, updateDeviceData, updateBle);
          break;
        }
      }
    }
  };

  const handledisconnect = async (i) => {
    try {
      await disconnectDevice(i);
      dispatch(
        bleDevicesActions.addbleDevices({
          index: i,
          value: -1,
        })
      );
    } catch (error) {
      console.error("Error disconnecting device:", error);
    }
  };

  const ring = (i) => {
    sendDataToDevice(i, "ring=1");
  };

  useEffect(() => {
    const batteryIcons = [0, 20, 40, 60, 80, 100];

    let index = 0;
    const intervalId = setInterval(() => {
      setBatteryIcon(batteryIcons[index]);
      index = (index + 1) % batteryIcons.length;
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = Cookies.get("token");
      if (!token) {
        window.location.href = "/";
        return;
      }

      try {
        const response = await axios.get("https://go.raycura.com:5000/api/auth/verify-token", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          console.log("Token is verified and user is authenticated");
          setLoading(false);
        } else {
          console.log("Token verification is failed");
          window.location.href = "/";
        }
      } catch (error) {
        console.log("Authentication check error:", error);
        window.location.href = "/";
      }
    };

    checkAuthentication();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="bg-light-red">
        <Navbar activeNavtab={"Connect"} />
        <section className="container-fluid px-5 bg-light-red mt-3" style={{ height: "90vh" }}>
          <div className="container-fluid py-5 px-5">
            <div className="row">
              <div className="col-2">
                <button
                  type="submit"
                  className="btn bg-red text-white px-5 mb-3 py-1 rounded-pill fw-semibold"
                  onClick={handleconnect}
                >
                  <strong>Connect</strong>
                </button>
              </div>
            </div>
            <div className="px-3 row rounded-4 mt-3 pb-2 bg-white">
              <div className="row bg-white mt-2 p-2 rounded-2 text-center">
                <div className="col-2 text-red fw-semibold">Sr.No.</div>
                <div className="col-3 text-red fw-semibold text-start">Connected Devices</div>
                <div className="col-3 text-red fw-semibold">Battery</div>
              </div>
            </div>
            <div className="px-3 row rounded-4 mt-3 pb-2 bg-white">
              {bleDevice.map((v, i) => {
                if (v !== -1) {
                  let n = i + 1;
                  return (
                    <div className="row border-bottom-light-red mt-2 p-2 text-center" key={i}>
                      <div className="col-2 fw-normal fw-semibold">{n}</div>
                      <div className="col-3 fw-normal text-start">{deviceinfo[i]?.deviceId ?? "Unknown Device"}</div>
                      <div className="col-3 fw-normal m-0 p-0 d-flex justify-content-center">
                        <p className="bi text-red fw-bold">
                          {deviceinfo[i].battery === "CHARGING" ? (
                            <div className="d-flex ">
                              <div
                                style={{
                                  height: "18px",
                                  width: "36px",
                                  padding: "1px",
                                }}
                                className="   border-1  border-red rounded rounded-1 "
                              >
                                <div
                                  className=" bg-red   rounded-end rounded-1"
                                  style={{
                                    width: `${batteryIcon}%`,
                                    height: "100%",
                                  }}
                                ></div>
                              </div>
                              <div className="bg-red mt-1" style={{ height: "8px", width: "3px" }}></div>
                            </div>
                          ) : (
                            // deviceinfo[i].battery
                            <div className="d-flex ">
                              <div
                                style={{
                                  height: "18px",
                                  width: "36px",
                                  padding: "1px",
                                }}
                                className="   border-1  border-red rounded rounded-1 "
                              >
                                <div
                                  className=" bg-red   rounded rounded-1"
                                  style={{
                                    width: `${deviceinfo[i].battery}%`,
                                    height: "100%",
                                  }}
                                ></div>
                              </div>
                              <div className="bg-red mt-1" style={{ height: "8px", width: "3px" }}></div>
                            </div>
                          )}
                        </p>
                      </div>
                      <div className="col-2 fw-normal">
                        <button
                          className="btn bg-red rounded-pill text-white fw-semibold w-75"
                          onClick={() => {
                            handledisconnect(i);
                          }}
                        >
                          disconnect
                        </button>
                      </div>
                      <div className="col-2 fw-normal">
                        <button className="btn bg-red rounded-pill text-white fw-semibold w-75" onClick={() => ring(i)}>
                          Ring
                        </button>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
