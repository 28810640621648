import React, { useState, useMemo, useRef, useEffect } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { Cylinder, Sphere, OrbitControls, PerspectiveCamera, Text, Box, RoundedBox } from "@react-three/drei";
import * as THREE from "three";
import { useSelector } from "react-redux";
import { tare } from "../../../../Connect/BluetoothHandler";
import wall from "../../../../../Images/wall.jpg";
import Slider from "./slider";

// RotatedPill component
const RotatedPill = React.forwardRef(({ x1, y1, z1 }, ref) => {
  const axis = useMemo(() => new THREE.Vector3(x1, y1, z1).normalize(), [x1, y1, z1]);
  const quaternion = useMemo(() => {
    const quaternion = new THREE.Quaternion();
    const defaultAxis = new THREE.Vector3(0, 1, 0);
    quaternion.setFromUnitVectors(defaultAxis, axis);
    return quaternion;
  }, [axis]);
  const position = useMemo(() => new THREE.Vector3(x1 / 2, y1 / 2, z1 / 2), [x1, y1, z1]);
  const radius = 0.6;
  const length = 10;

  return (
    <group ref={ref} position={position.toArray()} quaternion={quaternion}>
      <Cylinder args={[radius, radius, length, 32]} castShadow receiveShadow>
        <meshStandardMaterial color="#4B8CA3" />
      </Cylinder>
      <Sphere args={[radius, 32, 32]} position={[0, length / 2, 0]} castShadow receiveShadow>
        <meshStandardMaterial color="#4B8CA3" />
      </Sphere>
      <Sphere args={[radius, 32, 32]} position={[0, -length / 2, 0]} castShadow receiveShadow>
        <meshStandardMaterial color="#4B8CA3" />
      </Sphere>
    </group>
  );
});

// RotatedPill2 component
const RotatedPill2 = React.forwardRef(({ x1, y1, z1, x2, y2, z2 }, ref) => {
  const axis = useMemo(() => new THREE.Vector3(x2, y2, z2).normalize(), [x2, y2, z2]);
  const quaternion = useMemo(() => {
    const quaternion = new THREE.Quaternion();
    const defaultAxis = new THREE.Vector3(0, 1, 0);
    quaternion.setFromUnitVectors(defaultAxis, axis);
    return quaternion;
  }, [axis]);

  // Ensure the position is based on Rod 2
  const position = useMemo(() => new THREE.Vector3(x1 + x2 / 2, y1 + y2 / 2, z1 + z2 / 2), [x1, y1, z1, x2, y2, z2]);

  // Adjust length based on Rod 2's values if needed
  const length = 10; // You may want to base this on actual Rod 2 length

  return (
    <group ref={ref} position={position.toArray()} quaternion={quaternion}>
      <Cylinder args={[0.6, 0.6, length, 32]} castShadow receiveShadow>
        <meshStandardMaterial color="#4B8CA3" />
      </Cylinder>
      <Sphere args={[0.6, 32, 32]} position={[0, length / 2, 0]} castShadow receiveShadow>
        <meshStandardMaterial color="#4B8CA3" />
      </Sphere>
      <Sphere args={[0.6, 32, 32]} position={[0, -length / 2, 0]} castShadow receiveShadow>
        <meshStandardMaterial color="#4B8CA3" />
      </Sphere>
    </group>
  );
});

function WallWithTexture({ position, scale }) {
  // Use the imported wall image instead of the relative path
  const texture = useLoader(THREE.TextureLoader, wall);

  return (
    <>
      <mesh position={position} scale={scale} receiveShadow>
        <boxGeometry args={[1, 1, 1]} />
        <meshStandardMaterial map={texture} />
      </mesh>
      <mesh position={position} scale={scale} receiveShadow>
        <boxGeometry args={[1, 1, 1]} />
        <meshStandardMaterial map={texture} />
      </mesh>
      <mesh position={position} scale={scale} receiveShadow>
        <boxGeometry args={[1, 1, 1]} />
        <meshStandardMaterial map={texture} />
      </mesh>
    </>
  );
}

function Room({
  holdTime,
  completionCount,
  handleGenerateSphere,
  handleGenerateSecondSphere,
  handleClear,
  setClickCount,
}) {
  const roomSize = 90;
  const rectWidth = 100;
  const rectHeight = 20;

  const [clickCount, setClickCountLocal] = useState(0);

  const handleScrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleScrollDown = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  const handleClick = () => {
    if (clickCount === 0) {
      handleGenerateSphere();
      setClickCountLocal(1);
    } else if (clickCount === 1) {
      handleGenerateSecondSphere();
      setClickCountLocal(0);
    }
  };

  return (
    <>
      {/* Floor */}
      <mesh position={[0, -roomSize / 2, 0]} receiveShadow>
        <boxGeometry args={[roomSize * 2, 1, roomSize * 2]} />
        <meshStandardMaterial color="#A7C2CC" />
      </mesh>

      {/* Walls */}
      <WallWithTexture position={[0, 0, roomSize]} scale={[roomSize * 2, roomSize, 1]} />
      <WallWithTexture position={[roomSize, 0, 0]} scale={[1, roomSize, roomSize * 2]} />
      <WallWithTexture position={[-roomSize, 0, 0]} scale={[1, roomSize, roomSize * 2]} />
      {/* <mesh position={[roomSize, 0, 0]} receiveShadow>
        <boxGeometry args={[1, roomSize, roomSize * 2]} />
        <meshStandardMaterial color="#81f1ff" />
      </mesh>
      <mesh position={[-roomSize, 0, 0]} receiveShadow>
        <boxGeometry args={[1, roomSize, roomSize * 2]} />
        <meshStandardMaterial color="#AFEEEE" />
      </mesh> */}

      {/* Hold Time Display */}
      <group position={[0, 25, 88]} rotation={[0, Math.PI, 0]}>
        <mesh position={[0, 0, 0]}>
          <planeGeometry args={[rectWidth, rectHeight]} />
          <meshBasicMaterial color="white" />
        </mesh>
        <Text position={[-25, 5, 1]} fontSize={4} color="black" anchorX="center" anchorY="middle" textAlign="center">
          Hold
        </Text>
        <Text position={[-25, -3, 1]} fontSize={8} color="black" anchorX="center" anchorY="middle" textAlign="center">
          {holdTime.toFixed(2)}
        </Text>
        <Text position={[25, 5, 1]} fontSize={4} color="black" anchorX="center" anchorY="middle" textAlign="center">
          Count
        </Text>
        <Text position={[25, -3, 1]} fontSize={8} color="black" anchorX="center" anchorY="middle" textAlign="center">
          {completionCount}
        </Text>
      </group>

      {/* Scroll Up Button */}
      <Box
        args={[20, 1, 20]} // Size of the button
        position={[-70, -25, 88]} // Position on the right side of the wall
        rotation={[Math.PI / 2, 0, 0]} // Rotate the button (adjust Y rotation as needed)
        onClick={handleScrollUp}
        onPointerOver={(e) => e.object.material.color.set("white")}
        onPointerOut={(e) => e.object.material.color.set("#A7C2CC")}
      >
        <meshStandardMaterial color="#A7C2CC" />
      </Box>
      <Text
        position={[-69, -24, 85]} // Position above the button
        fontSize={4.5}
        color="black"
        anchorX="center"
        anchorY="middle"
        textAlign="center"
        rotation={[0, Math.PI, 0]} // Rotate to face upward
      >
        Scroll {"\n"} Up
      </Text>

      <Box
        args={[20, 1, 20]} // Size of the button
        position={[-70, 25, 88]} // Position on the right side of the wall
        rotation={[Math.PI / 2, 0, 0]} // Rotate the button (adjust Y rotation as needed)
        onClick={handleScrollDown}
        onPointerOver={(e) => e.object.material.color.set("white")}
        onPointerOut={(e) => e.object.material.color.set("#A7C2CC")}
      >
        <meshStandardMaterial color="#A7C2CC" />
      </Box>
      <Text
        position={[-69, 25, 85]} // Position above the button
        fontSize={4.5}
        color="black"
        anchorX="center"
        anchorY="middle"
        textAlign="center"
        rotation={[0, Math.PI, 0]} // Rotate to face upward
      >
        Scroll{"\n"} Down
      </Text>

      {/* Tare Button */}
      <RoundedBox
        args={[20, 20, 1]} // Size of the button [width, height, depth]
        position={[70, 25, 88]} // Adjust the position as needed
        radius={0.4} // Border radius
        smoothness={4} // Adjust smoothness for more rounded corners
        onClick={() => {
          tare(0);
          tare(1);
        }}
        onPointerOver={(e) => e.object.material.color.set("white")} // Hover effect
        onPointerOut={(e) => e.object.material.color.set("#A7C2CC")} // Hover out effect
      >
        <meshStandardMaterial color="#A7C2CC" />
      </RoundedBox>

      <Text
        position={[69, 25, 85]} // Position the text slightly above the button
        fontSize={4.5}
        rotation={[0, Math.PI, 0]}
        color="Black"
        anchorX="center"
        anchorY="middle"
      >
        Tare
      </Text>

      {/* Generate Sphere Button */}
      <RoundedBox
        args={[20, 20, 1]} // Size of the button [width, height, depth]
        position={[70, 0, 88]} // Adjust the position as needed
        radius={0.4} // Border radius
        smoothness={4} // Adjust smoothness for more rounded corners
        onClick={handleClick}
        onPointerOver={(e) => e.object.material.color.set("white")} // Hover effect
        onPointerOut={(e) => e.object.material.color.set("#A7C2CC")} // Hover out effect
      >
        <meshStandardMaterial color="#A7C2CC" />
      </RoundedBox>

      <Text
        position={[69, 0, 85]} // Position the text slightly above the button
        fontSize={4.5}
        rotation={[0, Math.PI, 0]}
        color="black"
        anchorX="center"
        anchorY="middle"
        textAlign="center"
      >
        Position
      </Text>

      {/* Clear Button */}
      <RoundedBox
        args={[20, 20, 1]} // Size of the button [width, height, depth]
        position={[70, -25, 88]} // Adjust the position as needed
        radius={0.4} // Border radius
        smoothness={4} // Adjust smoothness for more rounded corners
        onClick={() => handleClear(setClickCountLocal)}
        onPointerOver={(e) => e.object.material.color.set("white")} // Hover effect
        onPointerOut={(e) => e.object.material.color.set("#A7C2CC")} // Hover out effect
      >
        <meshStandardMaterial color="#A7C2CC" />
      </RoundedBox>

      <Text
        position={[69, -25, 85]} // Position the text slightly above the button
        fontSize={4.5}
        rotation={[0, Math.PI, 0]}
        color="black"
        anchorX="center"
        anchorY="middle"
      >
        Clear
      </Text>
    </>
  );
}

const calculateSphereR = (precision) => {
  const precisionRad = precision * (Math.PI / 180);
  return 10 * Math.sqrt(2 - 2 * Math.cos(precisionRad));
};

// SphereHandler component
function SphereHandler({
  x1,
  y1,
  z1,
  x2,
  y2,
  z2,
  currentSphereIndex,
  setCurrentSphereIndex,
  incrementCompletionCount,
  generationStopped,
  startTimer,
  setHoldTime,
  isSecondSphereActive,
  secondSpherePosition,
  holdTime,
  maxCount,
  isSphereVisible, // Add isSphereVisible as a prop
  precision, // Accept precision as a prop
}) {
  const [isColliding, setIsColliding] = useState(false);
  const [holdTimeout, setHoldTimeout] = useState(null);
  const [isHolding, setIsHolding] = useState(false);
  const [holdStartTime, setHoldStartTime] = useState(null);
  const [sphereVisible, setSphereVisible] = useState([true, true]);
  const [fixedSpherePosition, setFixedSpherePosition] = useState(null);

  useEffect(() => {
    if (currentSphereIndex === 0 && fixedSpherePosition === null) {
      setFixedSpherePosition(new THREE.Vector3(x1 + x2, y1 + y2, z1 + z2));
      console.log("First sphere at Rod 2:", x2, y2, z2);

      const hideAndShowTimeout = setTimeout(() => {
        setSphereVisible([false, false]);
        const showFirstSphereTimeout = setTimeout(() => {
          setSphereVisible([true, false]);
          console.log("First sphere visible for collision");
        }, 3000);

        return () => clearTimeout(showFirstSphereTimeout);
      }, 3000);

      return () => clearTimeout(hideAndShowTimeout);
    }
  }, [currentSphereIndex, fixedSpherePosition, x1, x2, y1, y2, z1, z2]);

  useFrame(() => {
    if (!generationStopped && isSecondSphereActive) {
      const sphereRadius = calculateSphereR(precision); // Calculate sphere radius based on precision
      if (fixedSpherePosition && currentSphereIndex === 0 && sphereVisible[0]) {
        handleCollision(fixedSpherePosition, sphereRadius, 0);
      }
      if (secondSpherePosition && currentSphereIndex === 1 && sphereVisible[1]) {
        handleCollision(secondSpherePosition, sphereRadius, 1);
      }
    }
  });

  const handleCollision = (spherePosition, sphereRadius, sphereIndex) => {
    const rod2TipPosition = new THREE.Vector3(x1 + x2, y1 + y2, z1 + z2);
    const distanceToSphere = rod2TipPosition.distanceTo(spherePosition);
    const adjustedSphereRadius = sphereRadius * 0.9;

    if (distanceToSphere <= adjustedSphereRadius) {
      if (!isColliding) {
        setIsColliding(true);
        setIsHolding(true);
        setHoldStartTime(Date.now());
        console.log(`Collision with sphere ${sphereIndex + 1} detected. Starting hold timer...`);

        const timeout = setTimeout(() => {
          setIsHolding(false);
          setIsColliding(false);
          const nextIndex = (sphereIndex + 1) % 2;
          setCurrentSphereIndex(nextIndex);

          if (sphereIndex === 1) {
            incrementCompletionCount();
          }

          const newVisibility = [false, false];
          newVisibility[nextIndex] = true;
          setSphereVisible(newVisibility);
          console.log(`Completed hold on sphere ${sphereIndex + 1}. Switching sphere.`);
        }, holdTime * 1000); // Use the updated holdTime value

        setHoldTimeout(timeout);
        if (sphereIndex === 0) {
          startTimer();
        }
      } else {
        const elapsedHoldTime = Math.floor((Date.now() - holdStartTime) / 1000);
        setHoldTime(elapsedHoldTime); // Update hold time based on how long it's been held
      }
    } else {
      if (isColliding) {
        setIsColliding(false);
        setIsHolding(false);
        clearTimeout(holdTimeout);
        console.log(`Lost collision with sphere ${sphereIndex + 1}. Resetting.`);
      }
    }
  };

  return (
    <>
      {isSphereVisible && (
        <>
          {sphereVisible[0] && fixedSpherePosition && (
            <mesh position={fixedSpherePosition.toArray()} castShadow receiveShadow>
              <sphereGeometry args={[calculateSphereR(precision), 32, 32]} /> {/* Use precision for radius */}
              <meshStandardMaterial
                color={currentSphereIndex === 0 && isHolding ? "yellow" : "rgb(100, 255, 180)"}
                transparent
                opacity={0.6}
              />
            </mesh>
          )}

          {isSecondSphereActive && sphereVisible[1] && secondSpherePosition && (
            <mesh position={secondSpherePosition.toArray()} castShadow receiveShadow>
              <sphereGeometry args={[calculateSphereR(precision), 32, 32]} /> {/* Use precision for radius */}
              <meshStandardMaterial
                color={isColliding && currentSphereIndex === 1 ? "yellow" : "rgb(100, 255, 180)"}
                transparent
                opacity={0.6}
              />
            </mesh>
          )}
        </>
      )}
    </>
  );
}
function TwoCylinder() {
  const { deviceinfo } = useSelector((store) => store.devicedata);
  const [currentSphereIndex, setCurrentSphereIndex] = useState(null);
  const [completionCount, setCompletionCount] = useState(5);
  const [holdTime, setHoldTime] = useState(3); // Current hold time
  const [originalHoldTime, setOriginalHoldTime] = useState(3); // Store the original hold time
  const [precision, setPrecision] = useState(5); // Precision for sphere radius
  const [generationStopped, setGenerationStopped] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isSecondSphereActive, setIsSecondSphereActive] = useState(false);
  const [secondSpherePosition, setSecondSpherePosition] = useState(null);
  const [maxCount, setMaxCount] = useState(3); // Initialize maxCount
  const pillRef = useRef();
  const [isSphereVisible, setIsSphereVisible] = useState(true); // Add isSphereVisible state variable
  const [clickCount, setClickCount] = useState(0); // Add clickCount state variable
  const [sphereKey, setSphereKey] = useState(0);

  const handleClear = () => {
    setIsSphereVisible(false);
    setCurrentSphereIndex(null);
    setSecondSpherePosition(null);
    setIsSecondSphereActive(false);
    setCompletionCount(0);
    setHoldTime(originalHoldTime);
    setStartTime(null);
    setElapsedTime(0);
    setClickCount(0);
    setSphereKey((prevKey) => prevKey + 1); // Update the sphere key

    // Delay the execution of setIsSphereVisible(true) by 1 second
    setTimeout(() => {
      setIsSphereVisible(true);
    }, 1000);
  };

  const handleGenerateSphere = () => {
    setCurrentSphereIndex(0);
    setStartTime(null);
    setCompletionCount(0);
    setElapsedTime(0);
    setGenerationStopped(false);
    setHoldTime(originalHoldTime); // Reset hold time to the original value
  };

  const handleSliderChange = (sliderId, value) => {
    switch (sliderId) {
      case "holdDuration":
        const parsedHoldTime = parseInt(value);
        setHoldTime(parsedHoldTime); // Update current hold time
        setOriginalHoldTime(parsedHoldTime); // Store the original hold time
        break;
      case "count":
        setMaxCount(parseInt(value)); // Update maxCount from slider
        break;
      case "precision":
        setPrecision(parseInt(value)); // Update precision from slider
        break;
      default:
        console.log(`Unknown slider ID: ${sliderId}`);
    }
  };

  const handleGenerateSecondSphere = () => {
    if (deviceinfo[1]) {
      setSecondSpherePosition(
        new THREE.Vector3(
          (deviceinfo[1]?.yp + deviceinfo[0]?.yp) * 10,
          (deviceinfo[1]?.zp + deviceinfo[0]?.zp) * 10,
          (deviceinfo[1]?.xp + deviceinfo[0]?.xp) * 10
        )
      );
      setIsSecondSphereActive(true);
      console.log(
        "Second sphere position set at Rod2:",
        deviceinfo[1]?.yp * 10,
        deviceinfo[1]?.zp * 10,
        deviceinfo[1]?.xp * 10
      );
    } else {
      console.warn("Device data for Rod2 (deviceinfo[1]) is not available");
    }
  };

  const incrementCompletionCount = () => {
    setCompletionCount((prevCount) => {
      const newCount = prevCount + 1;
      setElapsedTime(((Date.now() - startTime) / 1000).toFixed(2));

      if (newCount >= maxCount) {
        setGenerationStopped(true); // Stop sphere generation when max count is reached
        console.log(`Sphere generation stopped after ${newCount} cycles.`);
      }
      return newCount;
    });
  };

  const handleStopGeneration = () => {
    setGenerationStopped(true);
  };

  const startTimer = () => {
    if (startTime === null) {
      setStartTime(Date.now());
    }
  };

  useEffect(() => {
    let interval;
    if (startTime && !generationStopped) {
      interval = setInterval(() => {
        const timeElapsed = ((Date.now() - startTime) / 1000).toFixed(2);
        const remainingTime = Math.max(0, originalHoldTime - timeElapsed);

        // Prevent fluctuating behavior when time reaches 0
        if (remainingTime <= 0) {
          setHoldTime(0);
          clearInterval(interval); // Stop the timer once it hits zero
        } else {
          setHoldTime(remainingTime);
        }

        setElapsedTime(timeElapsed);
      }, 100);
    }
    return () => clearInterval(interval);
  }, [startTime, generationStopped, originalHoldTime]);

  return (
    <div>
      <Slider
        onSliderChange={handleSliderChange}
        holdTime={originalHoldTime} // Always pass the original hold time
        count={completionCount} // Pass current count
        precision={precision} // Pass current precision
      />
      <Canvas shadows style={{ width: "100vw", height: "100vh", padding: 0 }}>
        <PerspectiveCamera makeDefault position={[0, 3, -15]} near={1} far={1000} />
        <ambientLight intensity={0.7} />
        <directionalLight
          position={[-5, 5, -5]}
          intensity={1.5}
          castShadow
          shadow-mapSize-width={2048}
          shadow-mapSize-height={2048}
          shadow-camera-near={0.5}
          shadow-camera-far={100}
          shadow-camera-left={-20}
          shadow-camera-right={20}
          shadow-camera-top={20}
          shadow-camera-bottom={-20}
          shadow-radius={20}
        />
        {/* Scene objects */}
        <Room
          holdTime={holdTime}
          completionCount={completionCount}
          handleGenerateSphere={handleGenerateSphere}
          handleGenerateSecondSphere={handleGenerateSecondSphere}
          handleClear={handleClear}
          setClickCount={setClickCount}
        />
        {/* Pill and Spheres */}
        <RotatedPill
          ref={pillRef}
          x1={deviceinfo[0]?.yp * 10}
          y1={deviceinfo[0]?.zp * 10}
          z1={deviceinfo[0]?.xp * 10}
        />
        <RotatedPill2
          x1={deviceinfo[0]?.yp * 10}
          y1={deviceinfo[0]?.zp * 10}
          z1={deviceinfo[0]?.xp * 10}
          x2={deviceinfo[1]?.yp * 10}
          y2={deviceinfo[1]?.zp * 10}
          z2={deviceinfo[1]?.xp * 10}
        />
        <SphereHandler
          x1={deviceinfo[0]?.yp * 10}
          y1={deviceinfo[0]?.zp * 10}
          z1={deviceinfo[0]?.xp * 10}
          x2={deviceinfo[1]?.yp * 10}
          y2={deviceinfo[1]?.zp * 10}
          z2={deviceinfo[1]?.xp * 10}
          currentSphereIndex={currentSphereIndex}
          setCurrentSphereIndex={setCurrentSphereIndex}
          incrementCompletionCount={incrementCompletionCount}
          generationStopped={generationStopped}
          startTimer={startTimer}
          setHoldTime={setHoldTime}
          isSecondSphereActive={isSecondSphereActive}
          secondSpherePosition={secondSpherePosition}
          holdTime={originalHoldTime} // Always use original hold time
          maxCount={completionCount}
          precision={precision} // Ensure precision is passed correctly
          isSphereVisible={isSphereVisible} // Pass isSphereVisible state variable
          setIsSphereVisible={setIsSphereVisible} // Pass setIsSphereVisible state up daters
          key={sphereKey} // Add the key prop
        />
        <OrbitControls minDistance={55} maxDistance={55} zoomSpeed={1} enableRotate={true} enableZoom={true} />
      </Canvas>
    </div>
  );
}

export default TwoCylinder;
