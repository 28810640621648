import React, { useRef, useState, useEffect, useCallback } from "react";
import "./NewBolb.css";
import { useSelector } from "react-redux";
import { tare } from "../../../../Connect/BluetoothHandler";

export default function NewBolb() {
  let { deviceinfo } = useSelector((store) => store.devicedata);

  const arcCanvasRef = useRef(null);
  const [outerColors, setOuterColors] = useState(Array(8).fill("white"));
  const [innerColors, setInnerColors] = useState(Array(8).fill("white"));
  const [selectedBodies, setSelectedBodies] = useState([]);
  const [unusedRegions, setUnusedRegions] = useState([]);

  const centerX = 250;
  const centerY = 250;
  const outerRadius = 200;
  const innerRadius = 100;
  const numSegments = 8;
  const angleIncrement = (2 * Math.PI) / numSegments;

  const Regions = [
    { "canvasHeight/4": 0 },
    { "canvasHeight/2": 0 },
    { "canvasHeight/4": 45 },
    { "canvasHeight/2": 45 },
    { "canvasHeight/4": 90 },
    { "canvasHeight/2": 90 },
    { "canvasHeight/4": 135 },
    { "canvasHeight/2": 135 },
    { "canvasHeight/4": 180 },
    { "canvasHeight/2": 180 },
    { "canvasHeight/4": 225 },
    { "canvasHeight/2": 225 },
    { "canvasHeight/4": 270 },
    { "canvasHeight/2": 270 },
    { "canvasHeight/4": 315 },
    { "canvasHeight/2": 315 },
  ];

  useEffect(() => {
    const canvas = arcCanvasRef.current;
    const ctx = canvas.getContext("2d");

    const drawSegment = (startAngle, endAngle, outerRadius, innerRadius, color) => {
      ctx.beginPath();
      ctx.moveTo(centerX + innerRadius * Math.cos(startAngle), centerY + innerRadius * Math.sin(startAngle));
      ctx.arc(centerX, centerY, outerRadius, startAngle, endAngle);
      ctx.lineTo(centerX + innerRadius * Math.cos(endAngle), centerY + innerRadius * Math.sin(endAngle));
      ctx.arc(centerX, centerY, innerRadius, endAngle, startAngle, true);
      ctx.closePath();
      ctx.fillStyle = color;
      ctx.fill();
      ctx.stroke();
      ctx.strokeStyle = "red";
      ctx.lineWidth = 4;
    };

    const drawCircle = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < numSegments; i++) {
        const startAngle = i * angleIncrement;
        const endAngle = startAngle + angleIncrement;
        drawSegment(startAngle, endAngle, outerRadius, innerRadius, outerColors[i]);
        drawSegment(startAngle, endAngle, innerRadius, 0, innerColors[i]);
      }
    };

    drawCircle();
  }, [outerColors, innerColors, angleIncrement]);

  const getAngle = (x, y) => {
    let angle = Math.atan2(y - centerY, x - centerX);
    if (angle < 0) {
      angle += 2 * Math.PI;
    }
    return angle;
  };

  const getDistance = (x, y) => {
    return Math.sqrt((x - centerX) ** 2 + (y - centerY) ** 2);
  };

  const handleCanvasClick = (event) => {
    const rect = arcCanvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const angle = getAngle(x, y);
    const distance = getDistance(x, y);

    const segmentIndex = Math.floor(angle / angleIncrement);

    if (distance <= innerRadius) {
      const newInnerColors = [...innerColors];
      newInnerColors[segmentIndex] = newInnerColors[segmentIndex] === "red" ? "white" : "red";
      setInnerColors(newInnerColors);

      updateSelectedBodies(segmentIndex, newInnerColors[segmentIndex], "inner");
    } else if (distance <= outerRadius) {
      const newOuterColors = [...outerColors];
      newOuterColors[segmentIndex] = newOuterColors[segmentIndex] === "red" ? "white" : "red";
      setOuterColors(newOuterColors);

      updateSelectedBodies(segmentIndex, newOuterColors[segmentIndex], "outer");
    }
  };

  const updateSelectedBodies = (index, color, circleType) => {
    const regionIndex = circleType === "inner" ? index * 2 : index * 2 + 1;
    const regionKey = circleType === "inner" ? "canvasHeight/4" : "canvasHeight/2";
    const regionValue = Regions[regionIndex][regionKey];

    setSelectedBodies((prevSelectedBodies) => {
      const newSelectedBodies = Array.isArray(prevSelectedBodies) ? [...prevSelectedBodies] : [];
      const bodyIndex = newSelectedBodies.findIndex((obj) => obj[regionKey] === regionValue);

      if (color === "red") {
        if (bodyIndex === -1) {
          newSelectedBodies.push({ [regionKey]: regionValue });
        }
      } else {
        if (bodyIndex !== -1) {
          newSelectedBodies.splice(bodyIndex, 1);
        }
      }
      return newSelectedBodies;
    });
  };

  useEffect(() => {
    setUnusedRegions(selectedBodies);
  }, [selectedBodies]);

  const ballCanvasRef = useRef(null);
  const [canvasWidth] = useState(window.innerHeight * 0.95);
  const [canvasHeight] = useState(window.innerHeight * 0.95);
  const [ballSize] = useState(0.05 * canvasHeight); // 3% of 400
  // const [ballX, setBallX] = useState(0);
  // const [ballY, setBallY] = useState(0);
  // Initialize ballX and ballY with valid values
  const [ballX, setBallX] = useState(canvasWidth / 2);
  const [ballY, setBallY] = useState(canvasHeight / 2);

  const [xwidth] = useState(20); // yaw range
  const [yheight] = useState(20); // roll range
  const [rotation, setRotation] = useState(0); // Rotation angle in degrees
  const [distance, setDistance] = useState(0); // Distance from the center
  const [collision, setCollison] = useState(true);
  const [sustain, setSustain] = useState(1000);
  const [precision, setPrecision] = useState(6);
  const border = canvasHeight * 0.02;
  const [isGameRunning, setIsGameRunning] = useState(false);
  const [score, setScore] = useState(0);
  const [startTime, setStartTime] = useState(3); // Start timer with 3 seconds countdown
  const [timer, setTimer] = useState(0);
  const [timerInterval, setTimerInterval] = useState(null);

  const blackBallRadius = (canvasHeight / yheight) * precision * 0.5 + ballSize + canvasHeight * 0.025; // 3% of canvas height
  const [collisionStartTime, setCollisionStartTime] = useState(null);
  useEffect(() => {
    if (collision) {
      if (unusedRegions.length > 0) {
        const randomRegion = unusedRegions[Math.floor(Math.random() * unusedRegions.length)];
        const [key, value] = Object.entries(randomRegion)[0];

        const divisor = key.split("/")[1];
        const maxDistance = canvasHeight / divisor;
        console.log(maxDistance);
        const minDistance = maxDistance - canvasHeight / 4 + canvasHeight / 8;
        const newDistance = Math.random() * (maxDistance - minDistance) + minDistance - blackBallRadius;
        const minRotation = Number(value);
        const maxRotation = minRotation + 45;
        const newRotation = Math.random() * (maxRotation - minRotation) + minRotation;
        setDistance(newDistance);
        setRotation(newRotation);

        setUnusedRegions((prevUnusedRegions) => prevUnusedRegions.filter((region) => region !== randomRegion));
        setCollison(false);
      } else {
        setUnusedRegions(selectedBodies);
      }
    }
  }, [canvasHeight, unusedRegions, selectedBodies, collision, blackBallRadius]);

  const checkCollision = useCallback(
    (ballX, ballY, blackBallX, blackBallY, ballSize, blackBallRadius) => {
      let distanceBetweenCenters = Math.sqrt(
        (blackBallX - ballX) * (blackBallX - ballX) + (blackBallY - ballY) * (blackBallY - ballY)
      );

      if (distanceBetweenCenters <= blackBallRadius - ballSize - border / 2) {
        if (!collisionStartTime) {
          setCollisionStartTime(Date.now());
        } else {
          const elapsed = Date.now() - collisionStartTime;
          if (elapsed >= Math.floor(sustain)) {
            if (!collision) {
              setCollison(true);
              setScore((prevScore) => prevScore + 1); // Increment score on collision
              console.log(
                "Blue ball is inside the black ball for 2 seconds",
                distanceBetweenCenters,
                blackBallRadius - ballSize
              );
            }
          }
        }
      } else {
        setCollisionStartTime(null);
        setCollison(false);
        console.log("No collision detected", blackBallX, blackBallY);
      }
    },
    [collision, collisionStartTime, sustain, border]
  );

  useEffect(() => {
    // let animationFrameId;

    let aps = deviceinfo[0].ap;
    let mts = deviceinfo[0].mt;

    // Calculate distance from center
    let distance = Math.sqrt(aps * aps + mts * mts);

    // If distance is greater than 20, adjust the values
    if (distance > 20) {
      let factor = 20 / distance;
      aps = aps * factor;
      mts = mts * factor;
    }

    const animateBall = (aps, mts) => {
      const newBallX = ((mts / xwidth) * canvasWidth) / 2 + canvasWidth / 2;
      const newBallY = ((aps / yheight) * canvasHeight) / 2 + canvasHeight / 2;

      setBallX(newBallX);
      setBallY(newBallY);
    };

    animateBall(aps, mts);
  }, [deviceinfo, canvasHeight, canvasWidth, xwidth, yheight]);

  useEffect(() => {
    const canvas = ballCanvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Calculate black ball position
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;
    const radianAngle = (rotation * Math.PI) / 180;
    const blackBallX = centerX + distance * Math.cos(radianAngle);
    const blackBallY = centerY + distance * Math.sin(radianAngle);
    const lineWidth = border; // Adjust the multiplier as needed

    // Neomorphic effect settings
    const shadowOffset = 4;
    const shadowBlur = 8;

    // Draw background ring (light red) with neomorphic effect
    ctx.beginPath();
    ctx.arc(blackBallX, blackBallY, blackBallRadius, 0, 2 * Math.PI);
    ctx.fillStyle = "rgb(226, 237 ,243)";
    ctx.fill();

    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = "#B9D9F6";
    ctx.stroke();

    // Ensure valid values for ballX and ballY before drawing the yellow ball
    if (isFinite(ballX) && isFinite(ballY)) {
      const gradient = ctx.createRadialGradient(
        ballX - ballSize / 4,
        ballY - ballSize / 4,
        ballSize / 8,
        ballX,
        ballY,
        ballSize
      );
      gradient.addColorStop(0, "rgba(255, 235, 59, 1)"); // Bright yellow center
      gradient.addColorStop(0.7, "rgba(255, 193, 7, 1)"); // Golden yellow mid
      gradient.addColorStop(1, "rgba(255, 182, 0, 1)"); // Darker yellow edge

      ctx.beginPath();
      ctx.arc(ballX, ballY, ballSize, 0, 2 * Math.PI);
      ctx.fillStyle = gradient;
      ctx.fill();

      // Apply subtle inner shadow for 3D effect
      ctx.shadowOffsetX = shadowOffset;
      ctx.shadowOffsetY = shadowOffset;
      ctx.shadowBlur = shadowBlur;
      ctx.shadowColor = "rgba(0, 0, 0, 0.1)"; // Slightly darker shadow for depth
      ctx.fill();
    }

    // Only check for collisions and update the progress arc when the game is running
    if (isGameRunning) {
      checkCollision(ballX, ballY, blackBallX, blackBallY, ballSize, blackBallRadius);

      if (collisionStartTime) {
        const elapsed = Date.now() - collisionStartTime;
        const progress = elapsed / sustain;

        ctx.beginPath();
        ctx.arc(
          blackBallX,
          blackBallY,
          blackBallRadius,
          -0.5 * Math.PI, // Start at the top (12 o'clock)
          2 * Math.PI * progress - 0.5 * Math.PI // Draw the arc according to progress
        );
        ctx.strokeStyle = "rgb(120, 171 ,79)";
        ctx.lineWidth = lineWidth;
        ctx.lineCap = "round"; // Add rounded edges to the progress
        ctx.stroke();
      }
    }
  }, [
    ballSize,
    ballX,
    ballY,
    canvasWidth,
    canvasHeight,
    rotation,
    distance,
    blackBallRadius,
    checkCollision,
    collisionStartTime,
    sustain,
    border,
    isGameRunning,
  ]);
  useEffect(() => {
    let timerInterval;
    if (startTime > 0 && isGameRunning) {
      timerInterval = setInterval(() => {
        setStartTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (startTime === 0 && isGameRunning) {
      setIsGameRunning(true);
      setStartTime(null); // Stop the timer
    }

    return () => clearInterval(timerInterval);
  }, [startTime, isGameRunning]);

  // useEffect(() => {
  //   let timerInterval;
  //   if (startTime > 0 && isGameRunning) {
  //     timerInterval = setInterval(() => {
  //       setStartTime((prevTime) => prevTime - 1);
  //     }, 1000);
  //   } else if (startTime === 0 && isGameRunning) {
  //     setIsGameRunning(true);
  //     setStartTime(null); // Stop the timer
  //   }

  //   return () => clearInterval(timerInterval);
  // }, [startTime, isGameRunning]);
  const resetGame = () => {
    setIsGameRunning(false);
    clearInterval(timerInterval);
    setTimer(0);
    // Add other reset logic here
    setIsGameRunning(false);
    setScore(0);
    setStartTime(3); // Reset timer
    // setBallX(0);
    // setBallY(0);
    setCollison(false);
  };

  const startGame = () => {
    setIsGameRunning(true);
    setTimerInterval(
      setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000)
    );
  };

  return (
    <div className="App">
      <div className="row m-0 p-0">
        <div className="m-0 p-0 col">
          <h1>Ball Game</h1>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => {
              tare(0);
            }}
          >
            Calibrate
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        {/* Sustain Slider */}
        <div className="slider-container d-flex flex-column align-items-center ms-5 ">
          <div className="slider-label fs-5 p-4 ">Sustain</div>
          <input
            type="range"
            className="form-range custom-slider"
            min="100"
            max="2000"
            step="100"
            value={sustain}
            onChange={(e) => setSustain(Number(e.target.value))}
          />
          <div className="slider-value fs-5 p-3">{Math.floor(sustain)}</div>
        </div>

        <div className="canvas-container d-flex justify-content-center mx-3">
          <canvas
            ref={arcCanvasRef}
            width="500"
            height="500"
            onClick={handleCanvasClick}
            style={{
              backgroundColor: "rgb(226, 237 ,243)",
              borderRadius: "50%",
            }}
          />
        </div>

        {/* Precision Slider */}
        <div className="slider-container d-flex flex-column align-items-center me-5">
          <div className="slider-label fs-5 p-4">Precision</div>
          <input
            type="range"
            className="form-range custom-slider"
            min="2"
            max="6"
            step="1"
            value={precision}
            onChange={(e) => setPrecision(Number(e.target.value))}
          />
          <div className="slider-value fs-5 p-3">{precision}</div>
        </div>
      </div>

      <div
        className="second-container vh-100 d-flex align-items-center"
        style={{ backgroundColor: "rgb(226, 237 ,243)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-2 d-flex flex-column align-items-center justify-content-center">
              <div className="fs-5">Score: {score}</div>
              {startTime > 0 && <div className="fs-5">Game starts in: {startTime}</div>}
              {!isGameRunning && (
                <button type="button" className="btn btn-outline-success mt-2" onClick={startGame}>
                  Start Game
                </button>
              )}
              <button type="button" className="btn btn-outline-danger mt-2" onClick={resetGame}>
                Reset Game
              </button>
            </div>

            <div className="col-8 d-flex justify-content-center align-items-center">
              <canvas
                ref={ballCanvasRef}
                width={window.innerHeight * 0.95}
                height={window.innerHeight * 0.95}
                style={{
                  backgroundColor: "rgb(123, 180 ,210)",
                  borderRadius: "50%",
                }}
              />
            </div>

            <div className="col-2 d-flex flex-column align-items-center justify-content-center">
              <div className="fs-5">
                <h1>Time: {timer}s</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
