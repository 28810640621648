// export let bodypartstabs = {
//   ...{
//     "Upper Extremity": {
//       "Hand Flexion": "logo.png",
//       "Neck Flexion": "logo.png",
//       "Leg Flexion": "logo.png",
//       "Shoulder Flexion": "logo.png",
//       "Elbow Flexion": "logo.png",
//       "Wrist Flexion": "logo.png",
//       "Forearm Flexion": "logo.png",
//       "Arm Flexion": "logo.png",
//     },
//   },
//   ...{
//     "Lower Extremity": {
//       "Ankle Flexion": "logo.png",
//       "Knee Flexion": "logo.png",
//       "Spine Strech": "logo.png",
//     },
//   },
//   ...{
//     Spine: {
//       "Ankle Flexion": "logo.png",
//       Spine1: "logo.png",
//       Spine2: "logo.png",
//     },
//   },
//   ...{
//     "Full Body": {
//       "Ankle Flexion": "logo.png",
//       BalanceAssesment: "logo.png",
//       "Full Body2": "logo.png",
//     },
//   },
//   ...{
//     Cognitive: {
//       "Ankle Flexion": "logo.png",
//       Cognitive1: "logo.png",
//       Cognitive2: "logo.png",
//     },
//   },
// };

export let bodypartstabs = {
  ...{
    "Upper Extremity": {
      "Glenohumeral Flexion": "logo.png",
      "Glenohumeral Extension": "logo.png",
      "Glenohumeral Abduction": "logo.png",
      "Glenohumeral Complex Abduction": "logo.png",
      "Shoulder Complex Flexion": "logo.png",
      "Glenohumeral Internal Rotation": "logo.png",
      "Glenohumeral External Rotation": "logo.png",
      "Glenohumeral Complex Internal Rotation": "logo.png",
      "Glenohumeral Complex Lateral Rotation": "logo.png",
      "Elbow Flexion": "logo.png",
      "Forearm Pronation": "logo.png",
      "Forearm Supination": "logo.png",
      "Wrist Flexion": "logo.png",
      "Wrist Extension": "logo.png",
      "Wrist Radial Deviation": "logo.png",
      "Wrist Ulnar Deviation": "logo.png",
    },
  },
  ...{
    "Lower Extremity": {
      "Hip Flexion": "logo.png",
      "Hip Extension": "logo.png",
      "Hip Lateral Rotation": "logo.png",
      "Knee Flexion": "logo.png",
      "Hip Medial Rotation": "logo.png",
      "Hip Adduction": "logo.png",
      "Hip Abduction": "logo.png",
      "Ankle Dorsi flexion": "logo.png",
      "Plantar Flexion": "logo.png",
      "Metatarsophalangeal Abduction": "logo.png",
    },
  },
  ...{
    Spine: {
      "Thoracolumbar Flexion": "logo.png", // centre
      "Thoracolumbar Extension": "logo.png", // centre
      "Thoracolumbar Rotation": "logo.png", // left right
      "Lumbar Flexion": "logo.png", // centre
      "Thoracolumbar Lateral Flexion": "logo.png", //centre
      "Cervical Flexion": "logo.png", // centre
      "Cervical Extension": "logo.png", // centre
      "Cervical Lateral Flexion": "logo.png", // left right
      "Cervical Rotation": "logo.png", // left right
    },
  },
  ...{
    "Full Body": {
      "Balance Assessment": "logo.png",

      // "Ankle Flexion": "logo.png",
      // BalanceAssesment: "logo.png",
      // "Full Body2": "logo.png",
    },
  },
  ...{
    Cognitive: {
      "Ankle Flexion": "logo.png",
      Cognitive1: "logo.png",
      Cognitive2: "logo.png",
    },
  },
};
