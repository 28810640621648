import { createSlice } from "@reduxjs/toolkit";

const devicedataSlice = createSlice({
  name: "devicedata",
  initialState: { deviceinfo: [-1, -1, -1] },
  reducers: {
    addDeviceinfo: (state, action) => {
      state.deviceinfo[action.payload.index] = action.payload.value;
      state.deviceinfo = [...state.deviceinfo];
      // console.log(state.deviceinfo);
    },
    removeConnection: (state, action) => {
      state.deviceinfo = state.deviceinfo.filter(
        (conn) => conn !== action.payload
      ); // Remove WebSocket instance
    },
  },
});

export const devicedataActions = devicedataSlice.actions;
export default devicedataSlice;
