import React, { useEffect, useState, useRef } from "react";
import { tare } from "../../../Connect/BluetoothHandler";

export function ActivePassive({ content, setactiveRangeAngle, setpassiveRangeAngle }) {
  const [AutomaticManual, setAutomaticManual] = useState(true);
  const [angle, setAngle] = useState(0);
  const [isStarted, setisStarted] = useState(false);
  const [fixAngle, setFixAngle] = useState(angle);

  const intervalRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (isStarted && AutomaticManual) {
      setAngle(content.angle);
    }
  }, [content.angle, isStarted, AutomaticManual]);

  const startIncrement = () => {
    clearTimeout(timeoutRef.current);
    clearInterval(intervalRef.current);
    setFixAngle((prev) => (prev < 360 ? prev + 1 : 360));

    timeoutRef.current = setTimeout(() => {
      intervalRef.current = setInterval(() => {
        setFixAngle((prev) => (prev < 360 ? prev + 1 : 360));
      }, 200);
    }, 300);
  };

  const startDecrement = () => {
    clearTimeout(timeoutRef.current);
    clearInterval(intervalRef.current);
    setFixAngle((prev) => (prev > 0 ? prev - 1 : 0));

    timeoutRef.current = setTimeout(() => {
      intervalRef.current = setInterval(() => {
        setFixAngle((prev) => (prev > 0 ? prev - 1 : 0));
      }, 200);
    }, 300);
  };

  const stopIncrementDecrement = () => {
    clearTimeout(timeoutRef.current);
    clearInterval(intervalRef.current);
  };

  const handleMouseDownIncrement = () => {
    if (AutomaticManual) {
      tare(0);
      setisStarted(true);
    } else {
      startIncrement();
    }
  };

  const handleMouseDownDecrement = () => {
    if (AutomaticManual) {
      if (isStarted) {
        setisStarted(false);
        setFixAngle(angle);
      }
    } else {
      startDecrement();
    }
  };

  const handleMouseUp = () => {
    if (!AutomaticManual) {
      stopIncrementDecrement();
    }
  };

  const handleTouchStartIncrement = () => {
    if (AutomaticManual) {
      tare(0);
      setisStarted(true);
    } else {
      startIncrement();
    }
  };

  const handleTouchStartDecrement = () => {
    if (AutomaticManual) {
      if (isStarted) {
        setisStarted(false);
        setFixAngle(angle);
      }
    } else {
      startDecrement();
    }
  };

  const handleTouchEnd = () => {
    if (!AutomaticManual) {
      stopIncrementDecrement();
    }
  };

  useEffect(() => {
    if (content.range === "Active Range") {
      setactiveRangeAngle(fixAngle);
    } else if (content.range === "Passive Range") {
      setpassiveRangeAngle(fixAngle);
    }
  }, [setpassiveRangeAngle, setactiveRangeAngle, fixAngle, content.range]);

  return (
    <div className="container-fluid py-5 px-5">
      <div className="mt-2 px-5 bg-white rounded-2">
        <div className="row border-5 border-bottom-dark-red">
          <div className="col-8">
            <p className="text-red fw-semibold fs-4 pt-3">{content.range}</p>
          </div>

          <div
            className={`col-2 ${AutomaticManual ? "bg-red text-white" : "bg-white text-red"}`}
            onClick={() => {
              setAutomaticManual(true);
            }}
          >
            <p className="text-center fw-semibold fs-4 pt-3">{content.automatic}</p>
          </div>
          <div
            className={`col-2 ${!AutomaticManual ? "bg-red text-white" : "bg-white text-red"}`}
            onClick={() => {
              setAutomaticManual(false);
              setisStarted(false);
              setFixAngle(angle);
            }}
          >
            <p className="text-center fw-semibold fs-4 pt-3">{content.manual}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: "50vh" }}>
              <div className="text-red text-center fw-semibold" style={{ fontSize: "8rem" }}>
                {isStarted ? angle : fixAngle}
                <sup>°</sup>
              </div>
              <div className="text-red fw-semibold text-center" style={{ fontSize: "2.5rem" }}>
                ROM
              </div>
            </div>
          </div>
          <div className="col-4">
            <div
              className="d-flex justify-content-center align-items-center flex-column p-4 m-2"
              style={{ height: "50vh" }}
            >
              <div
                className="row btn bg-red w-100 d-flex justify-content-center align-items-center fw-bolder h-50 rounded-4"
                onMouseDown={handleMouseDownIncrement}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onTouchStart={handleTouchStartIncrement}
                onTouchEnd={handleTouchEnd}
                onTouchCancel={handleTouchEnd}
              >
                <h1 className="fw-bolder text-white text-center" style={{ fontSize: "3vw" }}>
                  {AutomaticManual ? "Start" : "+"}
                </h1>
              </div>
              <div
                className="row btn mt-4 bg-red w-100 d-flex justify-content-center align-items-center fw-bolder h-50 rounded-4"
                onMouseDown={handleMouseDownDecrement}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onTouchStart={handleTouchStartDecrement}
                onTouchEnd={handleTouchEnd}
                onTouchCancel={handleTouchEnd}
              >
                <h1 className="fw-bolder text-white text-center" style={{ fontSize: "3vw" }}>
                  {AutomaticManual ? "Stop" : "-"}
                </h1>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div
              className="d-flex justify-content-center align-items-center flex-column px-4 py-3"
              style={{ height: "50vh" }}
            >
              <div className="row bg-light-red w-100 d-flex justify-content-center align-items-center fw-bolder h-75 rounded-2"></div>
              <p className="pt-1 text-red" style={{ textAlign: "justify" }}>
                {content.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Manual({ content, setPainIndex, setStiffness }) {
  const [painIndexStiffness, setPainIndexStiffness] = useState(0);
  const intervalRef = useRef(null);
  const timeoutRef = useRef(null);

  const startIncrement = () => {
    clearTimeout(timeoutRef.current);
    clearInterval(intervalRef.current);
    setPainIndexStiffness((prev) => (prev < 100 ? prev + 5 : 100));

    timeoutRef.current = setTimeout(() => {
      intervalRef.current = setInterval(() => {
        setPainIndexStiffness((prev) => (prev < 100 ? prev + 5 : 100));
      }, 200);
    }, 300);
  };

  const startDecrement = () => {
    clearTimeout(timeoutRef.current);
    clearInterval(intervalRef.current);
    setPainIndexStiffness((prev) => (prev > 0 ? prev - 5 : 0));

    timeoutRef.current = setTimeout(() => {
      intervalRef.current = setInterval(() => {
        setPainIndexStiffness((prev) => (prev > 0 ? prev - 5 : 0));
      }, 200);
    }, 300);
  };

  const stopIncrementDecrement = () => {
    clearTimeout(timeoutRef.current);
    clearInterval(intervalRef.current);
  };

  const handleMouseDownIncrement = () => {
    startIncrement();
  };

  const handleMouseDownDecrement = () => {
    startDecrement();
  };

  const handleMouseUp = () => {
    stopIncrementDecrement();
  };

  const handleTouchStartIncrement = () => {
    startIncrement();
  };

  const handleTouchStartDecrement = () => {
    startDecrement();
  };

  const handleTouchEnd = () => {
    stopIncrementDecrement();
  };

  useEffect(() => {
    if (content.name === "PainIndex") {
      setPainIndex(painIndexStiffness);
    } else if (content.name === "Stiffness") {
      setStiffness(painIndexStiffness);
    }
  }, [painIndexStiffness, setPainIndex, setStiffness, content.name]);

  return (
    <div className="container-fluid py-5 px-5">
      <div className="mt-2 px-5 bg-white rounded-2">
        <div className="row border-5 border-bottom-dark-red">
          <div className="col-10">
            <p className="text-red fw-semibold fs-4 pt-3">{content.name}</p>
          </div>
          <div className="col-2 bg-red text-white">
            <p className="text-center fw-semibold fs-4 pt-3">{content.manual}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: "50vh" }}>
              <div className="text-red text-center fw-semibold" style={{ fontSize: "8rem" }}>
                {painIndexStiffness}%
              </div>
              <div className="text-red fw-semibold text-center" style={{ fontSize: "2.5rem" }}>
                {content.name}
              </div>
            </div>
          </div>
          <div className="col-4">
            <div
              className="d-flex justify-content-center align-items-center flex-column p-4 m-2"
              style={{ height: "50vh" }}
            >
              <div
                className="row btn bg-red w-100 d-flex justify-content-center align-items-center fw-bolder h-50 rounded-4"
                onMouseDown={handleMouseDownIncrement}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onTouchStart={handleTouchStartIncrement}
                onTouchEnd={handleTouchEnd}
                onTouchCancel={handleTouchEnd}
              >
                <h1 className="fw-bolder text-white text-center" style={{ fontSize: "3vw" }}>
                  +
                </h1>
              </div>
              <div
                className="row btn mt-4 bg-red w-100 d-flex justify-content-center align-items-center fw-bolder h-50 rounded-4"
                onMouseDown={handleMouseDownDecrement}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onTouchStart={handleTouchStartDecrement}
                onTouchEnd={handleTouchEnd}
                onTouchCancel={handleTouchEnd}
              >
                <h1 className="fw-bolder text-white text-center" style={{ fontSize: "3vw" }}>
                  -
                </h1>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div
              className="d-flex justify-content-center align-items-center flex-column px-4 py-3"
              style={{ height: "50vh" }}
            >
              <div className="row bg-light-red w-100 d-flex justify-content-center align-items-center fw-bolder h-75 rounded-2"></div>
              <p className="pt-1 text-red" style={{ textAlign: "justify" }}>
                {content.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
