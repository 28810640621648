import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Activity from "../Application/Activity/Activity";
import DoctorAddPatients from "../DoctorAddPatients/DoctorAddPatients";
import Progress from "../Application/Progress/Progress";
import { useSelector } from "react-redux";
import { mode1, mode2, mode3, mode4 } from "../Connect/BluetoothHandler";
import Assesment from "./Assesment/Assesment";
import Workout from "../Application/Workout/Workout";
import Counter from "../Application/Counter/Counter";
export default function Application() {
  const { currentView } = useSelector((store) => store.currentView); // Access the currentView from Redux store

  let [v, setv] = useState(<DoctorAddPatients />);

  useEffect(() => {
    if (["Assesment", "FlappyBird"].includes(currentView)) {
      mode1();
    } else if (currentView === "BalanceAssesment") {
      mode2();
    } else if (currentView === "Counter") {
      mode3();
    } else if (currentView.includes("Workout")) {
      mode4();
    }
  });

  useEffect(() => {
    if (currentView === "Activity") {
      setv(<Activity />);
    } else if (
      ["SelectionTabsAssesment", "Assesment", "BalanceAssesment"].includes(
        currentView
      )
    ) {
      setv(<Assesment />);
    } else if (currentView === "Progress") {
      setv(<Progress />);
    } else if (currentView === "DoctorAddPatients") {
      setv(<DoctorAddPatients />);
    } else if (
      [
        "SelectionTabsWorkout",
        "Workout",
        "Game",
        "BolbGame",
        "FlappyBird",
        "NewBolb",
        "Sphere",
      ].includes(currentView)
    ) {
      setv(<Workout />);
    } else if (["SelectionTabsCounter", "Counter"].includes(currentView)) {
      setv(<Counter />);
    }
  }, [currentView]);

  return (
    <div className="bg-light-red body">
      {/* Navbar Component*/}
      <Navbar activeNavtab={"App"} />
      {v}
    </div>
  );
}
