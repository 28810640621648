import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppProgressBar from "../AppProgressBar";
import SelectionTabsAssessment from "./SelectionTabsAssessment";
import { currentViewActions } from "../../../store/currentView";

import NewBolb from "../Workout/Game/NewBolb/NewBolb";
import BalanceAssessment from "./BalanceAssessment/BalanceAssessment";
import NormalAssessment from "./NoramalAssessment/NormalAssessment";

export default function Assessment() {
  const dispatch = useDispatch();
  const { currentView } = useSelector((store) => store.currentView);

  const { sequence } = useSelector((store) => store.sequenceClick);

  let [v, setv] = useState();

  useEffect(() => {
    const setCurrentView = (view) => {
      dispatch(currentViewActions.addView({ value: view }));
    };
    if (currentView === "SelectionTabsAssessment") {
      setv(<SelectionTabsAssessment setCurrentView={setCurrentView} />);
    } else if (currentView === "Balance Assessment") {
      setv(<BalanceAssessment />);
    } else if (currentView === "NewBolb") {
      setv(<NewBolb />);
    } else if (currentView === "Assessment") {
      setv(<NormalAssessment />);
    }
  }, [currentView, sequence, dispatch]);

  return (
    <>
      <AppProgressBar />

      {v}
    </>
  );
}
