import { createSlice } from "@reduxjs/toolkit";

const curretViewSlice = createSlice({
  name: "currentView",
  initialState: { currentView: "DoctorAddPatients" }, // Corrected initial state to match the initial view name
  reducers: {
    addView: (state, action) => {
      state.currentView = action.payload.value;
      console.log(state.currentView);
    },
  },
});

export const currentViewActions = curretViewSlice.actions;
export default curretViewSlice;
