import React, { useEffect, useRef, useState } from "react";
import "./FlappyBird.css";
import PlayerData from "../../../../../Components/PlayerData";
import Slider from "./Slider";
import Matter from "matter-js";
import { useSelector } from "react-redux";
import { tare } from "../../../../Connect/BluetoothHandler";
import FlappyBirdImage from "../../../../../Images/yellow falppy.png";
import TopTreePipe from "../../../../../Images/treeTop.png";
import BottomTreePipe from "../../../../../Images/treebottom.png";
function FlappyBirdMatter({ playerData }) {
  let { deviceinfo } = useSelector((store) => store.devicedata);
  let [windowDimensions, setwindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const isGameOverRef = useRef(true);
  let [birdImageDimensions, setBirdImageDimensions] = useState({ height: 0 });
  const valueRef = useRef({
    speed: 0.5,
    sustain: 1,
    randomness: 1,
    precision: 2,
    repeatation: 4,
    select: null,
  });

  let topTreeHeightRef = useRef();
  // Function to handle slider changes
  const handleSliderChange = (sliderId, newValue) => {
    // Update the ref object without causing re-render
    valueRef.current = {
      ...valueRef.current,
      [sliderId]: newValue,
      select: sliderId,
    };
    isGameOverRef.current = false;
  };

  let [ROM, setRom] = useState({ romValueOf: " ", value: 100, boolean: false });
  const canvasRef = useRef(null);
  const ballBirdRef = useRef();
  const pipesRef = useRef([]);
  // const storePies = useRef([]);
  const engineRef = useRef();

  useEffect(() => {
    const canvas = canvasRef.current;
    const engine = Matter.Engine.create();
    engineRef.current = engine;
    const world = engine.world;
    const birdBallSize = canvas.height * 0.13;

    engine.gravity.y = 0;
    // Create a renderer
    const render = Matter.Render.create({
      canvas,
      engine,
      options: {
        width: canvas.width,
        height: canvas.height,
        wireframes: false, // Set to true to debug collisions
      },
    });

    pipesRef.current.forEach((pipe, index) => {
      const { topTreePipe, bottomTreePipe } = pipe;
      // Remove pipes when they are off the screen

      Matter.World.remove(world, topTreePipe);
      Matter.World.remove(world, bottomTreePipe);
      pipesRef.current.splice(index, 1); // Remove the pipe pair from the array
    });

    // Load the bird image
    const birdImage = new Image();
    birdImage.src = FlappyBirdImage;

    birdImage.onload = function () {
      setBirdImageDimensions({
        height: birdImage.height,
      });
    };

    const birdBall = Matter.Bodies.circle(
      canvas.width * 0.13,
      canvas.height * 0.5,
      // birdBallSize - canvas.height * 0.01,
      // birdBallSize - canvas.height * 0.01,
      birdBallSize / 2,
      // birdBallSize,
      {
        label: "bird",
        render: {
          // fillStyle: "blue",
          sprite: {
            texture: birdImage.src,
            xScale: (canvas.height * 0.15) / birdImageDimensions.height,
            yScale: (canvas.height * 0.15) / birdImageDimensions.height,
          },
        },
      }
    );

    ballBirdRef.current = birdBall;

    Matter.World.add(world, birdBall);

    // Define pipe images
    const topPipe = new Image();
    topPipe.src = TopTreePipe;
    const bottomPipe = new Image();
    bottomPipe.src = BottomTreePipe;

    // Define the array and repetition variables
    const gap = birdBallSize + canvas.height * 0.8 * (valueRef.current.precision / ROM.value);

    let sustain = Math.round(valueRef.current.sustain / valueRef.current.speed + 1);
    console.log("sustain", sustain);
    let heightFormula = [canvas.height * 0.8 + canvas.height * 0.1 - gap / 2, canvas.height * 0.1 - gap / 2];
    let sustainToppipes = new Array(sustain).fill(heightFormula[0]);
    let sustainBottomPipes = new Array(sustain).fill(heightFormula[1]);
    let totalrandomPipes = valueRef.current.randomness * sustain * 0.25;

    let randompipessetfirst = Array.from(
      { length: totalrandomPipes },
      () => Math.random() * (canvas.height * 0.8) + canvas.height * 0.1 - gap / 2
    );
    let randompipessetsecond = Array.from(
      { length: totalrandomPipes },
      () => Math.random() * (canvas.height * 0.8) + canvas.height * 0.1 - gap / 2
    );
    let topPipeHeights = [...sustainToppipes, ...randompipessetfirst, ...sustainBottomPipes, ...randompipessetsecond];

    console.log(topPipeHeights);
    console.log(topPipeHeights.length);
    let repeat = 0;
    let heightIndex = 0;
    // Create pipe bodies and add them to the world

    function pipeGeneration() {
      if (isGameOverRef.current || repeat >= valueRef.current.repeatation) return;

      let topTreePipeHeight = topPipeHeights[heightIndex];
      topTreeHeightRef.current = topTreePipeHeight;

      const topTreePipe = Matter.Bodies.rectangle(
        // canvas.width,
        ((1 -
          (((canvas.width - (canvas.width * 0.13 - canvas.height * 0.065 - canvas.width * 0.025)) /
            ((canvas.width * 0.25) / (valueRef.current.speed * 60))) %
            1)) *
          (canvas.width * 0.25)) /
          (valueRef.current.speed * 60) +
          canvas.width,
        topTreePipeHeight / 2,
        canvas.width * 0.05,
        topTreePipeHeight,
        {
          label: "Pipe",
          isSensor: true,

          render: {
            // fillStyle: "green",
            sprite: {
              texture: topPipe.src,
              xScale: (canvas.width * 0.05) / 585,
              yScale: topTreePipeHeight / 2475,
            },
          },
        }
      );
      const bottomTreePipe = Matter.Bodies.rectangle(
        ((1 -
          (((canvas.width - (canvas.width * 0.13 - canvas.height * 0.065 - canvas.width * 0.025)) /
            ((canvas.width * 0.25) / (valueRef.current.speed * 60))) %
            1)) *
          (canvas.width * 0.25)) /
          (valueRef.current.speed * 60) +
          canvas.width,
        canvas.height - (canvas.height - (topTreePipeHeight + gap)) / 2,
        canvas.width * 0.05,
        canvas.height - topTreePipeHeight - gap,
        {
          isSensor: true,
          label: "Pipe",
          render: {
            fillStyle: "green",
            sprite: {
              texture: bottomPipe.src,
              xScale: (canvas.width * 0.05) / 585,
              yScale: (canvas.height - topTreePipeHeight - gap) / 2475,
            },
          },
        }
      );

      Matter.World.add(world, [topTreePipe, bottomTreePipe]);
      pipesRef.current.push({ topTreePipe, bottomTreePipe });

      // Increment index and handle repetition
      heightIndex++;
      if (heightIndex >= topPipeHeights.length) {
        heightIndex = 0;
        repeat++;
      }
    }

    pipeGeneration();

    function movePipe() {
      // if (isGameOverRef.current) return;

      if (isGameOverRef.current === false) {
        const pipeSpeed = -(canvas.width * 0.25) / (valueRef.current.speed * 60);
        pipesRef.current.forEach((pipe, index) => {
          const { topTreePipe, bottomTreePipe } = pipe;

          // Move pipes to the left
          Matter.Body.translate(topTreePipe, { x: pipeSpeed, y: 0 });
          Matter.Body.translate(bottomTreePipe, { x: pipeSpeed, y: 0 });

          // Calculate positions for the top pipe and the bird
          const pipeMidpointX = topTreePipe.position.x;
          const birdX = ballBirdRef.current.position.x;

          // Check if the bird has passed through the pipe
          if (pipeMidpointX < birdX) {
            isGameOverRef.current = false;
          }

          // Generate new pipes when the last generated pipe is past the 1/4 of the canvas
          if (topTreePipe.position.x < canvas.width * 0.75 && index === pipesRef.current.length - 1) {
            pipeGeneration();
          }

          // Remove pipes when they are off the screen
          if (topTreePipe.position.x < -topTreePipe.bounds.max.x) {
            Matter.World.remove(world, topTreePipe);
            Matter.World.remove(world, bottomTreePipe);
            pipesRef.current.splice(index, 1); // Remove the pipe pair from the array
          }
        });
      }
    }

    Matter.Events.on(engine, "collisionStart", (event) => {
      const pairs = event.pairs;

      pairs.forEach((pair) => {
        const { bodyA, bodyB } = pair;

        if (bodyA.label === "bird" && bodyB.label === "Pipe") {
          // Mark the game as over but don't stop pipe generation permanently
          isGameOverRef.current = true;
        }
      });
    });

    // Reset game on collision end
    Matter.Events.on(engine, "collisionEnd", (event) => {
      const pairs = event.pairs;
      pairs.forEach((pair) => {
        if (pair.bodyA === ballBirdRef.current || pair.bodyB === ballBirdRef.current) {
          // Reset game state to allow pipes to be generated again
          isGameOverRef.current = false; // Resume the game
          // Optionally, reset the bird's position
        }
      });
    });

    Matter.Events.on(engine, "beforeUpdate", movePipe);

    const runner = Matter.Runner.create();
    Matter.Runner.run(runner, engine);

    Matter.Render.run(render);

    // Cleanup function
    return () => {
      Matter.Render.stop(render);
      Matter.Runner.stop(runner);
      Matter.Engine.clear(engine);
    };
  }, [
    ROM,
    birdImageDimensions.height,
    valueRef.current.precision,
    valueRef.current.randomness,
    valueRef.current.repeatation,
    valueRef.current.speed,
    valueRef.current.sustain,
  ]);
  //
  useEffect(() => {
    const canvas = canvasRef.current;

    const handleBirdPosition = (value, topTreePipe, bottomTreePipe, bird, X) => {
      let returnValue = (value) => {
        if (value < 0) {
          return 0;
        } else if (value > ROM.value) {
          return ROM.value;
        } else {
          return value;
        }
      };

      const ballBirdPosition =
        ((ROM.value - returnValue(value)) / ROM.value) * canvas.height * 0.8 + 0.1 * canvas.height;

      let assign = (ballBirdPosition) => {
        if (
          topTreePipe.position.x < canvas.width * 0.155 + canvas.height * 0.065 - canvas.width * 0.006 &&
          topTreePipe.position.x > canvas.width * 0.105 - canvas.height * 0.065
        ) {
          if (ballBirdPosition + canvas.height * 0.065 > bottomTreePipe.bounds.min.y) {
            let newballBirdPosition = bottomTreePipe.bounds.min.y - canvas.height * 0.065 + 2;
            console.log("jerry");
            return newballBirdPosition;
          } else if (ballBirdPosition - canvas.height * 0.065 < topTreePipe.bounds.max.y) {
            let newballBirdPosition = topTreePipe.bounds.max.y + canvas.height * 0.065 - 2;
            return newballBirdPosition;
          } else {
            console.log("Spike");
            return ballBirdPosition;
          }
        } else {
          return ballBirdPosition;
        }
      };

      let bush = assign(ballBirdPosition);
      Matter.Body.setPosition(ballBirdRef.current, {
        x: X,
        y: bush,
      }); // Move up
    };

    // Get only the first pipe
    if (pipesRef.current.length > 0) {
      const { topTreePipe, bottomTreePipe } = pipesRef.current[0];
      const bird = ballBirdRef.current;
      const { x } = bird.position;

      if (deviceinfo[0].mode === 1 && ROM.romValueOf) {
        handleBirdPosition(
          ROM.boolean === true ? deviceinfo[0][ROM.romValueOf] * -1 : deviceinfo[0][ROM.romValueOf],
          topTreePipe,
          bottomTreePipe,
          bird,
          x
        );
      }
    }
  }, [deviceinfo, ROM]);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;

      setwindowDimensions({
        width: newWidth,
        height: newHeight,
      });

      const canvas = canvasRef.current;
      canvas.width = newWidth;
      canvas.height = newHeight;

      const newBirdBallSize = newHeight * 0.13; // Update bird size based on new height

      // Update bird scale and position
      const bird = ballBirdRef.current;
      Matter.Body.scale(bird, newBirdBallSize / bird.bounds.max.x, newBirdBallSize / bird.bounds.max.y);
      Matter.Body.setPosition(bird, {
        x: newWidth * 0.13,
        y: (bird.position.y / windowDimensions.height) * newHeight, // Adjust Y position based on new height
      });

      // Calculate the gap (reuse from where it's initially defined)
      const birdBallSize = newHeight * 0.13;
      const gap = birdBallSize + newHeight * 0.8 * (valueRef.current.precision / ROM.value); // Recalculate gap

      // Update pipe sizes and positions
      pipesRef.current.forEach(({ topTreePipe, bottomTreePipe }) => {
        const topPipeScaleX = (newWidth * 0.05) / (topTreePipe.bounds.max.x - topTreePipe.bounds.min.x);
        const topPipeScaleY = topTreePipe.position.y / topTreePipe.bounds.max.y;

        const bottomPipeScaleX = (newWidth * 0.05) / (bottomTreePipe.bounds.max.x - bottomTreePipe.bounds.min.x);
        const bottomPipeScaleY = (newHeight - topTreePipe.position.y - gap) / bottomTreePipe.bounds.max.y;

        // Scale the pipes
        Matter.Body.scale(topTreePipe, topPipeScaleX, topPipeScaleY);
        Matter.Body.scale(bottomTreePipe, bottomPipeScaleX, bottomPipeScaleY);

        // Adjust pipe positions based on new width
        Matter.Body.setPosition(topTreePipe, {
          x: (topTreePipe.position.x / windowDimensions.width) * newWidth,
          y: topTreePipe.position.y, // Keep Y position the same
        });
        Matter.Body.setPosition(bottomTreePipe, {
          x: (bottomTreePipe.position.x / windowDimensions.width) * newWidth,
          y: bottomTreePipe.position.y, // Keep Y position the same
        });
      });

      isGameOverRef.current = false; // Ensure the game doesn't reset, but adjusts object positions
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions, pipesRef.current.length, ROM.value]);

  return (
    <>
      <PlayerData playerData={playerData} />

      {/* Roll, Pitch, Yaw selection div */}
      <div className="container-fluid my-5 px-5 row gap-5" style={{ height: "70vh" }}>
        <div className="col-4 p-5 h-100 bg-white rounded-4 ">
          <div className="row d-flex justify-content-center align-items-center fw-bolder h-25 rounded-4">
            <h1 className="fw-bolder text-red text-center" style={{ fontSize: "3vw" }}>
              Start Position
            </h1>
          </div>
          <div
            className="row bg-red d-flex justify-content-center align-items-center fw-bolder h-75 my-4 rounded-4 clickable"
            onClick={() => {
              tare(0);
              isGameOverRef.current = true;
              setRom({ romValueOf: " ", value: 100, boolean: false });
            }}
          >
            <h1 className="fw-bolder text-white text-center" style={{ fontSize: "3vw" }}>
              Start Position
            </h1>
          </div>
        </div>
        <div className="col p-5 h-100 bg-white rounded-4">
          <div className="row d-flex justify-content-center align-items-center fw-bolder h-25 rounded-4 clickable">
            <h1 className="fw-bolder text-red text-center" style={{ fontSize: "3vw" }}>
              End Position
            </h1>
          </div>
          <div className="row d-flex justify-content-center align-items-center fw-bolder h-75 my-4 rounded-4 gx-5">
            <div className="col-6 h-100 pb-4">
              <div
                className="row bg-red d-flex justify-content-center align-items-center fw-bolder h-50 rounded-4 clickable"
                onClick={() => {
                  setRom({
                    romValueOf: "roll",
                    value: Math.abs(deviceinfo[0].roll),
                    boolean: deviceinfo[0]?.roll < 0 ? true : false,
                  });
                  isGameOverRef.current = false;
                }}
              >
                <h1 className="fw-bolder text-white text-center" style={{ fontSize: "3vw" }}>
                  X :{deviceinfo[0].roll ? deviceinfo[0].roll : 0}
                  <sup>°</sup>
                </h1>
              </div>
              <div
                className="row bg-red d-flex justify-content-center align-items-center fw-bolder h-50 my-4 rounded-4 clickable"
                onClick={() => {
                  setRom({
                    romValueOf: "pitch",
                    value: Math.abs(deviceinfo[0].pitch),
                    boolean: deviceinfo[0].pitch < 0 ? true : false,
                  });
                  isGameOverRef.current = false;
                }}
              >
                <h1 className="fw-bolder text-white text-center" style={{ fontSize: "3vw" }}>
                  Y :{deviceinfo[0].pitch ? deviceinfo[0]?.pitch : 0}
                  <sup>°</sup>
                </h1>
              </div>
            </div>
            <div className="col-6 h-100 pb-4">
              <div
                className="row bg-red d-flex justify-content-center align-items-center fw-bolder h-50 rounded-4 clickable"
                onClick={() => {
                  setRom({
                    romValueOf: "yaw",
                    value: Math.abs(deviceinfo[0].yaw),
                    boolean: deviceinfo[0].yaw < 0 ? true : false,
                  });
                  isGameOverRef.current = false;
                }}
              >
                <h1 className="fw-bolder text-white text-center" style={{ fontSize: "3vw" }}>
                  Z :{deviceinfo[0].yaw ? deviceinfo[0].yaw : 0} <sup>°</sup>
                </h1>
              </div>
              <div className="row bg-red d-flex justify-content-center align-items-center fw-bolder h-50 my-4 rounded-4">
                <h1 className="fw-bolder text-white text-center" style={{ fontSize: "3vw" }}>
                  {ROM.romValueOf + ":" + ROM.value}
                  <sup>°</sup>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Roll, Pitch, Yaw selection div */}

      <Slider onSliderChange={handleSliderChange} />

      <div className="game-area overflow-hidden">
        <canvas className="canvas-bg" ref={canvasRef} width={windowDimensions.width} height={windowDimensions.height} />
      </div>
    </>
  );
}

export default FlappyBirdMatter;
