import { createSlice } from "@reduxjs/toolkit";

const bleDevicesSlice = createSlice({
  name: "bleDevices",
  initialState: { bleDevice: [-1, -1, -1] },
  reducers: {
    addbleDevices: (state, action) => {
      state.bleDevice[action.payload.index] = action.payload.value;
      state.bleDevice = [...state.bleDevice];
      // console.log(state.bleDevice);
    },
    removebleDevices: (state, action) => {
      state.bleDevice[action.payload.index] = -1;
      state.bleDevice = [...state.bleDevice];
      // console.log(state.bleDevice);
    },
  },
});

export const bleDevicesActions = bleDevicesSlice.actions;
export default bleDevicesSlice;
