import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { useSelector } from "react-redux";
import calculateCoordinates from "./BalanceAssesmentScore";
import { tare } from "../../../Connect/BluetoothHandler";

export default function BalanceAssessment() {
  let [level, setLevel] = useState("Easy");
  let [widthHeight, setWidthHeight] = useState({
    width: "55vh",
    height: "55vh",
  });
  let { currentView } = useSelector((store) => store.currentView); // Access the currentView from Redux store

  let { deviceinfo } = useSelector((store) => store.devicedata);

  let [scorePlot, setScorePlot] = useState({
    visible: false,
    ap: [],
    mt: [],
  });

  let [plotValue, setPlotValue] = useState({
    ap: [],
    mt: [],
  });

  let [button, setButton] = useState({
    start: true,
    stop: false,
    saveAndNotSave: false,
  });

  let [countDown, setCountDown] = useState({
    boolean: false,
    count: 15,
    minute: 0,
    second: 15,
  });

  let [centreDegree, setCenterDegree] = useState(15);
  let [balanceAssesmentScore, setBalancdAssesmentScore] = useState({
    centre: 0,
    anterior: 0,
    posterior: 0,
    left: 0,
    right: 0,
  });

  useEffect(() => {
    let intervalId;

    if (countDown.boolean && (countDown.minute > 0 || countDown.second > 0)) {
      intervalId = setInterval(() => {
        setCountDown((prev) => {
          if (prev.second > 0) {
            return { ...prev, second: prev.second - 1 };
          } else if (prev.minute > 0) {
            return { ...prev, minute: prev.minute - 1, second: 59 };
          } else {
            clearInterval(intervalId);
            // setButton({ start: true, stop: false, saveAndNotSave: true });
            return { ...prev, boolean: false, second: 0 };
          }
        });
      }, 1000);
    } else if (
      countDown.boolean &&
      countDown.minute === 0 &&
      countDown.second === 0
    ) {
      // Ensure the interval is cleared when the countdown reaches zero
      clearInterval(intervalId);
      setButton({ start: false, stop: false, saveAndNotSave: true });

      setScorePlot((prev) => ({
        ...prev,
        visible: true,
      }));

      handleClick(countDown.count);
    }

    return () => clearInterval(intervalId);
  }, [countDown]);

  useEffect(() => {
    if (currentView !== "BalanceAssessment") {
      setCountDown({ boolean: false, count: 15, minute: 0, second: 15 });
      setButton({ start: true, stop: false, saveAndNotSave: false });
    }
  }, [currentView]);

  useEffect(() => {
    if (deviceinfo[0]?.mode === 2) {
      let ap = -1 * deviceinfo[0].ap;
      let mt = deviceinfo[0].mt;

      // Calculate distance from center
      let distance = Math.sqrt(ap * ap + mt * mt);

      // If distance is greater than 20, adjust the values
      if (distance > 20) {
        let factor = 20 / distance;
        ap *= factor;
        mt *= factor;
      }

      setPlotValue({
        ap: [ap],
        mt: [mt],
      });

      if (scorePlot.visible === false && button.start === false) {
        console.log("update");
        setScorePlot((prev) => ({
          ...prev,
          ap: [...prev.ap, ap],
          mt: [...prev.mt, mt],
        }));
      }
    }
  }, [deviceinfo, scorePlot.visible, button.start]);

  useEffect(() => {
    if (button.saveAndNotSave) {
      const result = calculateCoordinates(
        scorePlot.ap,
        scorePlot.mt,
        centreDegree
      );
      setBalancdAssesmentScore(result);
    }
  }, [button.saveAndNotSave, scorePlot.ap, scorePlot.mt, centreDegree]);
  const trace1 = {
    x: plotValue.mt,
    y: plotValue.ap,
    mode: "markers",
    type: "scatter",
    marker: {
      color: "yellow",
      size: 50,
    },
  };

  const handleClick = (time) => {
    setCountDown({
      boolean: false,
      count: time,
      minute: Math.floor(time / 60),
      second: time % 60,
    });
  };

  return (
    <div className="  mt-5">
      <style>
        {`
          .disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        `}
      </style>
      <div className="mx-5">
        <div className="bg-white text-red mt-5 mb-3 py-2 text-center fs-3 fw-bold">
          Levels
        </div>
        <div
          className={`row m-0 p-0 text-center fs-3 bg-white ${
            !button.start ? "disabled" : ""
          }`}
        >
          <div
            className={`col m-0 py-2 ${
              level === "Easy" ? "text-white bg-red" : "text-red bg-white"
            }`}
            onClick={() => {
              if (button.start) {
                setLevel("Easy");
                setWidthHeight({
                  width: "55vh",
                  height: "55vh",
                });
                setCenterDegree(15);
              }
            }}
          >
            Easy
          </div>
          <div
            className={`col m-0 py-2 ${
              level === "Medium" ? "text-white bg-red" : "text-red bg-white"
            }`}
            onClick={() => {
              if (button.start) {
                setLevel("Medium");
                setWidthHeight({
                  width: "37vh",
                  height: "37vh",
                });
                setCenterDegree(15);
              }
            }}
          >
            Medium
          </div>
          <div
            className={`col m-0 py-2 ${
              level === "Hard" ? "text-white bg-red" : "text-red bg-white"
            }`}
            onClick={() => {
              if (button.start) {
                setLevel("Hard");
                setWidthHeight({
                  width: "18vh",
                  height: "18vh",
                });
                setCenterDegree(5);
              }
            }}
          >
            Hard
          </div>
        </div>
        <div
          className={`row mx-0 my-3 p-0 text-center fs-3 bg-white ${
            !button.start ? "disabled" : ""
          }`}
        >
          <div
            className={`col m-0 py-2 ${
              countDown.count === 15 ? "text-white bg-red" : "text-red bg-white"
            }`}
            onClick={() => {
              if (button.start) handleClick(15);
            }}
          >
            15
          </div>
          <div
            className={`col m-0 py-2 ${
              countDown.count === 30 ? "text-white bg-red" : "text-red bg-white"
            }`}
            onClick={() => {
              if (button.start) handleClick(30);
            }}
          >
            30
          </div>
          <div
            className={`col m-0 py-2 ${
              countDown.count === 45 ? "text-white bg-red" : "text-red bg-white"
            }`}
            onClick={() => {
              if (button.start) handleClick(45);
            }}
          >
            45
          </div>
          <div
            className={`col m-0 py-2 ${
              countDown.count === 120
                ? "text-white bg-red"
                : "text-red bg-white"
            }`}
            onClick={() => {
              if (button.start) handleClick(120);
            }}
          >
            2 Minutes
          </div>
        </div>
        <div className="row mx-0 my-3 p-0  bg-white">
          <div className="col-9 m-0 py-2   text-red bg-white">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum
            vitae dolores veniam eveniet sequi, suscipit asperiores, voluptas
            quaerat minima natus recusandae dignissimos facilis assumenda modi
            voluptate fugit quos similique rerum?
          </div>
          <div className="col-3 m-0 py-1   text-red bg-white">
            {" "}
            <button
              type="button"
              className="btn bg-red rounded-pill w-75 fs-3 text-white  shadow"
              onClick={() => tare(0)}
            >
              Calibrate
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="row  mt-1">
          <div className="col m-0 p-0 d-flex  justify-content-center align-items-center">
            {button.start ? (
              <button
                type="button"
                className="btn bg-red rounded-pill w-75 fs-3 text-white my-3 shadow"
                onClick={() => {
                  setCountDown((prev) => ({
                    ...prev,
                    boolean: true,
                  }));

                  setButton({
                    start: false,
                    stop: true,
                    saveAndNotSave: false,
                  });

                  setScorePlot((prev) => ({
                    ...prev,
                    visible: false,
                    ap: [],
                    mt: [],
                  }));
                }}
              >
                Start
              </button>
            ) : button.stop ? (
              <button
                type="button"
                className="btn bg-red rounded-pill w-75 fs-3 text-white my-3 shadow"
                onClick={() => {
                  console.log(scorePlot.ap, scorePlot.mt);
                  setButton({
                    start: true,
                    stop: false,
                    saveAndNotSave: true,
                  });

                  handleClick(countDown.count);

                  setScorePlot((prev) => ({
                    ...prev,
                    visible: true,
                  }));
                }}
              >
                Stop
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="col m-0 p-0 d-flex  justify-content-center align-items-center">
            {button.saveAndNotSave && (
              <button
                type="button"
                className="btn bg-red rounded-pill w-75 fs-3 text-white my-3 shadow"
                onClick={() => {
                  setButton({ ...button, start: true, saveAndNotSave: false });
                  setScorePlot({
                    visible: false,
                    ap: [],
                    mt: [],
                  });
                }}
              >
                Don't Save
              </button>
            )}
          </div>
          <div className="col m-0 p-0 d-flex  justify-content-center align-items-center">
            {button.saveAndNotSave && (
              <button
                type="button"
                className="btn bg-red rounded-pill w-75 fs-3 text-white my-3 shadow"
              >
                Save
              </button>
            )}
          </div>
        </div>
        <div className="row  py-4 ">
          <div className="col m-0 p-0 d-flex justify-content-center align-items-center text-red">
            <div className=" display-4">
              <div className="row py-3">
                <div className="col fw-medium text-start ">
                  <div className=" fw-medium">Time</div>
                  <div className=" fw-medium">Level </div>
                  <div className=" fw-medium">Center</div>
                </div>
                <div className="col text-start">
                  <div className="">
                    {":"}
                    {countDown.minute < 10
                      ? `0${countDown.minute}`
                      : countDown.minute}
                    :
                    {countDown.second < 10
                      ? `0${countDown.second}`
                      : countDown.second}
                  </div>
                  <div className="">
                    {":"}
                    {level}
                  </div>
                  <div className="">{`: ${balanceAssesmentScore.centre}%`}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="col m-0  px-0 d-flex justify-content-center align-items-center position-relative">
            <div
              className="rounded-circle overflow-hidden "
              style={{ width: "80vh", height: "80vh" }}
            >
              <style>
                {`
          .plot-lines {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }
          .plot-line {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;
            z-index: 10;
          }
          .plot-line:before {
            content: "";
            position: absolute;
            width: 2px;
            background-color: red; /* Example line color */
          }
          .plot-line.y-equal-x:before {
            transform: rotate(45deg);
            height: 100%;
            left: 50%;
          }
          .plot-line.y-equal-minus-x:before {
            transform: rotate(-45deg);
            height: 100%;
            left: 50%;
          }
        `}
              </style>
              {scorePlot.visible ? (
                <>
                  <Plot
                    data={[
                      {
                        x: scorePlot.mt,
                        y: scorePlot.ap,
                        mode: "markers",
                        type: "scatter",
                        marker: {
                          color: "yellow",
                          // size: 50,
                        },
                        line: { shape: "spline", smoothing: 3 },
                      },
                    ]}
                    layout={{
                      xaxis: {
                        visible: true,
                        showticklabels: false,
                        zeroline: false,
                        showgrid: true,
                        title: " ",
                        autorange: false,
                        range: [-22, 22],
                        fixedrange: true,
                      },
                      yaxis: {
                        visible: true,
                        showticklabels: false,
                        zeroline: false,
                        showgrid: true,
                        title: " ",
                        autorange: false,
                        range: [-22, 22],
                        fixedrange: true,
                      },
                      aspectratio: { x: 1, y: 1 },
                      plot_bgcolor: "#33B3A6",
                      autosize: true,
                      margin: { t: 0, b: 0, l: 0, r: 0 },
                    }}
                    config={{ displayModeBar: false, useResizeHandler: true }}
                    style={{ width: "100%", height: "100%" }}
                  />

                  <div className="plot-lines">
                    <div
                      className="plot-line y-equal-x"
                      style={{ top: "0%", left: "0%" }}
                    />
                    <div
                      className="plot-line y-equal-minus-x"
                      style={{ top: "0%", left: "0%" }}
                    />
                  </div>
                  <div
                    className="position-absolute border border-2 border-danger top-50 start-50 translate-middle rounded-circle overflow-hidden"
                    style={{
                      width: widthHeight.width,
                      height: widthHeight.height,
                    }}
                  ></div>
                </>
              ) : (
                <>
                  <Plot
                    data={[trace1]}
                    layout={{
                      xaxis: {
                        visible: false,
                        showticklabels: false,
                        showgrid: false,
                        title: "X Axis",
                        autorange: false,
                        range: [-22, 22],
                        fixedrange: true,
                      },
                      yaxis: {
                        visible: false,
                        showticklabels: false,
                        showgrid: false,
                        title: "Y Axis",
                        autorange: false,
                        range: [-22, 22],
                        fixedrange: true,
                      },
                      aspectratio: { x: 1, y: 1 },
                      plot_bgcolor: "#33B3A6",
                      autosize: true,
                      margin: { t: 0, b: 0, l: 0, r: 0 },
                    }}
                    config={{ displayModeBar: false, useResizeHandler: true }}
                    style={{ width: "100%", height: "100%" }}
                  />

                  <div
                    className="position-absolute border border-2 border-danger top-50 start-50 translate-middle rounded-circle overflow-hidden"
                    style={{
                      width: widthHeight.width,
                      height: widthHeight.height,
                    }}
                  ></div>
                </>
              )}
            </div>
          </div>

          <div className="col m-0 p-0 d-flex justify-content-center align-items-center text-red">
            <div className=" display-3">
              <div className="row py-3">
                <div className="col fw-medium text-end ">
                  <div className=" fw-medium">Anterior </div>
                  <div className=" fw-medium">Posterior </div>
                  <div className=" fw-medium">Left </div>
                  <div className=" fw-medium">Right</div>
                </div>
                <div className="col text-start">
                  <div className="">{`: ${balanceAssesmentScore.anterior}%`}</div>
                  <div className="">{`: ${balanceAssesmentScore.posterior}%`}</div>
                  <div className="">{`: ${balanceAssesmentScore.left}%`}</div>
                  <div className="">{`: ${balanceAssesmentScore.right}%`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
