import React, { useEffect, useState } from "react";
import AppProgressBar from "../AppProgressBar";
import SelectionTabsWorkout from "./SelectionTabsWorkout";
import { useDispatch, useSelector } from "react-redux";
import { currentViewActions } from "../../../store/currentView";
import BolbGame from "./Game/Bolbgame/BolbGame";
import NewBolb from "./Game/NewBolb/NewBolb";
import Sphere from "./Game/Sphere/Sphere";
import FlappyBirdMatter from "./Game/FlappyBird/FlappyBirdMatter";
import ReflexGame from "./Game/ReflexGame/ReflexGame";
import CarRace from "./Game/CarRace/CarRace";
export default function Workout() {
  const dispatch = useDispatch();
  const { currentView } = useSelector((store) => store.currentView); // Access the currentView from Redux store
  const { sequence } = useSelector((store) => store.sequenceClick);

  const [playerData, setPlayerData] = useState({
    patientName: "tom",
    jointName: "mot",
  });

  let [v, setv] = useState();

  useEffect(() => {
    const sequenceObjectKeys = ["DoctorName", "Patient", "Activity", "Side", "Date", "Time"];
    if (currentView === "FlappyBird") {
      sequence.forEach((objectValue) => {
        let objKey = Object.keys(objectValue)[0];
        if (objKey === "Patient") {
          setPlayerData((prevState) => ({
            ...prevState,
            patientName: objectValue[objKey],
          }));
        } else if (!sequenceObjectKeys.includes(objKey)) {
          setPlayerData((prevState) => ({
            ...prevState,
            jointName: objectValue[objKey],
          }));
        }
      });
    }
  }, [currentView, sequence]);

  useEffect(() => {
    const setCurrentView = (view) => {
      dispatch(currentViewActions.addView({ value: view })); // Dispatch the action to update the currentView
    };

    if (currentView === "SelectionTabsWorkout") {
      setv(<SelectionTabsWorkout setCurrentView={setCurrentView} />);
    } else if (currentView === "BolbGame") {
      setv(<BolbGame />);
    } else if (currentView === "NewBolb") {
      setv(<NewBolb />);
    } else if (currentView === "Sphere") {
      setv(<Sphere />);
    } else if (currentView === "FlappyBirdMatter") {
      setv(<FlappyBirdMatter playerData={playerData} />);
    } else if (currentView === "ReflexGame") {
      setv(<ReflexGame playerData={playerData} />);
    } else if (currentView === "CarRace") {
      setv(<CarRace />);
    }
  }, [playerData, currentView, dispatch]);

  return (
    <div>
      <AppProgressBar />
      {v}
    </div>
  );
}
