// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.game-area {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: skyblue;
}

.bird {
    position: absolute;
    width: 5vw;
    height: 5vw;
    background-color: yellow;
    border-radius: 50%;
    left: 10vw ;
    /* Fixed horizontal position */
}

.pipe {
    position: absolute;
    width: 6vw;
    background-color: green;
}

.game-over {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Application/Workout/Game/FlappyBird/FlappyBird.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,wBAAwB;IACxB,kBAAkB;IAClB,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,eAAe;IACf,UAAU;AACd","sourcesContent":[".game-area {\r\n    position: relative;\r\n    width: 100vw;\r\n    height: 100vh;\r\n    overflow: hidden;\r\n    background-color: skyblue;\r\n}\r\n\r\n.bird {\r\n    position: absolute;\r\n    width: 5vw;\r\n    height: 5vw;\r\n    background-color: yellow;\r\n    border-radius: 50%;\r\n    left: 10vw ;\r\n    /* Fixed horizontal position */\r\n}\r\n\r\n.pipe {\r\n    position: absolute;\r\n    width: 6vw;\r\n    background-color: green;\r\n}\r\n\r\n.game-over {\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n    font-size: 2rem;\r\n    color: red;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
