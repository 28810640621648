import { parseInt } from "lodash";

const bleService = "2021b100-e8f2-537e-4f6c-d104768a1214";
const ledCharacteristic = "2021b102-e8f2-537e-4f6c-d104768a1214";
const sensorCharacteristic = "2021b101-e8f2-537e-4f6c-d104768a1214";

export let devices = [-1, -1, -1];
export function isWebBluetoothEnabled(
  index,
  onDeviceDataReceived,
  onDeviceDisconnect
) {
  if (typeof navigator.bluetooth === "undefined") {
    alert("Web Bluetooth is not supported on this device or browser.");
    return;
  }

  navigator.bluetooth
    .getAvailability()
    .then((bluetoothAvailable) => {
      if (bluetoothAvailable) {
        console.log("Web Bluetooth supports in this Device");

        navigator.bluetooth
          .requestDevice({
            filters: [
              {
                namePrefix: "BETTER", // Devices whose name starts with 'BETTER'
              },
            ],
            optionalServices: [bleService],
          })
          .then((device) => {
            // Check if the device is already connected
            const existingDevice = devices.find(
              (d) => d !== -1 && d.device.name === device.name
            );
            if (existingDevice) {
              console.log("Device already connected:", device.name);
              return; // If device already connected, exit
            } else {
              console.log("Device Selected:", device.name);
              return device.gatt.connect().then((gattServer) => {
                return { device, gattServer };
              });
            }
          })
          .then((result) => {
            if (!result) return; // If no device was selected, exit
            const { device, gattServer } = result;
            device.addEventListener("gattserverdisconnected", () => {
              handleDeviceDisconnected(index, onDeviceDisconnect);
            });
            return gattServer.getPrimaryService(bleService).then((service) => {
              console.log(service);
              return { device, gattServer, service };
            });
          })
          .then((result) => {
            if (!result) return; // If no device was selected, exit
            const { device, service, gattServer } = result;
            return service
              .getCharacteristic(sensorCharacteristic)
              .then((characteristic) => {
                devices[index] = {
                  device,
                  gattServer,
                  service,
                  characteristic,
                };
                let updatedDevices = [...devices];
                devices = updatedDevices;
                console.log(devices);
                startNotifications(
                  characteristic,
                  device,
                  onDeviceDataReceived
                );
                return characteristic.readValue();
              });
          })
          .then((value) => {
            if (!value) return; // If no device was selected, exit
            console.log("Read value: ", value);
            const decodedValue = new TextDecoder().decode(value);
            console.log("Decoded value: ", decodedValue);
          })
          .catch((error) => {
            if (error.name === "NotFoundError") {
              console.error("User cancelled the requestDevice() chooser.");
            } else if (error.message.includes("Connection attempt failed")) {
              console.error("Error connecting to the device:", error);
              alert(
                "Failed to connect to the device. Please make sure the device is in range and not connected to another device."
              );
            } else {
              console.error("Error in device selection:", error);
            }
          });
      } else {
        console.log("Web Bluetooth is not supported in this Device");
      }
    })
    .catch((error) => {
      console.error("Error checking Bluetooth availability:", error);
    });
}

function startNotifications(characteristic, device, onDeviceDataReceived) {
  characteristic.addEventListener("characteristicvaluechanged", (event) => {
    handleCharacteristicChange(event, device, onDeviceDataReceived);
  });
  characteristic.startNotifications().then(() => {
    console.log("Notifications Started.");
  });
}

function handleCharacteristicChange(event, device, onDeviceDataReceived) {
  const parts = new TextDecoder().decode(event.target.value).split(" ");
  // console.log(parts);
  var mode = parseInt(parts[2]);

  if (mode === 1) {
    onDeviceDataReceived({
      deviceId: device.name,
      battery: parts[0],
      time: parseInt(parts[1]),
      mode: parseInt(parts[2]),
      roll: parseInt(parts[3]),
      pitch: parseInt(parts[4]),
      yaw: parseInt(parts[5]),
      angle: parseInt(parts[6]),
      A_angle: parseInt(parts[7]),
    });
  } else if (mode === 2) {
    onDeviceDataReceived({
      deviceId: device.name,
      battery: parseInt(parts[0]),
      time: parseInt(parts[1]),
      mode: parseInt(parts[2]),
      ap: parseFloat(parts[3]),
      mt: parseFloat(parts[4]),
      g: parseInt(parts[5]),
      x: parseInt(parts[6]),
      y: parseInt(parts[7]),
      z: parseInt(parts[8]),
    });
  } else if (mode === 3) {
    onDeviceDataReceived({
      deviceId: device.name,
      battery: parts[0],
      time: parseInt(parts[1]),
      mode: parseInt(parts[2]),
      roll: parseInt(parts[3]),
      pitch: parseInt(parts[4]),
      yaw: parseInt(parts[5]),
      count: parseInt(parts[6]),
      angle_1: parseInt(parts[7]),
      p: parseInt(parts[8]),
      r2: parseInt(parts[9]),
    });
  } else if (mode === 4) {
    onDeviceDataReceived({
      deviceId: device.name,
      battery: parts[0],
      time: parseInt(parts[1]),
      mode: parseInt(parts[2]),
      xp: parseFloat(parts[3]),
      yp: parseFloat(parts[4]),
      zp: parseFloat(parts[5]),
      xg: parseInt(parts[6]),
      yg: parseInt(parts[7]),
      zg: parseInt(parts[8]),
    });
  } else if (mode === 5) {
    onDeviceDataReceived({
      deviceId: device.name,
      battery: parts[0],
      time: parseInt(parts[1]),
      mode: parseInt(parts[2]),
      roll_4: parseInt(parts[3]) - 180,
      pitch_4: parseInt(parts[4]) - 180,
      yaw_4: parseInt(parts[5]) - 180,
      roll_5: parseInt(parts[6]),
      pitch_5: parseInt(parts[7]),
      yaw_5: parseInt([8]),
      angle_7d: parseInt(parts[9]),
    });
  } else if (mode === 7) {
    onDeviceDataReceived({
      deviceId: device.name,
      battery: parts[0],
      time: parseInt(parts[1]),
      mode: parseInt(parts[2]),
      angle1: parseFloat(parts[3]),
      angle2: parseFloat(parts[4]),
      p: parseInt(parts[5]),
      count: parseInt(parts[6]),
      angle: parseFloat(parts[7]),
    });
  } else if (mode === 8) {
    onDeviceDataReceived({
      deviceId: device.name,
      battery: parts[0],
      time: parseInt(parts[1]),
      mode: parseInt(parts[2]),

      x: parts[3]
        .split(",")
        .filter((item) => item !== "")
        .map(Number),
      y: parts[4]
        .split(",")
        .filter((item) => item !== "")
        .map(Number),

      z: parts[5]
        .split(",")
        .filter((item) => item !== "")
        .map(Number),
      Time: parts[6]
        .split(",")
        .filter((item) => item !== "")
        .map(Number)
        .map((number) => number),
    });
  } else if (mode === 9) {
    onDeviceDataReceived({
      deviceId: device.name,
      battery: parseInt(parts[0]),
      time: parseInt(parts[1]),
      mode: parseInt(parts[2]),
      x: parseFloat(parts[3]),
      y: parseFloat(parts[4]),
      z: parseFloat(parts[5]),
      ax: parseFloat(parts[6]),
      ay: parseFloat(parts[7]),
      az: parseFloat(parts[8]),
      t: parseInt(parts[9]),
      stepx: parseInt(parts[10]) * 3,
      stepy: parseInt(parts[11]) * 3,
      stepz: parseInt(parts[12]) * 3,
      xr: parseFloat(parts[13]),
      zr: parseFloat(parts[14]),
      right: parseInt(parts[15]),
      left: parseInt(parts[16]),
      up: parseInt(parts[17]),
      down: parseInt(parts[18]),
      vertical: parseInt(parts[19]),
      horizontal: parseInt(parts[20]),
    });
    console.log("t==", parseInt(parts[9]));
  } else if (mode === 10) {
    onDeviceDataReceived({
      deviceId: device.name,
      battery: parseInt(parts[0]),
      time: parseInt(parts[1]),
      mode: parseInt(parts[2]),
      roll: parseFloat(parts[3]),
      pitch: parseFloat(parts[4]),
      yaw: parseFloat(parts[5]),
      b: parseInt(parts[6]),
    });
  } else if (mode === 11) {
    onDeviceDataReceived({
      deviceId: device.name,
      battery: parseInt(parts[0]),
      time: parseInt(parts[1]),
      mode: parseInt(parts[2]),
      roll: parseFloat(parts[3]),
      pitch: parseFloat(parts[4]),
      yaw: parseFloat(parts[5]),
      b: parseInt(parts[6]),
    });
  } else if (mode === 12) {
    onDeviceDataReceived({
      deviceId: device.name,
      battery: parts[0],
      time: parseInt(parts[1]),
      mode: parseInt(parts[2]),

      xp: parts[3]
        .split(",")
        .filter((item) => item !== "")
        .map(Number),
      yp: parts[4]
        .split(",")
        .filter((item) => item !== "")
        .map(Number),

      zp: parts[5]
        .split(",")
        .filter((item) => item !== "")
        .map(Number),
      length: parts[6]
        .split(",")
        .filter((item) => item !== "")
        .map(Number)
        .map((number) => number),
    });
  } else {
    onDeviceDataReceived({
      deviceId: device.name,
      battery: parts[0],
      time: parseInt(parts[1]),
      mode: parseInt(parts[2]),
    });
  }
}

export function sendDataToDevice(index, value) {
  if (devices[index] !== -1) {
    const { service } = devices[index];
    if (service) {
      service
        .getCharacteristic(ledCharacteristic)
        .then((characteristic) => {
          const encoder = new TextEncoder();
          const encodedData = encoder.encode(value);
          return characteristic.writeValue(encodedData);
        })
        .then(() => {
          if (value === "disconnect=1") {
            console.log(`Data sent to device ${index}: ${value}`);
            devices[index].gattServer.disconnect();
            devices[index] = -1;
            let updatedDevices = [...devices];
            devices = updatedDevices;
            console.log(devices);
          } else {
            console.log(`Data sent to device ${index}: ${value}`);
          }
        })
        .catch((error) => {
          console.error(`Error sending data to device ${index}:`, error);
        });
    } else {
      console.error(`Service is not defined for device ${index}`);
    }
  } else {
    console.log(`Device ${index} is not connected`);
  }
}
export function disconnectDevice(index) {
  return new Promise((resolve, reject) => {
    if (devices[index] !== -1) {
      devices[index].characteristic
        .stopNotifications()
        .then(() => {
          console.log("Notifications stopped successfully.");
          return devices[index].gattServer.disconnect();
          // return sendDataToDevice(index, "disconnect=1");
        })
        .then(() => {
          devices[index] = -1; // device ko -1 se update karein
          let updatedDevices = [...devices];
          devices = updatedDevices;
          console.log(devices);
          console.log(`Device ${index} disconnected`);
          resolve(); // Promise resolve karein
        })
        .catch((error) => {
          console.error("Failed to stop notifications:", error);
          reject(error); // Promise reject karein agar error aaye
        });
    } else {
      console.log(`Device ${index} is not connected`);
      resolve(); // Device already disconnected hai, resolve karein
    }
  });
}

function handleDeviceDisconnected(index, onDeviceDisconnect) {
  devices[index] = -1;
  let updatedDevices = [...devices];
  devices = updatedDevices;
  console.log(`Device ${index} disconnected`);
  console.log(devices);
  onDeviceDisconnect(index);
}
export function mode0() {
  for (let i = 0; i < devices.length; i++) {
    if (devices[i] !== -1) {
      sendDataToDevice(i, "default_mode=1");
    }
  }
}
export function mode1() {
  for (let i = 0; i < devices.length; i++) {
    if (devices[i] !== -1) {
      sendDataToDevice(i, "rpy=1");
    }
  }
}
export function mode2() {
  for (let i = 0; i < devices.length; i++) {
    if (devices[i] !== -1) {
      sendDataToDevice(i, "acc=1");
    }
  }
}

export function mode3() {
  for (let i = 0; i < devices.length; i++) {
    if (devices[i] !== -1) {
      sendDataToDevice(i, "counter=1");
    }
  }
}

export function mode4() {
  for (let i = 0; i < devices.length; i++) {
    if (devices[i] !== -1) {
      sendDataToDevice(i, "force=1");
    }
  }
}

export function mode5() {
  for (let i = 0; i < devices.length; i++) {
    if (devices[i] !== -1) {
      sendDataToDevice(i, "7D=1");
    }
  }
}
export function mode7() {
  for (let i = 0; i < devices.length; i++) {
    if (devices[i] !== -1) {
      sendDataToDevice(i, "mode7=1");
    }
  }
}
export function mode8() {
  for (let i = 0; i < devices.length; i++) {
    if (devices[i] !== -1) {
      sendDataToDevice(i, "mode8=1");
    }
  }
}
export function mode9() {
  for (let i = 0; i < devices.length; i++) {
    if (devices[i] !== -1) {
      sendDataToDevice(i, "mode9=1");
    }
  }
}

export function mode10() {
  for (let i = 0; i < devices.length; i++) {
    if (devices[i] !== -1) {
      sendDataToDevice(i, "mode10=1");
    }
  }
}

export function mode11() {
  for (let i = 0; i < devices.length; i++) {
    if (devices[i] !== -1) {
      sendDataToDevice(i, "mode11=1");
    }
  }
}
export function mode12() {
  for (let i = 0; i < devices.length; i++) {
    if (devices[i] !== -1) {
      sendDataToDevice(i, "mode12=1");
    }
  }
}

// Tare for multiple device for reset the Values of roll,pitch,yaw
export function tare(index) {
  sendDataToDevice(index, "TARE=1");
}

export function roll(index) {
  sendDataToDevice(index, "roll=1");
}

export function pitch(index) {
  sendDataToDevice(index, "pitch=1");
}

export function yaw(index) {
  sendDataToDevice(index, "yaw=1");
}

export function position1(index) {
  sendDataToDevice(index, "position1=1");
}
export function position2(index) {
  sendDataToDevice(index, "position2=1");
}
