import React, { useEffect, useState } from "react";
import AppProgressBar from "../AppProgressBar";
import { useSelector } from "react-redux";
import ScatterPlot from "./Graphs/ScatterChart/ScatterPlot";
import MultiAxesModeNine from "./Graphs/MultiAxesLineChart/MultiAxesModeNine";
import MultiAxesModeEleven from "./Graphs/MultiAxesLineChart/MultiAxesModeEleven";
import MultiAxesModeEight from "./Graphs/MultiAxesLineChart/MultiAxesModeEight";
import MultiAxesModeTwelve from "./Graphs/MultiAxesLineChart/MultiAxesModeTwelve";
import { mode11, mode12, mode8, mode9 } from "../../Connect/BluetoothHandler";

export default function Progress() {
  const { currentView } = useSelector((store) => store.currentView);
  const { sequence } = useSelector((store) => store.sequenceClick);
  const [patientName, setPatientName] = useState();
  const [view, setView] = useState("Assessment");
  const [graph, setGraph] = useState("Mode11");
  useEffect(() => {
    if (currentView === "Progress") {
      sequence.forEach((objectValue) => {
        let objKey = Object.keys(objectValue)[0];
        if (objKey === "Patient") {
          setPatientName(objectValue[objKey]);
        }
      });
    }
  }, [currentView, sequence]);
  return (
    <>
      <AppProgressBar />
      <div className="m-5  py-1">
        <div className="row   text-center fs-3 fw-normal bg-white p-0 m-0 ">
          <div className="col-8 text-start py-3 pl-2 text-red">
            {patientName}
          </div>
          <div
            className={`col-2 ${
              view === "Assessment" ? "bg-red text-white" : "text-red bg-white"
            } py-3`}
            onClick={() => {
              setView("Assessment");
            }}
          >
            Assessment
          </div>
          <div
            className={`col-2 ${
              view === "Counter" ? "bg-red text-white" : "text-red bg-white"
            } py-3`}
            onClick={() => {
              setView("Counter");
            }}
          >
            Counter
          </div>
        </div>
      </div>

      <div className="m-5  py-1">
        <div className="row   text-center fs-3 fw-normal bg-white p-0 m-0 ">
          <div className="col-4 text-start py-3 pl-2 text-red">
            Modes Graph Selection
          </div>

          <div
            className={`col-2 ${
              graph === "Mode8" ? "bg-red text-white" : "text-red bg-white"
            } py-3`}
            onClick={() => {
              setGraph("Mode8");
              mode8();
            }}
          >
            Mode8
          </div>
          <div
            className={`col-2 ${
              graph === "Mode9" ? "bg-red text-white" : "text-red bg-white"
            } py-3`}
            onClick={() => {
              setGraph("Mode9");
              mode9();
            }}
          >
            Mode9
          </div>
          <div
            className={`col-2 ${
              graph === "Mode11" ? "bg-red text-white" : "text-red bg-white"
            } py-3`}
            onClick={() => {
              setGraph("Mode11");
              mode11();
            }}
          >
            Mode11
          </div>
          <div
            className={`col-2 ${
              graph === "Mode12" ? "bg-red text-white" : "text-red bg-white"
            } py-3`}
            onClick={() => {
              setGraph("Mode12");
              mode12();
            }}
          >
            Mode12
          </div>
        </div>
      </div>
      <div className=" px-5" style={{ width: "100%" }}>
        {graph === "Mode8" && <MultiAxesModeEight />}
        {graph === "Mode9" && <MultiAxesModeNine />}
        {graph === "Mode11" && <MultiAxesModeEleven />}
        {graph === "Mode12" && <MultiAxesModeTwelve />}
      </div>

      <div className="mt-5 px-5">
        <ScatterPlot />
      </div>
    </>
  );
}
