export default function calculateCoordinates(ap, mt, centreDegree) {
    const centredegree = centreDegree;
  
    // Calculate distances from origin and create an array of objects with key and value
    const distances = ap.map((y, i) => ({
      key: i,
      value: Math.sqrt(Math.pow(mt[i], 2) + Math.pow(y, 2)),
    }));
  
    // Sort distances by value in ascending order
    distances.sort((a, b) => a.value - b.value);
  
    // Calculate the start and end indices for slicing the array
    const length = distances.length;
    const start = Math.floor(length * 0.1);
    const end = Math.floor(length * 0.9);
  
    // Filter distances to remove the top and bottom 10%
    const filteredDistances = distances.slice(start, end);
  
    // Sort the filtered distances by the original indices (key) in ascending order
    filteredDistances.sort((a, b) => a.key - b.key);
  
    // Calculate the percentage of distances less than centredegree
    const count = filteredDistances.filter(
      ({ value }) => value < centredegree
    ).length;
    const centre = (count / filteredDistances.length) * 100;
  
    // Create arrays for aps and mts based on filtered distances
    const aps = filteredDistances.map(({ key }) => ap[key]);
    const mts = filteredDistances.map(({ key }) => mt[key]);
  
    // Initialize quadrant counters
    let anterior = 0;
    let posterior = 0;
    let left = 0;
    let right = 0;
  
    // Calculate the number of points in each quadrant
    let notIncludePoints = 0;
    for (let i = 0; i < aps.length; i++) {
      console.log(`Processing point ${i}: ap=${aps[i]}, mt=${mts[i]}, distance=${filteredDistances[i].value}`);
      if (filteredDistances[i].value > 3) {
        if (aps[i] <= 0 && mts[i] >= 0) {
          if (mts[i] > -aps[i]) {
            right++;
          } else {
            posterior++;
          }
        } else if (aps[i] <= 0 && mts[i] < 0) {
          if (mts[i] < aps[i]) {
            left++;
          } else {
            posterior++;
          }
        } else if (aps[i] >= 0 && mts[i] >= 0) {
          if (mts[i] > aps[i]) {
            right++;
          } else {
            anterior++;
          }
        } else if (aps[i] >= 0 && mts[i] < 0) {
          if (-mts[i] > aps[i]) {
            right++;
          } else {
            anterior++;
          }
        }
      } else {
        notIncludePoints++;
      }
    }
  
    const total = aps.length - notIncludePoints;
    console.log(`Total points: ${aps.length}, Not included points: ${notIncludePoints}, Included points: ${total}`);
  
    // Calculate percentages for each quadrant
    const quadrantPercentages = {
      anterior: (anterior / total) * 100,
      posterior: (posterior / total) * 100,
      left: (left / total) * 100,
      right: (right / total) * 100,
    };
  
    // Return the results
    return {
      centre: centre.toFixed(2),
      anterior: quadrantPercentages.anterior.toFixed(2),
      posterior: quadrantPercentages.posterior.toFixed(2),
      left: quadrantPercentages.left.toFixed(2),
      right: quadrantPercentages.right.toFixed(2),
    };
  }
  
  // Example usage:
  
  // const results = calculateCoordinates(Object.values(ap), Object.values(mt), centreDegree);
  // console.log("Centre Percentage:", results.centre);
  // console.log("Quadrant Percentages:", results);
  