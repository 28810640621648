import React, { useEffect, useState } from "react";
import { ActivePassive, Manual } from "./ActivePassive";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import axios from "axios";
import { sequenceClickActions } from "../../../../store/squenceclick";

import PlayerData from "../../../../Components/PlayerData";

export default function NormalAssessment() {
  const dispatch = useDispatch();
  const { currentView } = useSelector((store) => store.currentView);
  const { deviceinfo } = useSelector((store) => store.devicedata);
  const { sequence } = useSelector((store) => store.sequenceClick);
  // States for pain index and stiffness
  const [painIndexStiffness, setPainIndexStiffness] = useState(0);
  const [stiffness, setStiffness] = useState(0);
  const [activeRangeAngle, setactiveRangeAngle] = useState(0);
  const [passiveRangeAngle, setpassiveRangeAngle] = useState(0);
  const sideObject = sequence.find((obj) => obj.hasOwnProperty("Side"));
  const [side, setSide] = useState(sideObject ? sideObject.Side : "Right");

  const activeContent = {
    range: "Active Range",
    automatic: "Automatic",
    manual: "Manual",
    angle: deviceinfo[0]?.angle ?? 0,
    description:
      "ipsum dolor sit amet consectetur adipisicing elit. Dolor facere tempore porro sequi ut aliquam non eos quos fuga, ratione sapiente voluptatem, ea inventore. Maxime eius quasi nisi minus consectetur.",
  };

  const passiveContent = {
    range: "Passive Range",
    automatic: "Automatic",
    manual: "Manual",
    angle: deviceinfo[0]?.angle ?? 0,
    description:
      "ipsum dolor sit amet consectetur adipisicing elit. Dolor facere tempore porro sequi ut aliquam non eos quos fuga, ratione sapiente voluptatem, ea inventore. Maxime eius quasi nisi minus consectetur.",
  };

  const painIndexContent = {
    name: "PainIndex",
    manual: "Manual",
    angle: 0,
    description:
      "ipsum dolor sit amet consectetur adipisicing elit. Dolor facere tempore porro sequi ut aliquam non eos quos fuga, ratione sapiente voluptatem, ea inventore. Maxime eius quasi nisi minus consectetur.",
  };

  const stiffnessContent = {
    name: "Stiffness",
    manual: "Manual",
    angle: 0,
    description:
      "ipsum dolor sit amet consectetur adipisicing elit. Dolor facere tempore porro sequi ut aliquam non eos quos fuga, ratione sapiente voluptatem, ea inventore. Maxime eius quasi nisi minus consectetur.",
  };

  const [{ patientName, jointName }, setAssessmentData] = useState({
    patientName: "",
    jointName: "",
  });

  const updateSideInSequence = (side) => {
    let newSequence = [...sequence];
    let sideIndex = newSequence.findIndex((item) => item.Side);

    if (sideIndex !== -1) {
      newSequence[sideIndex] = { Side: side };
    }

    dispatch(sequenceClickActions.setSequenceClick({ value: newSequence }));
  };

  useEffect(() => {
    if (sideObject) {
      setSide(sideObject.Side);
    }
  }, [sideObject]);

  useEffect(() => {
    const sequenceObjectKeys = [
      "DoctorName",
      "Patient",
      "Activity",
      "Side",
      "Date",
      "Time",
      "PainIndex",
      "Stiffness",
      "Active Range",
      "Passive Range",
    ];
    if (currentView === "Assessment") {
      sequence.forEach((objectValue) => {
        let objKey = Object.keys(objectValue)[0];
        if (objKey === "Patient") {
          setAssessmentData((prevState) => ({
            ...prevState,
            patientName: objectValue[objKey],
          }));
        } else if (!sequenceObjectKeys.includes(objKey)) {
          setAssessmentData((prevState) => ({
            ...prevState,
            jointName: objectValue[objKey],
          }));
        }
      });
    }
  }, [currentView, sequence]);

  const saveAssessmentData = async () => {
    try {
      console.log("try");
      const token = Cookies.get("token");
      if (!token) {
        alert("Authentication token is missing. Please log in again");
        return;
      }

      const response = await axios.post(
        `https://go.raycura.com:5000/api/patients/update/${patientName}`,
        [
          { Patient: patientName },
          { Activity: "Assessment" },
          { "Upper Extremity": jointName },
          { Side: side },
          { Date: "01-10-24" },
          { "Active Range": activeRangeAngle },
          { "Passive Range": passiveRangeAngle },
          { PainIndex: painIndexStiffness },
          { Stiffness: stiffness },
        ],
        { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }
      );
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving assessment data:", error);
      alert("Failed to save assessment data. Please try again.");
    }
  };

  // Time Display:
  const [currentTime, setCurrentTime] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTime = async () => {
      try {
        const response = await axios.get("https://worldtimeapi.org/api/timezone/Asia/Kolkata");
        const dateTime = new Date(response.data.datetime);
        console.log(dateTime)
        setCurrentTime(dateTime);
      } catch (err) {
        setError("Unable to fetch time.");
        console.log(error);
      }
    };

    fetchTime();
    const interval = setInterval(fetchTime, 60000); // Update every minute

    return () => clearInterval(interval);
  }, [error]);

  const formatDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("en-GB", options); // DD/MM/YYYY format
  };

  const formatTime = (date) => {
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    return date.toLocaleTimeString("en-US", options); // 12-hour format with AM/PM
  };

  const updateSequenceWithDateTimeAndValues = (date, time, painIndex, stiffness) => {
    let newSequence = [...sequence];

    const updateOrPush = (key, value) => {
      let index = newSequence.findIndex((item) => item.hasOwnProperty(key));
      if (index !== -1) {
        newSequence[index] = { [key]: value };
      } else {
        newSequence.push({ [key]: value });
      }
    };

    // Date and Time
    updateOrPush("Date", date);
    updateOrPush("Time", time);

    // Active and Passive Range
    updateOrPush("Active Range", activeRangeAngle);
    updateOrPush("Passive Range", passiveRangeAngle);

    // PainIndex and Stiffness (if you need to update these)
    updateOrPush("PainIndex", painIndex);
    updateOrPush("Stiffness", stiffness);

    return newSequence;
  };

  return (
    <>
      <div className={`${currentView === "Assessment" ? "d-block" : "d-none"}`}>
        <section className="container-fluid px-5 mt-3 bg-light-red h-100">
          <PlayerData
            playerData={{
              patientName,
              jointName,
              currentTime,

            }}
          />
          <div className="mx-5 my-5 px-2 ">
            <div className="row">
              <div
                className={`${side === "Right" ? "text-white bg-red" : "text-red bg-white"} col-6 rounded-start-2`}
                onClick={() => {
                  setSide("Right");
                  updateSideInSequence("Right");
                }}
              >
                <p className="text-center fw-semibold fs-4 pt-3">Right</p>
              </div>

              <div
                className={`${side === "Left" ? "text-white bg-red" : "text-red bg-white"} col-6 rounded-end-2`}
                onClick={() => {
                  setSide("Left");
                  updateSideInSequence("Left");
                }}
              >
                <p className="text-center fw-semibold fs-4 pt-3">Left</p>
              </div>
            </div>
          </div>
          <ActivePassive content={activeContent} setactiveRangeAngle={setactiveRangeAngle} />
          <ActivePassive content={passiveContent} setpassiveRangeAngle={setpassiveRangeAngle} />
          <Manual content={painIndexContent} setPainIndex={setPainIndexStiffness} />
          <Manual content={stiffnessContent} setStiffness={setStiffness} />
          <div className="container-fluid py-5 px-5">
            <div className="mt-2 px-5 rounded-2">
              <div className="row justify-content-center">
                <div
                  className="col-3 bg-red rounded-5"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const date = formatDate(currentTime);
                    const time = formatTime(currentTime);
                    const newSequence = updateSequenceWithDateTimeAndValues(date, time, painIndexStiffness, stiffness);

                    dispatch(
                      sequenceClickActions.setSequenceClick({
                        value: newSequence,
                      })
                    );
                    saveAssessmentData();
                  }}
                >
                  <p className="text-white fw-semibold fs-4 pt-3 text-center">Save</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
