import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ping, tare } from "../../../../Connect/BluetoothHandler";

export default function ReflexGame() {
  let { deviceinfo } = useSelector((store) => store.devicedata);

  let [value, setValue] = useState(0);
  let canvasRef = useRef();
  const [ball, setBall] = useState({ x: null, y: null });
  const [targetSide, setTargetSide] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [color, setColor] = useState(["#f0f0f0", "#f0f0f0"]);
  const [time, setTime] = useState({
    sendTime: null,
    generateTime: null,
    motorTime: null,
    reactionTime: null,
    responseTime: null,
    correctTime: null,
    latency: null,
  });
  const [correctCount, setCorrectCount] = useState(0);

  let generate = useCallback(() => {
    const colors = ["#00c69d", "#e2c2f3", "#09bcff", "#f3e65c", "#fe7a39", "#fd52b0"];
    const randomColor = colors[Math.floor(Math.random() * colors.length)];

    const num = Math.random();
    if (num < 0.5) {
      setColor([randomColor, "#f0f0f0"]); // return for left
      setTargetSide("Left");
    } else {
      setColor(["#f0f0f0", randomColor]); // return for right
      setTargetSide("Right");
    }

    const generate_time = new Date().getTime;
    setTime((prev) => ({
      ...prev,
      generateTime: generate_time,
    }));
  }, []);

  const generateRandomText = useCallback(() => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let text = "";
    for (let i = 0; i < 5; i++) {
      text += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    console.log("the text send", text);
    return text;
  }, []);

  let results = useCallback(() => {
    const correct_Time = new Date().getTime();
    const motor_time = deviceinfo[0]?.time - deviceinfo[0]?.startTime;
    const response_time = correct_Time - time.generateTime - time.latency;
    const reaction_time = response_time - motor_time;
    console.log(reaction_time);
    setTime((prev) => ({
      ...prev,
      motorTime: motor_time,
      responseTime: response_time,
      reactionTime: reaction_time,
      correctTime: correct_Time,
    }));

    setCorrectCount((prev) => 1 + prev);
  }, [deviceinfo, time.generateTime, time.latency]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Variables for box dimensions
    const boxHeight = canvas.height * 0.9;
    const boxWidth = canvas.width * 0.425;

    // Draw the first box
    const firstBoxX = canvas.width * 0.275 - boxWidth / 2;
    const firstBoxY = canvas.height * 0.5 - boxHeight / 2;

    ctx.fillStyle = color[0]; // Set box color
    ctx.fillRect(firstBoxX, firstBoxY, boxWidth, boxHeight);

    // Draw the second box
    const secondBoxX = canvas.width * 0.725 - boxWidth / 2;
    const secondBoxY = canvas.height * 0.5 - boxHeight / 2;

    ctx.fillStyle = color[1]; // Set box color
    ctx.fillRect(secondBoxX, secondBoxY, boxWidth, boxHeight);

    let moveBall = (width) => {
      if (value === 1) {
        if (toggle) {
          return width * 0.25;
        } else {
          return width * 0.75;
        }
      } else if (value === -1) {
        if (toggle) {
          return width * 0.75;
        } else {
          return width * 0.25;
        }
      } else {
        return width * 0.5;
      }
    };

    // Variables for the ball
    const ballRadius = canvas.width * 0.1;
    const ballX = moveBall(canvas.width);
    const ballY = canvas.height * 0.5;

    setBall({ x: ballX, y: ballY });

    // Draw the ball
    ctx.fillStyle = "red"; // Set ball color
    ctx.beginPath();
    ctx.arc(ballX, ballY, ballRadius, 0, Math.PI * 2);
    ctx.closePath();
    ctx.fill();
  }, [toggle, value, color]);

  useEffect(() => {
    let id;
    if (value === 0) {
      // Setting a timeout to log "color" after 3000 ms
      id = setTimeout(() => {
        console.log("color");
        generate(); // Call the generate function after the timeout
      }, Math.random() * 2000 + 2000);
    } else {
      clearTimeout(id); // Clear the timeout if value is not 0
    }

    return () => {
      clearTimeout(id); // Cleanup function to clear timeout on unmount
    };
  }, [generate, value]);

  useEffect(() => {
    // Select

    if (targetSide === "Right" && ball.x > 0.5 * canvasRef.current.width) {
      results();
      console.log("correct");
      
      setTargetSide(null);
    } else if (targetSide === "Left" && ball.x < 0.5 * canvasRef.current.width) {
      results();
      console.log("correct");
      setTargetSide(null);
    } else if (targetSide === "Right" && ball.x < 0.5 * canvasRef.current.width) {
      console.log("incorrect");
      setTargetSide(null);
    } else if (targetSide === "Left" && ball.x > 0.5 * canvasRef.current.width) {
      console.log("incorrect");
      setTargetSide(null);
    }

    if (value !== 0) {
      setTimeout(() => {
        setColor(["#f0f0f0", "#f0f0f0"]);
      }, 1000);
    }
  }, [value, ball.x, targetSide, results]);

  useEffect(() => {
    if (value === 0) {
      ping(0, generateRandomText());
      const send_time = new Date().getTime();
      setTime((prev) => ({
        ...prev,
        sendTime: send_time,
      }));
    }
  }, [value, generateRandomText]);

  useEffect(() => {
    if (deviceinfo[0]?.ping) {
      const recieveTime = new Date().getTime();
      if (generateRandomText === deviceinfo[0]?.ping) {
        const Latency = (recieveTime - time.sendTime) / 2;
        setTime((prev) => ({
          ...prev,
          latency: Latency,
        }));
      }
      console.log(deviceinfo[0]?.ping);
    }
  }, [deviceinfo, generateRandomText, time.sendTime]);
  useEffect(() => {
    if (deviceinfo[0]?.mode === 14) {
      setValue(deviceinfo[0]?.rolls);
    }
  }, [deviceinfo]);

  return (
    <div>
      <div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            tare(0);
          }}
        >
          Tare
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setToggle(0);
          }}
        >
          Toggle
        </button>
      </div>
      <div>{correctCount}</div>
      <div>
        <canvas ref={canvasRef} height={window.innerHeight * 0.9} width={window.innerHeight * 0.9}></canvas>
      </div>
    </div>
  );
}
