import React, { useEffect, useState } from "react";
import { ActivePassive, Manual } from "./ActivePassive";
import { useDispatch, useSelector } from "react-redux";
import AppProgressBar from "../AppProgressBar";
import SelectionTabsAssesment from "./SelectionTabsAssesment";
import { currentViewActions } from "../../../store/currentView";
import axios from "axios";
import { sequenceClickActions } from "../../../store/squenceclick";
import BalanceAssesment from "./BalanceAssesment/BalanceAssesment";
import PlayerData from "../../../Components/PlayerData";

export default function Assesment() {
  const dispatch = useDispatch();
  const { currentView } = useSelector((store) => store.currentView);
  const { deviceinfo } = useSelector((store) => store.devicedata);
  const { sequence } = useSelector((store) => store.sequenceClick);
  // States for pain index and stiffness
  const [painIndexStiffness, setPainIndexStiffness] = useState(0);
  const [stiffness, setStiffness] = useState(0);
  const [activeRangeAngle, setactiveRangeAngle] = useState(0);
  const [passiveRangeAngle, setpassiveRangeAngle] = useState(0);
  const sideObject = sequence.find((obj) => obj.hasOwnProperty("Side"));
  const [side, setSide] = useState(sideObject ? sideObject.Side : "Right");

  const activeContent = {
    range: "Active Range",
    automatic: "Automatic",
    manual: "Manual",
    angle: deviceinfo[0]?.angle ?? 0,
    description:
      "ipsum dolor sit amet consectetur adipisicing elit. Dolor facere tempore porro sequi ut aliquam non eos quos fuga, ratione sapiente voluptatem, ea inventore. Maxime eius quasi nisi minus consectetur.",
  };

  const passiveContent = {
    range: "Passive Range",
    automatic: "Automatic",
    manual: "Manual",
    angle: deviceinfo[0]?.angle ?? 0,
    description:
      "ipsum dolor sit amet consectetur adipisicing elit. Dolor facere tempore porro sequi ut aliquam non eos quos fuga, ratione sapiente voluptatem, ea inventore. Maxime eius quasi nisi minus consectetur.",
  };

  const painIndexContent = {
    name: "PainIndex",
    manual: "Manual",
    angle: 0,
    description:
      "ipsum dolor sit amet consectetur adipisicing elit. Dolor facere tempore porro sequi ut aliquam non eos quos fuga, ratione sapiente voluptatem, ea inventore. Maxime eius quasi nisi minus consectetur.",
  };

  const stiffnessContent = {
    name: "Stiffness",
    manual: "Manual",
    angle: 0,
    description:
      "ipsum dolor sit amet consectetur adipisicing elit. Dolor facere tempore porro sequi ut aliquam non eos quos fuga, ratione sapiente voluptatem, ea inventore. Maxime eius quasi nisi minus consectetur.",
  };

  const setCurrentView = (view) => {
    dispatch(currentViewActions.addView({ value: view }));
  };

  const [{ patientName, jointName }, setAssessmentData] = useState({
    patientName: "",
    jointName: "",
  });

  const updateSideInSequence = (side) => {
    let newSequence = [...sequence];
    let sideIndex = newSequence.findIndex((item) => item.Side);

    if (sideIndex !== -1) {
      newSequence[sideIndex] = { Side: side };
    }

    dispatch(sequenceClickActions.setSequenceClick({ value: newSequence }));
  };

  useEffect(() => {
    if (sideObject) {
      setSide(sideObject.Side);
    }
  }, [sideObject]);

  useEffect(() => {
    const sequenceObjectKeys = [
      "DoctorName",
      "Patient",
      "Activity",
      "Side",
      "Date",
      "Time",
      "PainIndex",
      "Stiffness",
      "Active Range",
      "Passive Range",
    ];
    if (currentView === "Assesment") {
      sequence.forEach((objectValue) => {
        let objKey = Object.keys(objectValue)[0];
        if (objKey === "Patient") {
          setAssessmentData((prevState) => ({
            ...prevState,
            patientName: objectValue[objKey],
          }));
        } else if (!sequenceObjectKeys.includes(objKey)) {
          setAssessmentData((prevState) => ({
            ...prevState,
            jointName: objectValue[objKey],
          }));
        }
      });
    }
  }, [currentView, sequence]);

  // Time Display:
  const [currentTime, setCurrentTime] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTime = async () => {
      try {
        const response = await axios.get(
          "http://worldtimeapi.org/api/timezone/Asia/Kolkata"
        );
        const dateTime = new Date(response.data.datetime);
        setCurrentTime(dateTime);
      } catch (err) {
        setError("Unable to fetch time.");
        console.log(error);
      }
    };

    fetchTime();
    const interval = setInterval(fetchTime, 60000); // Update every minute

    return () => clearInterval(interval);
  }, [error]);

  const formatDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("en-GB", options); // DD/MM/YYYY format
  };

  const formatTime = (date) => {
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    return date.toLocaleTimeString("en-US", options); // 12-hour format with AM/PM
  };

  const updateSequenceWithDateTimeAndValues = (
    date,
    time,
    painIndex,
    stiffness
  ) => {
    let newSequence = [...sequence];
    let dateIndex = newSequence.findIndex((item) => item.Date);
    let timeIndex = newSequence.findIndex((item) => item.Time);
    let PainIndex = newSequence.findIndex((item) => item.PainIndex);
    let Stiffness = newSequence.findIndex((item) => item.Stiffness);
    let ActiveRange = newSequence.findIndex((item) => item["Active Range"]);
    let PassiveRange = newSequence.findIndex((item) => item["Passive Range"]);

    // Date and Time Push in sequenceClick
    if (dateIndex !== -1) {
      newSequence[dateIndex] = { Date: date };
    } else {
      newSequence.push({ Date: date });
    }

    if (timeIndex !== -1) {
      newSequence[timeIndex] = { Time: time };
    } else {
      newSequence.push({ Time: time });
    }

    // Active Range and Passive Range Puch in SequenceClick
    if (ActiveRange !== -1) {
      newSequence[ActiveRange] = { "Active Range": activeRangeAngle };
    } else {
      newSequence.push({ "Active Range": activeRangeAngle });
    }

    if (PassiveRange !== -1) {
      newSequence[PassiveRange] = { "Passive Range": passiveRangeAngle };
    } else {
      newSequence.push({ "Passive Range": passiveRangeAngle });
    }

    // PainIndex and Stiffness Push in SequenceClick
    if (PainIndex !== -1) {
      newSequence[PainIndex] = { PainIndex: painIndex };
    } else {
      newSequence.push({ PainIndex: painIndex });
    }

    if (Stiffness !== -1) {
      newSequence[Stiffness] = { Stiffness: stiffness };
    } else {
      newSequence.push({ Stiffness: stiffness });
    }

    return newSequence;
  };

  return (
    <>
      <AppProgressBar />

      <div
        className={`${
          currentView === "SelectionTabsAssesment" ? "d-block" : "d-none"
        }`}
      >
        <SelectionTabsAssesment setCurrentView={setCurrentView} />
      </div>

      <div
        className={`${
          currentView === "BalanceAssesment" ? "d-block" : "d-none"
        }`}
      >
        <BalanceAssesment />
      </div>

      <div className={`${currentView === "Assesment" ? "d-block" : "d-none"}`}>
        <section className="container-fluid px-5 mt-3 bg-light-red h-100">
          <PlayerData
            playerData={{
              patientName,
              jointName,
              currentTime,
              formatDate,
              formatTime,
            }}
          />
          <div className="mx-5 my-5 px-2 ">
            <div className="row">
              <div
                className={`${
                  side === "Right" ? "text-white bg-red" : "text-red bg-white"
                } col-6 rounded-start-2`}
                onClick={() => {
                  setSide("Right");
                  updateSideInSequence("Right");
                }}
              >
                <p className="text-center fw-semibold fs-4 pt-3">Right</p>
              </div>

              <div
                className={`${
                  side === "Left" ? "text-white bg-red" : "text-red bg-white"
                } col-6 rounded-end-2`}
                onClick={() => {
                  setSide("Left");
                  updateSideInSequence("Left");
                }}
              >
                <p className="text-center fw-semibold fs-4 pt-3">Left</p>
              </div>
            </div>
          </div>
          <ActivePassive
            content={activeContent}
            setactiveRangeAngle={setactiveRangeAngle}
          />
          <ActivePassive
            content={passiveContent}
            setpassiveRangeAngle={setpassiveRangeAngle}
          />
          <Manual
            content={painIndexContent}
            setPainIndex={setPainIndexStiffness}
          />
          <Manual content={stiffnessContent} setStiffness={setStiffness} />
          <div className="container-fluid py-5 px-5">
            <div className="mt-2 px-5 rounded-2">
              <div className="row justify-content-center">
                <div
                  className="col-3 bg-red rounded-5"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const date = formatDate(currentTime);
                    const time = formatTime(currentTime);
                    const newSequence = updateSequenceWithDateTimeAndValues(
                      date,
                      time,
                      painIndexStiffness,
                      stiffness
                    );

                    dispatch(
                      sequenceClickActions.setSequenceClick({
                        value: newSequence,
                      })
                    );
                  }}
                >
                  <p className="text-white fw-semibold fs-4 pt-3 text-center">
                    Save
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
