// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.canvas-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

canvas {
  border: 1px solid black;
  margin-bottom: 20px;
}

canvas:last-child {
  margin-bottom: 0;
}

h2 {
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Application/Workout/Game/NewBolb/NewBolb.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".App {\r\n  text-align: center;\r\n}\r\n\r\n.canvas-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  margin: 20px;\r\n}\r\n\r\ncanvas {\r\n  border: 1px solid black;\r\n  margin-bottom: 20px;\r\n}\r\n\r\ncanvas:last-child {\r\n  margin-bottom: 0;\r\n}\r\n\r\nh2 {\r\n  margin-top: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
