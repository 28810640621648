import React, { useRef, useState, useEffect, useCallback } from "react";
import "./NewBolb.css";
import { useSelector } from "react-redux";
import { mode2, tare } from "../../../../Connect/BluetoothHandler";

export default function NewBolb() {
  let { deviceinfo } = useSelector((store) => store.devicedata);

  const arcCanvasRef = useRef(null);
  const [outerColors, setOuterColors] = useState(Array(8).fill("white"));
  const [innerColors, setInnerColors] = useState(Array(8).fill("white"));
  const [selectedBodies, setSelectedBodies] = useState([]);
  const [unusedRegions, setUnusedRegions] = useState([]);

  const centerX = 250;
  const centerY = 250;
  const outerRadius = 200;
  const innerRadius = 100;
  const numSegments = 8;
  const angleIncrement = (2 * Math.PI) / numSegments;

  const Regions = [
    { "canvasHeight/4": "0" },
    { "canvasHeight/2": "0" },
    { "canvasHeight/4": "45" },
    { "canvasHeight/2": "45" },
    { "canvasHeight/4": "90" },
    { "canvasHeight/2": "90" },
    { "canvasHeight/4": "135" },
    { "canvasHeight/2": "135" },
    { "canvasHeight/4": "180" },
    { "canvasHeight/2": "180" },
    { "canvasHeight/4": "225" },
    { "canvasHeight/2": "225" },
    { "canvasHeight/4": "270" },
    { "canvasHeight/2": "270" },
    { "canvasHeight/4": "315" },
    { "canvasHeight/2": "315" },
  ];

  useEffect(() => {
    const canvas = arcCanvasRef.current;
    const ctx = canvas.getContext("2d");

    const drawSegment = (
      startAngle,
      endAngle,
      outerRadius,
      innerRadius,
      color
    ) => {
      ctx.beginPath();
      ctx.moveTo(
        centerX + innerRadius * Math.cos(startAngle),
        centerY + innerRadius * Math.sin(startAngle)
      );
      ctx.arc(centerX, centerY, outerRadius, startAngle, endAngle);
      ctx.lineTo(
        centerX + innerRadius * Math.cos(endAngle),
        centerY + innerRadius * Math.sin(endAngle)
      );
      ctx.arc(centerX, centerY, innerRadius, endAngle, startAngle, true);
      ctx.closePath();
      ctx.fillStyle = color;
      ctx.fill();
      ctx.stroke();
      ctx.strokeStyle = "red";
      ctx.lineWidth = 4;
    };

    const drawCircle = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < numSegments; i++) {
        const startAngle = i * angleIncrement;
        const endAngle = startAngle + angleIncrement;
        drawSegment(
          startAngle,
          endAngle,
          outerRadius,
          innerRadius,
          outerColors[i]
        );
        drawSegment(startAngle, endAngle, innerRadius, 0, innerColors[i]);
      }
    };

    drawCircle();
  }, [outerColors, innerColors, angleIncrement]);

  const getAngle = (x, y) => {
    let angle = Math.atan2(y - centerY, x - centerX);
    if (angle < 0) {
      angle += 2 * Math.PI;
    }
    return angle;
  };

  const getDistance = (x, y) => {
    return Math.sqrt((x - centerX) ** 2 + (y - centerY) ** 2);
  };

  const handleCanvasClick = (event) => {
    const rect = arcCanvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const angle = getAngle(x, y);
    const distance = getDistance(x, y);

    const segmentIndex = Math.floor(angle / angleIncrement);

    if (distance <= innerRadius) {
      const newInnerColors = [...innerColors];
      newInnerColors[segmentIndex] =
        newInnerColors[segmentIndex] === "red" ? "white" : "red";
      setInnerColors(newInnerColors);

      updateSelectedBodies(segmentIndex, newInnerColors[segmentIndex], "inner");
    } else if (distance <= outerRadius) {
      const newOuterColors = [...outerColors];
      newOuterColors[segmentIndex] =
        newOuterColors[segmentIndex] === "red" ? "white" : "red";
      setOuterColors(newOuterColors);

      updateSelectedBodies(segmentIndex, newOuterColors[segmentIndex], "outer");
    }
  };

  const updateSelectedBodies = (index, color, circleType) => {
    const regionIndex = circleType === "inner" ? index * 2 : index * 2 + 1;
    const regionKey =
      circleType === "inner" ? "canvasHeight/4" : "canvasHeight/2";
    const regionValue = Regions[regionIndex][regionKey];

    setSelectedBodies((prevSelectedBodies) => {
      const newSelectedBodies = Array.isArray(prevSelectedBodies)
        ? [...prevSelectedBodies]
        : [];
      const bodyIndex = newSelectedBodies.findIndex(
        (obj) => obj[regionKey] === regionValue
      );

      if (color === "red") {
        if (bodyIndex === -1) {
          newSelectedBodies.push({ [regionKey]: regionValue });
        }
      } else {
        if (bodyIndex !== -1) {
          newSelectedBodies.splice(bodyIndex, 1);
        }
      }
      return newSelectedBodies;
    });
  };

  useEffect(() => {
    setUnusedRegions(selectedBodies);
  }, [selectedBodies]);

  const ballCanvasRef = useRef(null);
  const [canvasWidth] = useState(window.innerHeight);
  const [canvasHeight] = useState(window.innerHeight);
  const [ballSize] = useState(0.03 * canvasHeight); // 3% of 400
  const [ballX, setBallX] = useState(0);
  const [ballY, setBallY] = useState(0);

  const [xwidth] = useState(18); // yaw range
  const [yheight] = useState(18); // roll range
  const [rotation, setRotation] = useState(0); // Rotation angle in degrees
  const [distance, setDistance] = useState(0); // Distance from the center
  const [collision, setCollison] = useState(true);
  const [sustain, setSustain] = useState(1000);
  const [precision, setPrecision] = useState(6);

  const blackBallRadius = (precision / 100) * yheight * canvasHeight * 0.15; // 3% of canvas height

  const [collisionStartTime, setCollisionStartTime] = useState(null);
  useEffect(() => {
    if (collision) {
      if (unusedRegions.length > 0) {
        const randomRegion =
          unusedRegions[Math.floor(Math.random() * unusedRegions.length)];
        const [key, value] = Object.entries(randomRegion)[0];
        const maxDistance = eval(key.replace("canvasHeight", canvasHeight));
        const minDistance = maxDistance - canvasHeight / 4 + canvasHeight / 8;
        const newDistance =
          Math.random() * (maxDistance - minDistance) +
          minDistance -
          blackBallRadius;
        const minRotation = Number(value);
        const maxRotation = minRotation + 45;
        const newRotation =
          Math.random() * (maxRotation - minRotation) + minRotation;
        setDistance(newDistance);
        setRotation(newRotation);

        setUnusedRegions((prevUnusedRegions) =>
          prevUnusedRegions.filter((region) => region !== randomRegion)
        );
        setCollison(false);
      } else {
        setUnusedRegions(selectedBodies);
      }
    }
  }, [canvasHeight, unusedRegions, selectedBodies, collision, blackBallRadius]);

  const checkCollision = useCallback(
    (ballX, ballY, blackBallX, blackBallY, ballSize, blackBallRadius) => {
      let distanceBetweenCenters = Math.sqrt(
        (blackBallX - ballX) * (blackBallX - ballX) +
          (blackBallY - ballY) * (blackBallY - ballY)
      );

      if (distanceBetweenCenters <= blackBallRadius - ballSize) {
        if (!collisionStartTime) {
          setCollisionStartTime(Date.now());
        } else {
          const elapsed = Date.now() - collisionStartTime;
          if (elapsed >= Math.floor(sustain)) {
            // 2 seconds in milliseconds
            if (!collision) {
              setCollison(true);
              console.log(
                "Blue ball is inside the black ball for 2 seconds",
                distanceBetweenCenters,
                blackBallRadius - ballSize
              );
            }
          }
        }
      } else {
        setCollisionStartTime(null);
        setCollison(false);
        console.log("No collision detected", blackBallX, blackBallY);
      }
    },
    [collision, collisionStartTime, sustain]
  );

  useEffect(() => {
    const canvas = ballCanvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw blue ball
    ctx.beginPath();
    ctx.arc(ballX, ballY, ballSize, 0, 2 * Math.PI);
    ctx.fillStyle = "blue";
    ctx.fill();

    // Draw black ball
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;
    const radianAngle = (rotation * Math.PI) / 180;
    const blackBallX = centerX + distance * Math.cos(radianAngle);
    const blackBallY = centerY + distance * Math.sin(radianAngle);

    checkCollision(
      ballX,
      ballY,
      blackBallX,
      blackBallY,
      ballSize,
      blackBallRadius
    );

    ctx.beginPath();
    ctx.arc(blackBallX, blackBallY, blackBallRadius, 0, 2 * Math.PI);
    ctx.fillStyle = "black";
    // ctx.fill();
    ctx.stroke();
  }, [
    ballSize,
    ballX,
    ballY,
    xwidth,
    yheight,
    canvasWidth,
    canvasHeight,
    rotation,
    distance,
    blackBallRadius,
    checkCollision,
  ]);

  useEffect(() => {
    setBallX(
      ((deviceinfo[0]?.mt / xwidth) * canvasWidth) / 2 + canvasWidth / 2
    );
    setBallY(
      ((deviceinfo[0]?.ap / yheight) * canvasHeight) / 2 + canvasHeight / 2
    );
  }, [deviceinfo, canvasHeight, canvasWidth, xwidth, yheight]);

  return (
    <div className="App">
      <div className="row m-0 p-0">
        <div className=" m-0 p-0 col">
          <div className="fs-3">{Math.floor(sustain)}</div>
          <div>
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => {
                setSustain((prev) => {
                  if (prev < 2000) return prev + 100;
                  else return 2000;
                });
              }}
            >
              +
            </button>
            <span className="mx-2">Sustain</span>
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => {
                setSustain((prev) => {
                  if (prev > 100) return prev - 100;
                  else return 100;
                });
              }}
            >
              -
            </button>
          </div>
        </div>
        <div className=" m-0 p-0 col">
          {" "}
          <h1>Interactive Canvas</h1>
          <button
            type="button"
            class="btn btn-outline-primary"
            onClick={() => {
              tare(0);
            }}
          >
            Tare
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            onClick={() => {
              mode2();
            }}
          >
            Mode 2
          </button>
        </div>
        <div className=" m-0 p-0 col">
          <div className="fs-3">{precision}</div>
          <div>
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => {
                setPrecision((prev) => {
                  if (prev < 10) return prev + 1;
                  else return 10;
                });
              }}
            >
              +
            </button>
            <span className="mx-2">Precision</span>
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => {
                setPrecision((prev) => {
                  if (prev > 2) return prev - 1;
                  else return 2;
                });
              }}
            >
              -
            </button>
          </div>
        </div>
      </div>

      <div className="canvas-container">
        <canvas
          ref={arcCanvasRef}
          width="500"
          height="500"
          onClick={handleCanvasClick}
        />
        <canvas
          ref={ballCanvasRef}
          width={canvasHeight}
          height={canvasHeight}
        />
      </div>

      <div>
        <div className="d-inline">ballx={ballX} &nbsp; &nbsp; &nbsp;</div>
        <div className="d-inline">bally={ballY}</div>
        <h2>Selected Bodies (Red): {JSON.stringify(selectedBodies)}</h2>
      </div>
    </div>
  );
}
