import { configureStore } from "@reduxjs/toolkit";
import devicedataSlice from "./deviceinfo";
import patientsSlice from "./patientsinfo";
import itemNameSlice from "./itemname";
import sequenceClickSlice from "./squenceclick";
import curretViewSlice from "./currentView";
import bleDevicesSlice from "./bleDevices";

const deviceStore = configureStore({
  reducer: {
    bleDevices: bleDevicesSlice.reducer,
    sequenceClick: sequenceClickSlice.reducer,
    patientsinfo: patientsSlice.reducer,
    itemName: itemNameSlice.reducer,
    devicedata: devicedataSlice.reducer,
    currentView: curretViewSlice.reducer,
  },
});

export default deviceStore;
