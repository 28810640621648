import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { useSelector } from "react-redux";

export default function ScatterPlot() {
  let { deviceinfo } = useSelector((store) => store.devicedata);
  let [plotValue, setPlotValue] = useState({
    ap: [],
    mt: [],
  });

  useEffect(() => {
    if (deviceinfo[0]?.mode === 2) {
      setPlotValue((prev) => ({
        ap: [...prev.ap, -1 * deviceinfo[0].ap],
        mt: [...prev.mt, deviceinfo[0].mt],
      }));
    }
  }, [deviceinfo]);

  const trace1 = {
    x: plotValue.mt,
    y: plotValue.ap,
    mode: "markers+lines", // Use "markers+lines" mode for both markers and lines
    type: "scatter",
    marker: {
      color: "yellow", // Sets the color of the data points
    },
  };

  return (
    <div className="row bg-white m-0 px-0 py-5">
      <div className="col m-0 p-0 d-flex justify-content-center align-items-center">
        <div>
          <div>
            <h1>Easy</h1>
            <h5>Level</h5>
          </div>
          <div>
            <h1>2:59</h1>
            <h5>Test Duration</h5>
          </div>
          <div>
            <h1>68%</h1>
            <h5>Percentage</h5>
          </div>
        </div>
      </div>
      <div className="col m-0 p-0 border border-2 border-primary d-flex justify-content-center align-items-center  position-relative">
        <div
          className="rounded-circle overflow-hidden "
          style={{ width: "80vh", height: "80vh" }}
        >
          <Plot
            data={[trace1]}
            layout={{
              xaxis: {
                visible: false, // Ensure x-axis is visible
                showticklabels: false, // Show tick labels on x-axis
                showgrid: false, // Show grid lines on x-axis
                title: "X Axis", // X-axis title
                autorange: false, // Disable autoranging
                range: [-20, 20], // Set range for x-axis
                fixedrange: true, // Fix range of x-axis
              },
              yaxis: {
                visible: false, // Ensure y-axis is visible
                showticklabels: false, // Show tick labels on y-axis
                showgrid: false, // Show grid lines on y-axis
                title: "Y Axis", // Y-axis title
                autorange: false, // Disable autoranging
                range: [-20, 20], // Set range for y-axis
                fixedrange: true, // Fix range of y-axis
              },
              aspectratio: { x: 1, y: 1 }, // Ensures equal aspect ratio
              plot_bgcolor: "green", // Sets the background color of the plotting area
              autosize: true,
              margin: { t: 0, b: 0, l: 0, r: 0 }, // Add margins for better layout
            }}
            config={{ displayModeBar: false, useResizeHandler: true }}
            style={{ width: "100%", height: "100%" }}
          />

          <div className="position-absolute h-50 w-50 bg-white top-50 start-50 translate-middle rounded-circle overflow-hidden">
           
          </div>
        </div>
      </div>
      <div className="col m-0 p-0 d-flex justify-content-center align-items-center">
        <div>
          <div>
            <h1>30%</h1>
            <h5>Anterior</h5>
          </div>
          <div>
            <h1>10%</h1>
            <h5>Posterior</h5>
          </div>
          <div>
            <h1>30%</h1>
            <h5>Left</h5>
          </div>
          <div>
            <h1>30%</h1>
            <h5>Right</h5>
          </div>
        </div>
      </div>
    </div>
  );
}
