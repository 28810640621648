import React from "react";
import { sequenceClickActions } from "../store/squenceclick";
import { useDispatch } from "react-redux";
import { mode11, } from "../Pages/Connect/BluetoothHandler";

function BlockItem({ imageSrc, altText, textContent, onClick }) {
  return (
    <div className="container-fluid mt-1 pt-2" style={{ cursor: "pointer" }}>
      <div
        className="position-relative zoom-effect"
        style={{ margin: "0 2vw" }}
        onClick={onClick}
      >
        <img
          src={imageSrc}
          alt={altText}
          className="ratio rounded-4"
          style={{ "--bs-aspect-ratio": "calc(1.618 * 100%)" }}
        />
        <div className="position-absolute bottom-0 container-fluid bg-red rounded-bottom-4 pt-3">
          <p className="text-center text-white fw-semibold fs-5 zoom-effect">
            {textContent}
          </p>
        </div>
      </div>
    </div>
  );
}

function Block({ setCurrentView }) {
  const dispatch = useDispatch();
  return (
    <div className="d-flex justify-content-around">
      <BlockItem
        imageSrc={require("../Images/assessment.png")}
        altText="Assessment"
        textContent="Assessment"
        onClick={() => {
          setCurrentView("SelectionTabsAssessment");
          dispatch(
            sequenceClickActions.addSequenceClick({
              value: { Activity: "Assessment" },
            })
          );
        }}
      />
      <BlockItem
        imageSrc={require("../Images/workout.png")}
        altText="Workout"
        textContent="Workout"
        onClick={() => {
          setCurrentView("SelectionTabsWorkout");
          dispatch(
            sequenceClickActions.addSequenceClick({
              value: { Activity: "Workout" },
            })
          );
        }}
      />
      <BlockItem
        imageSrc={require("../Images/counter.png")}
        altText="Counter"
        textContent="Counter"
        onClick={() => {
          setCurrentView("SelectionTabsCounter");
          dispatch(
            sequenceClickActions.addSequenceClick({
              value: { Activity: "Counter" },
            })
          );
        }}
      />
      <BlockItem
        imageSrc={require("../Images/progress.png")}
        altText="Progress"
        textContent="Progress"
        onClick={() => {
          setCurrentView("Progress");
          mode11();
          dispatch(
            sequenceClickActions.addSequenceClick({
              value: { Activity: "Progress" },
            })
          );
        }}
      />
    </div>
  );
}

export default Block;
