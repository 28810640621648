import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { tare } from "../../../../Connect/BluetoothHandler";

export default function BolbGame() {
  let canvasRef = useRef(null);
  let { deviceinfo } = useSelector((store) => store.devicedata);
  let [sps, setSps] = useState(0);
  let [spm, setSpm] = useState(sps);
  let [gameRunning, setGamerunning] = useState(false);
  let [hit, setHit] = useState(0);
  let [miss, setMiss] = useState(0);
  let [level, setLevel] = useState(1);
  let [newBall, setNewBall] = useState(null);
  let [collectibleBalls, setCollectibleBalls] = useState([]);
  let [collectionStartTime, setCollectionStartTime] = useState(null);
  let [collectionEndTime, setCollectionEndTime] = useState(null);

  let redBall = useRef({
    x: 300,
    y: 300,
    radius: 30,
    ballVelocityX: 1,
    ballVelocityY: 0,
  });

  let blackBall = useRef({
    x: 200,
    y: 300,
    radius: 30,
    ballVelocityX: 2, // Increase velocity
    ballVelocityY: 2, // Increase velocity
  });

  let newBallVelocity = useRef({
    ballVelocityX: 2, // Increase velocity
    ballVelocityY: 3, // Different velocity for varied movement
  });
  let moveRedBall = (roll, pitch, canvas) => {
    if (roll > 15) roll = 15;
    if (pitch > 15) pitch = 15;
    if (roll < -15) roll = -15;
    if (pitch < -15) pitch = -15;

    const xRange = 18; // Maximum absolute value of roll
    const yRange = 18; // Maximum absolute value of pitch
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;

    // Calculate target x and y coordinates
    let targetX = canvasWidth / 2 + (roll / xRange) * (canvasWidth / 2);
    let targetY = canvasHeight / 2 + (pitch / yRange) * (canvasHeight / 2);

    // Define a small step value for smoother movement
    const step = 2;

    // Update red ball position gradually towards target position
    if (Math.abs(redBall.current.x - targetX) > step) {
      redBall.current.x += (targetX - redBall.current.x) / step;
    } else {
      redBall.current.x = targetX;
    }

    if (Math.abs(redBall.current.y - targetY) > step) {
      redBall.current.y += (targetY - redBall.current.y) / step;
    } else {
      redBall.current.y = targetY;
    }
  };
  const resetHitAndMiss = () => {
    setHit(0);
    setMiss(0);
  };

  let regenerateBalls = (canvas) => {
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const radius = blackBall.current.radius;
    const minDistance = 100; // Minimum distance between balls
    let angle, distanceFromCenter, x, y;

    // Generate a random position for the black ball
    angle = Math.random() * Math.PI * 2;
    distanceFromCenter = Math.random() * (canvasHeight / 4 - radius) + radius;
    blackBall.current.x = canvasWidth / 2 + Math.cos(angle) * distanceFromCenter;
    blackBall.current.y = canvasHeight / 2 + Math.sin(angle) * distanceFromCenter;

    // Regenerate the new ball if it exists
    if (newBall) {
      angle = Math.random() * Math.PI * 2;
      distanceFromCenter = Math.random() * (canvasHeight / 4 - radius) + radius;

      // Calculate the new position of the new ball close to the black ball
      x = blackBall.current.x + Math.cos(angle) * distanceFromCenter;
      y = blackBall.current.y + Math.sin(angle) * distanceFromCenter;

      // Ensure the new ball is within the canvas bounds
      x = Math.max(radius, Math.min(canvasWidth - radius, x));
      y = Math.max(radius, Math.min(canvasHeight - radius, y));

      newBall.x = x;
      newBall.y = y;
      setNewBall({ ...newBall });
    }
  };

  let checkCollision = (redBall, blackBall, canvas) => {
    let distance = Math.sqrt((redBall.x - blackBall.x) ** 2 + (redBall.y - blackBall.y) ** 2);
    if (distance < redBall.radius + blackBall.radius) {
      setHit((prevscore) => prevscore + 1);
      regenerateBalls(canvas);
    }
  };

  let checkNewBallCollision = (redBall, newBall, canvas) => {
    let distance = Math.sqrt((redBall.x - newBall.x) ** 2 + (redBall.y - newBall.y) ** 2);
    if (distance < redBall.radius + newBall.radius) {
      setMiss((prevMiss) => prevMiss + 1);
      regenerateBalls(canvas);
    }
  };

  let checkCollectibleBallCollision = (redBall, balls, canvas) => {
    let collected = 0;
    const updatedBalls = balls.map((ball) => {
      let distance = Math.sqrt((redBall.x - ball.x) ** 2 + (redBall.y - ball.y) ** 2);
      if (distance < redBall.radius + ball.radius) {
        collected += 1;
        return null; // Remove the ball
      }
      return ball;
    });

    if (collected > 0) {
      setCollectibleBalls(updatedBalls.filter((ball) => ball !== null));
      if (updatedBalls.filter((ball) => ball !== null).length === 0) {
        setCollectionEndTime(new Date().getTime());
        setTimeout(() => regenerateCollectibleBalls(canvas), 2000);
      }
    }
  };

  let regenerateCollectibleBalls = (canvas) => {
    const balls = [];
    const radius = 15; // Smaller radius for green balls
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const minDistance = 100; // Minimum distance between balls

    for (let i = 0; i < 5; i++) {
      let x, y, tooClose;
      do {
        tooClose = false;
        let angle = Math.random() * Math.PI * 2;
        let distanceFromCenter = Math.random() * (canvasHeight / 2 - radius) + radius;
        x = canvasWidth / 2 + Math.cos(angle) * distanceFromCenter;
        y = canvasHeight / 2 + Math.sin(angle) * distanceFromCenter;

        for (let ball of balls) {
          let distance = Math.sqrt((x - ball.x) ** 2 + (y - ball.y) ** 2);
          if (distance < minDistance) {
            tooClose = true;
            break;
          }
        }
      } while (tooClose);

      balls.push({
        x: x,
        y: y,
        radius: radius,
      });
    }

    setCollectibleBalls(balls);
    setCollectionStartTime(new Date().getTime());
  };

  const drawBall = (ctx, canvas) => {
    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw red ball
    ctx.beginPath();
    ctx.arc(redBall.current.x, redBall.current.y, redBall.current.radius, 0, Math.PI * 2);
    ctx.fillStyle = "#ff7223";
    ctx.fill();
    ctx.closePath();

    // Draw black ball if not in level 4
    if (level !== 4) {
      ctx.beginPath();
      ctx.arc(blackBall.current.x, blackBall.current.y, blackBall.current.radius, 0, Math.PI * 2);
      ctx.fillStyle = "black";
      ctx.fill();
      ctx.closePath();
    }

    // Draw new ball if it exists and not in level 4
    if (newBall && level !== 4) {
      ctx.beginPath();
      ctx.arc(newBall.x, newBall.y, newBall.radius, 0, Math.PI * 2);
      ctx.fillStyle = "blue";
      ctx.fill();
      ctx.closePath();
    }

    // Draw collectible balls for level 4
    if (level === 4) {
      collectibleBalls.forEach((ball) => {
        ctx.beginPath();
        ctx.arc(ball.x, ball.y, ball.radius, 0, Math.PI * 2);
        ctx.fillStyle = "green";
        ctx.fill();
        ctx.closePath();
      });
    }
  };

  const moveBalls = (canvas) => {
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;
    const canvasRadius = canvasWidth / 2;

    // Move black ball
    blackBall.current.x += blackBall.current.ballVelocityX;
    blackBall.current.y += blackBall.current.ballVelocityY;

    // Check if the black ball is outside the circular boundary
    let distanceFromCenter = Math.sqrt((blackBall.current.x - centerX) ** 2 + (blackBall.current.y - centerY) ** 2);

    if (distanceFromCenter + blackBall.current.radius > canvasRadius) {
      // Reflect the velocity to bounce back within the circular boundary
      blackBall.current.ballVelocityX *= -1;
      blackBall.current.ballVelocityY *= -1;
    }

    // Repeat similar logic for other balls
    if (newBall) {
      newBall.x += newBallVelocity.current.ballVelocityX;
      newBall.y += newBallVelocity.current.ballVelocityY;

      distanceFromCenter = Math.sqrt((newBall.x - centerX) ** 2 + (newBall.y - centerY) ** 2);

      if (distanceFromCenter + newBall.radius > canvasRadius) {
        newBallVelocity.current.ballVelocityX *= -1;
        newBallVelocity.current.ballVelocityY *= -1;
      }
    }
  };
  useEffect(() => {
    let intervalId = setInterval(() => {
      if (gameRunning) {
        setSps((prevSps) => (prevSps + 1) % 60);
        if (sps === 59) {
          setSpm((prevSpm) => prevSpm + 1);
        }
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [gameRunning, sps]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const gameLoop = () => {
      moveRedBall(deviceinfo[0].pitch, deviceinfo[0].roll, canvas);
      if (level !== 4) {
        checkCollision(redBall.current, blackBall.current, canvas);
        if (newBall) {
          checkNewBallCollision(redBall.current, newBall, canvas);
        }
      } else {
        checkCollectibleBallCollision(redBall.current, collectibleBalls, canvas);
      }
      if (level === 3) {
        moveBalls(canvas);
      }
      drawBall(ctx, canvas);
    };
    const intervalId = setInterval(gameLoop, 1000 / 60); // 30 fps

    return () => clearInterval(intervalId);
  }, [
    deviceinfo,
    newBall,
    level,
    collectibleBalls,
    checkCollectibleBallCollision,
    checkCollision,
    checkNewBallCollision,
    drawBall,
    moveBalls,
  ]); // Dependency array includes newBall, level, and collectibleBalls

  const handleLevelChange = (newLevel) => {
    resetHitAndMiss(); // Reset hit and miss to 0

    setLevel(newLevel);
    if (newLevel === 2 || newLevel === 3) {
      const canvas = canvasRef.current;
      const radius = 30;
      const minDistanceFromBlackBall = 50; // Minimum distance from the black ball
      const maxDistanceFromBlackBall = 100; // Maximum distance from the black ball

      let angle = Math.random() * Math.PI * 2;
      let distanceFromBlackBall =
        Math.random() * (maxDistanceFromBlackBall - minDistanceFromBlackBall) + minDistanceFromBlackBall;

      setNewBall({
        x: blackBall.current.x + Math.cos(angle) * distanceFromBlackBall,
        y: blackBall.current.y + Math.sin(angle) * distanceFromBlackBall,
        radius: radius,
      });
    } else if (newLevel === 4) {
      const canvas = canvasRef.current;
      regenerateCollectibleBalls(canvas);
      setCollectionEndTime(null);
    } else {
      setNewBall(null);
      setCollectibleBalls([]);
      setCollectionStartTime(null);
      setCollectionEndTime(null);
    }
  };

  const calculateCollectionTime = () => {
    if (collectionStartTime && collectionEndTime) {
      const timeInSeconds = (collectionEndTime - collectionStartTime) / 1000;
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.floor(timeInSeconds % 60);
      return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
    }
    return "00:00";
  };

  return (
    <div className="mt-5 pt-5 px-5 container-fluid  vh-100" style={{ backgroundColor: "#4C356B" }}>
      <div className="row">
        <div className="col" style={{ height: "90vh" }}>
          <div className="row d-flex h-100 justify-content-center align-items-center">
            <p className="fw-semibold lh-lg" id="timerDisplay" style={{ color: "white", fontSize: "4vw" }}>
              Time:
              {(spm < 10 ? "0" + spm : spm) + ":" + (sps < 10 ? "0" + sps : sps)}
            </p>
            <p className="fw-semibold lh-lg" style={{ color: "white", fontSize: "4vw" }}>
              Hit:{hit < 10 ? "0" + hit : hit}
            </p>

            <p className="fw-semibold lh-lg" id="missDisplay" style={{ color: "white", fontSize: "4vw" }}>
              Miss:{miss < 10 ? "0" + miss : miss}
            </p>
            <p className="fw-semibold lh-lg" style={{ color: "white", fontSize: "4vw" }}>
              {level === 4 ? "Collection Time: " + calculateCollectionTime() : "Percentage"}
            </p>
            <div className="d-flex row m-0 p-0">
              <div className="col-8">
                <button
                  className="btn rounded-pill text-dark w-100 fw-semibold"
                  style={{ fontSize: "3vw", backgroundColor: "white" }}
                  id="startButton"
                  onClick={() => setGamerunning(true)}
                >
                  Start
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => {
                    tare(0);
                  }}
                >
                  Tare
                </button>
                <button
                  className="btn d-none rounded-pill text-white w-100 fw-semibold"
                  style={{ fontSize: "3vw", backgroundColor: "white" }}
                  id="stopButton"
                  onClick={() => setGamerunning(false)}
                >
                  Stop
                </button>
              </div>
              <div className="col-4">
                <button
                  className="btn rounded-circle px-4 py-2 text-dark fw-semibold"
                  style={{ fontSize: "3vw", backgroundColor: "white" }}
                  id="resetButton"
                  onClick={() => {
                    setGamerunning(false);
                    setSps(0);
                    setSpm(0);
                    setHit(0);
                    setMiss(0);
                    setNewBall(null);
                    setCollectibleBalls([]);
                    setCollectionStartTime(null);
                    setCollectionEndTime(null);
                    redBall.current = {
                      x: 300,
                      y: 300,
                      radius: 30,
                      ballVelocityX: 1,
                      ballVelocityY: 0,
                    };
                    blackBall.current = {
                      x: 200,
                      y: 300,
                      radius: 30,
                      ballVelocityX: 2,
                      ballVelocityY: 2,
                    };
                  }}
                >
                  ↺
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "85vh",
            width: "85vh",
            position: "relative",
            boxShadow: "inset 8px 8px 15px rgba(0, 0, 0, 0.15), inset -8px -8px 15px rgba(255, 255, 255, 0.7)",
            borderRadius: "50%",
            backgroundColor: "#f0f0f3",
          }}
        >
          <canvas
            ref={canvasRef}
            width={window.innerHeight - (window.innerHeight * 15) / 100}
            height={window.innerHeight - (window.innerHeight * 15) / 100}
            style={{
              border: "2px solid white",
              borderRadius: "100%",
              backgroundColor: "white",
            }}
          ></canvas>
        </div>

        <div className="col" style={{ height: "90vh", minWidth: "10%", maxWidth: "30%" }}>
          <div className="d-flex flex-column w-100 justify-content-evenly align-items-center h-100 px-2">
            {[...Array(10)].map((_, index) => (
              <div
                key={index}
                className="p-2 w-50 rounded-pill text-center text-dark fw-semibold level-button"
                style={{ fontSize: "1.5vw", backgroundColor: "white" }}
                data-level={index + 1}
                onClick={() => handleLevelChange(index + 1)}
              >
                Level {index + 1}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
