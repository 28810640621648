import React, { useState, useEffect } from "react";
import axios from "axios";

export default function PlayerData({ playerData: { patientName, jointName } }) {
  const [currentTime, setCurrentTime] = useState(null);

  useEffect(() => {
    // Function to fetch current time
    const fetchCurrentTime = async () => {
      try {
        const response = await axios.get("http://worldtimeapi.org/api/timezone/Asia/Kolkata");
        // Set the fetched time to state
        setCurrentTime(response.data.datetime);
      } catch (error) {
        console.error("Error fetching the current time:", error);
      }
    };

    // Call the function to fetch time
    fetchCurrentTime();
  }, []);

  // Define the date and time formatting functions
  const formatDate = (datetime) => {
    const date = new Date(datetime);
    return date.toLocaleDateString(); // Format as 'MM/DD/YYYY'
  };

  const formatTime = (datetime) => {
    const date = new Date(datetime);
    return date.toLocaleTimeString(); // Format as 'HH:MM:SS'
  };

  return (
    <div className="mx-5 px-5 bg-white rounded-2">
      <div className="row">
        <div className="col-9">
          <p className="text-red fw-semibold fs-4 pt-3">
            {patientName} - {jointName}
          </p>
        </div>

        <div className="col-3">
          <p className="text-red text-end fw-semibold fs-4 pt-3">
            {currentTime ? (
              <>
                {formatDate(currentTime)}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {formatTime(currentTime)}
              </>
            ) : (
              "Loading..."
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
