import React, { useState, useEffect } from "react";
import "./Slider.css";

export default function Slider({ onSliderChange, holdTime, count, precision }) {
  return (
    <div className="border border1-1 border-danger px-5 mb-5">
      <div className="row m-0 p-0 bg-white rounded-4" style={{ height: "100vh" }}>
        <RangeInput
          onSliderChange={onSliderChange}
          sliderId="count"
          name="Count"
          min="1"
          max="10"
          value={count} // Pass initial count here
        />
        <RangeInput
          onSliderChange={onSliderChange}
          sliderId="precision"
          name="Precision"
          min="0"
          max="100"
          value={precision} // Pass initial precision here
        />
        <RangeInput
          onSliderChange={onSliderChange}
          sliderId="holdDuration"
          name="Hold Duration"
          min="1"
          max="10"
          value={holdTime} // Pass initial holdTime here
        />
      </div>
    </div>
  );
}

const RangeInput = ({ onSliderChange, sliderId, name, min, max, value }) => {
  const [sliderValue, setSliderValue] = useState(value); // Initialize with passed prop value

  useEffect(() => {
    setSliderValue(value); // Update slider when the prop value changes
  }, [value]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    onSliderChange(sliderId, newValue);
    setSliderValue(newValue); // Update slider's internal state
  };

  return (
    <div className="col-6 p-0 d-flex align-items-center justify-content-center">
      <div className="h-75 w-75 rounded-4 px-3">
        <p className="text-center fs-1 fw-bold text-red">{name}</p>
        <div className="h-25 d-flex align-items-center justify-content-center">
          <input
            type="range"
            className="form-range custom-thumb position-relative"
            min={min}
            max={max}
            value={sliderValue} // Use state value here
            onChange={handleChange}
          />
        </div>
        <div className="h-25 d-flex align-items-center justify-content-center fs-1 fw-bold text-red">
          <p>{sliderValue}</p> {/* Display the current value */}
        </div>
      </div>
    </div>
  );
};
