import React, { useEffect, useState } from "react";
import axios from "axios"; 
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock, 
  faEye,
  faEyeSlash, 
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

const Login = () => {
  const [email, setEmail] = useState(""); 
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkingAuth, setCheckingAuth] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const token = document.cookie.split("; ").find((row) => row.startsWith("token="));
      if (token) {
        window.location.href = "/Connect";
      } else {
        setCheckingAuth(false);  
      }
    };
    checkAuth(); 
  }, []);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {

      const response = await axios.post(
        "https://go.raycura.com:5000/api/auth/login",
        { email, password }
      );
      if (response.data.token) {
        document.cookie = `token=${response.data.token}; path=/; max-age=${30 * 24 * 60 * 60}`;
        window.location.href = "/Connect";
      }
    } catch (error) {
      console.error("Login error:", error);
      const errorMessage =
        error.response?.data?.message || "Login failed. Please try again.";
      alert(errorMessage);
    }finally {
      setLoading(false);
    }
  };

  if (checkingAuth) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    ); 
  }

  return (
    <div className="vw-100 vh-100 position-relative">
      <div>
        <img className="vh-100 vw-100" src="Picture5.png" alt="img" />
      </div>
      <div
        className="position-absolute"
        style={{
          background: "rgb(221, 220, 213)",
          borderRadius: "2vw",
          boxShadow:
            "0.7vw 0.7vw 1.5vw rgba(20, 20, 20, 0.3), -0.7vw -0.7vw 1.5vw rgba(255, 255, 255, 0.9)",
          padding: "2.5vw",
          width: "30vw",
          maxWidth: "45vw",
          transition: "box-shadow 0.3s ease",
          top: "10%",
          right: "10%",
        }}
      >
        <style>
          {`
            .clickable:active {
              box-shadow: inset 0.7vw 0.7vw 1.5vw #bebebe, inset -0.7vw -0.7vw 1.5vw #ffffff !important;
            }
          `}
        </style>
        <h2
          className="mb-4 text-center fw-bolder"
          style={{ color: "rgb(223, 112, 34)", fontSize: "2vw" }}
        >
          Login
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3 position-relative">
            <FontAwesomeIcon
              icon={faEnvelope}
              style={{
                position: "absolute",
                top: "50%",
                left: "1.5vw",
                transform: "translateY(-50%)",
                fontSize: "1.5vw",
              }}
            />
            <input
              type="email"
              className="form-control ps-5 fs-5"
              placeholder="Email"
              required
              style={{
                background: "rgb(221, 220, 213)",
                border: "none",
                borderRadius: "1vw",
                boxShadow:
                  "inset 0.7vw 0.7vw 1.5vw #bebebe, inset -0.7vw -0.7vw 1.5vw #ffffff",
                padding: "1vw 2vw",
                outline: "none",
                width: "100%",
                marginBottom: "2vw",
                fontSize: "1vw",
              }}
              aria-label="Email"
              pattern=".+@.+\..+"
              title="Email should contain '@' and a domain name."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-3 position-relative">
            <FontAwesomeIcon
              icon={faLock}
              style={{
                position: "absolute",
                top: "50%",
                left: "1.5vw",
                transform: "translateY(-50%)",
                fontSize: "1.5vw",
              }}
            />
            <input
              type={passwordVisible ? "text" : "password"}
              className="form-control ps-5 fs-5"
              placeholder="Password"
              required
              style={{
                background: "rgb(221, 220, 213)",
                border: "none",
                borderRadius: "1vw",
                boxShadow:
                  "inset 0.7vw 0.7vw 1.5vw #bebebe, inset -0.7vw -0.7vw 1.5vw #ffffff",
                padding: "1vw 2vw",
                outline: "none",
                width: "100%",
                marginBottom: "2vw",
                fontSize: "1vw",
              }}
              aria-label="Password"
              minLength="6"
              title="Password must be at least 6 characters."
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FontAwesomeIcon
              icon={passwordVisible ? faEyeSlash : faEye}
              style={{
                position: "absolute",
                top: "50%",
                left: "auto",
                right: "1.5vw",
                transform: "translateY(-50%)",
                cursor: "pointer",
                fontSize: "1.5vw",
              }}
              onClick={togglePasswordVisibility}
            />
          </div>
          <div className="mb-3 d-flex justify-content-between">
            <Link
              to="/reset-password"
              className="fw-bolder mb-0"
              style={{
                color: "rgb(223, 112, 34)",
                fontSize: "1vw",
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              Forgot Password
            </Link>
          </div>
          <button
            type="submit"
            className="btn w-100 mb-3 fs-5 clickable"
            style={{
              background: "rgb(221, 220, 213)",
              border: "none",
              borderRadius: "1vw",
              padding: "1vw 2vw",
              outline: "none",
              width: "100%",
              marginBottom: "2vw",
              color: "rgb(26, 26, 26)",
              fontWeight: "bold",
              transition: "box-shadow 0.3s ease, transform 0.3s ease",
              boxShadow:
                "0.7vw 0.7vw 1.5vw #bebebe, -0.7vw -0.7vw 1.5vw #ffffff",
              fontSize: "1vw",
            }}
          >
            Login
          </button>
          <p className="text-center mb-3" style={{ fontSize: "1vw" }}>
            Or
          </p>
          <button
            type="button"
            className="btn w-100 clickable fs-5"
            style={{
              background: "rgb(221, 220, 213)",
              border: "none",
              borderRadius: "1vw",
              padding: "1vw 2vw",
              outline: "none",
              width: "100%",
              marginBottom: "2vw",
              color: "rgb(26, 26, 26)",
              fontWeight: "bold",
              transition: "box-shadow 0.3s ease, transform 0.3s ease",
              boxShadow:
                "0.7vw 0.7vw 1.5vw #bebebe, -0.7vw -0.7vw 1.5vw #ffffff",
              fontSize: "1vw",
            }}
          >
            <FontAwesomeIcon icon={faGoogle} className="me-2" />
            Sign in with Google
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
