import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CountingReps from "./CounterGame/CountingReps/CountingReps";
import SelectionTabsCounter from "./SelectionTabsCounter";
import { currentViewActions } from "../../../store/currentView";
import AppProgressBar from "../AppProgressBar";
import NewSphere from "./CounterGame/NewSphere/NewSphere";
import MainCylinder from "./CounterGame/Cylinder/Cylinder";
import TwoCylinder from "./CounterGame/TwoCylinder/TwoCylinder";

export default function Counter() {
  const dispatch = useDispatch();
  const { currentView } = useSelector((store) => store.currentView); // Access the currentView from Redux store

  let [v, setv] = useState();

  useEffect(() => {
    const setCurrentView = (view) => {
      dispatch(currentViewActions.addView({ value: view })); // Dispatch the action to update the currentView
    };
    if (currentView === "SelectionTabsCounter") {
      setv(<SelectionTabsCounter setCurrentView={setCurrentView} />);
    } else if (currentView === "CountingReps") {
      setv(<CountingReps />);
    } else if (currentView === "MainCylinder") {
      setv(<MainCylinder/>);
    } else if (currentView === "NewSphere") {
      setv(<NewSphere />);
    }else if (currentView === "TwoCylinder") {
      setv(<TwoCylinder />);
    }
  }, [currentView, dispatch]);
  return (
    <>
      <AppProgressBar />
      <div>{v}</div>
    </>
  );
}
