import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { sequenceClickActions } from "../../store/squenceclick";
import { currentViewActions } from "../../store/currentView";

export default function AppProgressBar() {
  let { sequence } = useSelector((store) => store.sequenceClick);
  let { currentView } = useSelector((store) => store.currentView);
  const dispatch = useDispatch();
  const setCurrentView = (view) => {
    dispatch(currentViewActions.addView({ value: view })); // Dispatch the action to update the currentView
  };

  // Function to find the index of the object with the "Patient" key
  const findPatientIndex = ([...sequence]) => {
    return sequence.findIndex((item) => item.hasOwnProperty("Patient"));
  };

  // Find the index of the object with the "Patient" key
  const patientIndex = findPatientIndex(sequence);

  // If the index is found, slice the array from that index onwards
  const limitedSequence =
    patientIndex !== -1 ? sequence.slice(patientIndex, patientIndex + 3) : [];
  // Limit to first 4 values
  return (
    <div className="pt-3 px-5">
      <div className="row  border-2 border-red bg-white  rounded-end-pill mx-1 ">
        {limitedSequence.map((objectValue, index) => {
          let objKey = Object.keys(objectValue)[0];
          if (objKey !== "DoctorName") {
            return (
              <div
                className="col d-flex flex-column justify-content-center align-items-center  bg-white border-right-red text-red py-2  rounded-end-pill"
                onClick={() => {
                  dispatch(
                    sequenceClickActions.removeSequenceClick({
                      value: index + 1,
                    })
                  );
                  if (objKey === "Patient") {
                    setCurrentView("DoctorAddPatients");
                  } else if (objKey === "Activity") {
                    setCurrentView("Activity");
                  } else if (
                    ["SelectionTabsCounter", "Counter"].includes(currentView)
                  ) {
                    setCurrentView("SelectionTabsCounter");
                  } else if (
                    [
                      "SelectionTabsAssesment",
                      "Assesment",
                      "BalanceAssesment",
                    ].includes(currentView)
                  ) {
                    setCurrentView("SelectionTabsAssesment");
                  } else if (
                    [
                      "SelectionTabsWorkout",
                      "Workout",
                      "Game",
                      "BolbGame",
                      "FlappyBird",
                      "NewBolb",
                      "Sphere",
                    ].includes(currentView)
                  ) {
                    setCurrentView("SelectionTabsWorkout");
                  }
                }}
                key={index}
                style={{ cursor: "pointer" }}
              >
                <ul className="list-group list-group-horizontal list-unstyled">
                  <li className="fs-5 fw-bold">
                    {Object.keys(objectValue)} <span>-</span>
                  </li>
                  <li className=" px-1 fs-5 ">{Object.values(objectValue)}</li>
                </ul>
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
}
