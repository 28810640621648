// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../Images/FlappyBackground.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.canvas-bg {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Application/Workout/Game/FlappyBird/FlappyBird.css"],"names":[],"mappings":"AAAA;IACI,oEAA8E;IAC9E,iCAAiC;IACjC,sCAAsC;IACtC,uCAAuC;AAC3C","sourcesContent":[".canvas-bg {\r\n    background-image: url(\"../../../../../Images/FlappyBackground.jpg\") !important;\r\n    background-size: cover !important;\r\n    background-position: center !important;\r\n    background-repeat: no-repeat !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
