// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --valueHeight: 10vh;
    --valueWidth: var(--valueHeight);
    --light-red: #ffbdbb;
}

.custom-thumb::-webkit-slider-thumb {

    height: var(--valueHeight);
    width: var(--valueWidth);
    border-radius: 50%;
    background-color: white;
    /* Adjust slightly outwards to cover border */
    position: relative;

    top: calc(50% - (var(--valueHeight)/2));
    border: 10px solid red;
    /* Slightly thicker border */
}



.custom-thumb::-webkit-slider-runnable-track {
    height: 3.5vh;
    background-color: red;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Application/Counter/CounterGame/TwoCylinder/Slider.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gCAAgC;IAChC,oBAAoB;AACxB;;AAEA;;IAEI,0BAA0B;IAC1B,wBAAwB;IACxB,kBAAkB;IAClB,uBAAuB;IACvB,6CAA6C;IAC7C,kBAAkB;;IAElB,uCAAuC;IACvC,sBAAsB;IACtB,4BAA4B;AAChC;;;;AAIA;IACI,aAAa;IACb,qBAAqB;AACzB","sourcesContent":[":root {\r\n    --valueHeight: 10vh;\r\n    --valueWidth: var(--valueHeight);\r\n    --light-red: #ffbdbb;\r\n}\r\n\r\n.custom-thumb::-webkit-slider-thumb {\r\n\r\n    height: var(--valueHeight);\r\n    width: var(--valueWidth);\r\n    border-radius: 50%;\r\n    background-color: white;\r\n    /* Adjust slightly outwards to cover border */\r\n    position: relative;\r\n\r\n    top: calc(50% - (var(--valueHeight)/2));\r\n    border: 10px solid red;\r\n    /* Slightly thicker border */\r\n}\r\n\r\n\r\n\r\n.custom-thumb::-webkit-slider-runnable-track {\r\n    height: 3.5vh;\r\n    background-color: red;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
