export let bodypartstabs = {
  ...{
    "Upper Extremity": {
      "Hand Flexion": "rename.png",
      "Neck Flexion": "rename.png",
      "Leg Flexion": "rename.png",
      "Shoulder Flexion": "rename.png",
      "Elbow Flexion": "rename.png",
      "Wrist Flexion": "rename.png",
      "Forearm Flexion": "rename.png",
      "Arm Flexion": "rename.png",
    },
  },
  ...{
    "Lower Extremity": {
      "Ankle Flexion": "rename.png",
      "Knee Flexion": "rename.png",
      "Spine Strech": "rename.png",
    },
  },
  ...{
    Spine: {
      "Ankle Flexion": "rename.png",
      Spine1: "rename.png",
      Spine2: "rename.png",
    },
  },
  ...{
    "Full Body": {
      "Ankle Flexion": "rename.png",
      "Full Body1 ": "rename.png",
      "Full Body2": "rename.png",
    },
  },
  ...{
    Cognitive: {
      "Ankle Flexion": "rename.png",
      Cognitive1: "rename.png",
      Cognitive2: "rename.png",
    },
  },
};
