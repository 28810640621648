import { createSlice } from "@reduxjs/toolkit";

const patientsSlice = createSlice({
  name: "patientsinfo",
  initialState: { patients: [] },
  reducers: {
    addPatient: (state, action) => {
      state.patients.push(action.payload);
    },
    removePatient: (state, action) => {
      console.log("removeip");
    },
  },
});

export const { addPatient } = patientsSlice.actions;
export default patientsSlice;
