import React from "react";
import betterImage from "../Images/better.png";
import { Link } from "react-router-dom";
import { toggleFullScreen } from "./toggleFullScreen";

export default function Navbar({ activeNavtab }) {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-white  py-0" style={{ fontFamily: "Urbanist" }}>
        <div className="container-fluid px-5">
          <div className="navbar-brand logo" onClick={toggleFullScreen} style={{ cursor: "pointer" }}>
            <img src={betterImage} alt="better" />
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav gap-3 pb-2">
              <li className={`nav-item ${activeNavtab === "Connect" ? "bg-red" : "bg-white"}`}>
                <Link
                  className={`nav-link pt-4 fw-bolder fs-5  ${activeNavtab === "Connect" ? "text-white" : "text-red"}`}
                  aria-current="page"
                  to="/Connect"
                >
                  Connect
                </Link>
              </li>
              <li className={`nav-item ${activeNavtab === "App" ? "bg-red" : "bg-white"}`}>
                <Link
                  className={`nav-link pt-4 fw-bolder fs-5 ${activeNavtab === "App" ? "text-white" : "text-red"}`}
                  to="/Application"
                >
                  App
                </Link>
              </li>
              <li className={`nav-item ${activeNavtab === "Admin" ? "bg-red" : "bg-white"}`}>
                <Link
                  className={`nav-link pt-4 fw-bolder fs-5 ${activeNavtab === "Admin" ? "text-white" : "text-red"}`}
                  to="/Admin"
                >
                  Admin
                </Link>
              </li>
              <li className={`nav-item ${activeNavtab === "Info" ? "bg-red" : "bg-white"}`}>
                <Link
                  className={`nav-link pt-4 fw-bolder fs-5 ${activeNavtab === "Info" ? "text-white" : "text-red"}`}
                  to="/Info"
                >
                  Info
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
