import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import * as THREE from "three";

export default function Sphere() {
  const mountRef = useRef(null);
  const { deviceinfo } = useSelector((store) => store.devicedata);
  const sphere2Ref = useRef(null);

  useEffect(() => {
    // Capture the current value of mountRef
    const mount = mountRef.current;

    // Scene setup
    const scene = new THREE.Scene();

    // Orthographic camera setup for isometric view
    const aspect = window.innerWidth / window.innerHeight;
    const frustumSize = 10; // Controls the zoom level
    const camera = new THREE.OrthographicCamera(
      (-frustumSize * aspect) / 2,
      (frustumSize * aspect) / 2,
      frustumSize / 2,
      -frustumSize / 2,
      0.1,
      1000
    );

    // Position the camera for an isometric view
    camera.position.set(5, 5, 5);
    camera.lookAt(0, 0, 0);

    // Renderer setup
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    mount.appendChild(renderer.domElement);

    // First sphere geometry with smaller size (0.5 radius)
    const geometry1 = new THREE.SphereGeometry(0.1, 32, 32);
    const material1 = new THREE.MeshBasicMaterial({
      color: 0x0077ff,
      wireframe: true,
    }); // Blue color
    const sphere1 = new THREE.Mesh(geometry1, material1);

    // Second sphere geometry with smaller size (0.5 radius)
    const geometry2 = new THREE.SphereGeometry(0.1, 32, 32);
    const material2 = new THREE.MeshBasicMaterial({
      color: 0xff7700,
      wireframe: true,
    }); // Orange color
    const sphere2 = new THREE.Mesh(geometry2, material2);

    // Store reference to sphere2 for later position updates
    sphere2Ref.current = sphere2;

    // Set the initial position of both spheres
    sphere1.position.set(0, 0, 0);
    sphere2.position.set(
      deviceinfo[0]?.xp || 0,
      deviceinfo[0]?.yp || 0,
      deviceinfo[0]?.zp || 0
    );

    // Add spheres to the scene
    scene.add(sphere1);
    scene.add(sphere2);

    // Animation loop with controlled rendering
    let frameId;
    function animate() {
      frameId = requestAnimationFrame(animate);
      renderer.render(scene, camera);
    }

    animate();

    // Handle window resize
    const handleResize = () => {
      const aspect = window.innerWidth / window.innerHeight;
      camera.left = (-frustumSize * aspect) / 2;
      camera.right = (frustumSize * aspect) / 2;
      camera.top = frustumSize / 2;
      camera.bottom = -frustumSize / 2;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Clean up on component unmount
    return () => {
      cancelAnimationFrame(frameId);
      mount.removeChild(renderer.domElement);
      window.removeEventListener("resize", handleResize);
    };
  }, [deviceinfo]);

  // Update position when deviceinfo changes
  useEffect(() => {
    if (sphere2Ref.current) {
      sphere2Ref.current.position.set(
        deviceinfo[0]?.xp || 0,
        deviceinfo[0]?.yp || 0,
        deviceinfo[0]?.zp || 0
      );
    }
  }, [deviceinfo]);

  return (
    <>
      <div>
        <button type="button" className="btn btn-outline-primary">
          Tare
        </button>
        <button type="button" className="btn btn-outline-primary">
          mode4
        </button>
      </div>
      <div ref={mountRef} />
    </>
  );
}
