import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { mode0, tare } from "../../../../Connect/BluetoothHandler";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const MAX_DATA_POINTS = 5000; // Maximum number of data points in the FIFO queue

const MultiAxesModeEleven = () => {
  const { deviceinfo } = useSelector((store) => store.devicedata);
  const [plotValue, setPlotValue] = useState({
    x: [],
    time: [],
    b: 0,
  });

  const [isPaused, setIsPaused] = useState(false);
  const [boolean, setBoolean] = useState(deviceinfo);
  const [shouldPlot, setShouldPlot] = useState(true); // New state to control plotting
  const prevBooleanRef = useRef(deviceinfo);

  useEffect(() => {
    if (deviceinfo[0]?.mode === 0) {
      // Set boolean to empty array if mode is 0
      setBoolean([]);
      setShouldPlot(false); // Stop plotting if mode is 0
    } else {
      // Compare current deviceinfo with previous deviceinfo
      const prevBoolean = prevBooleanRef.current;
      const isDifferent =
        JSON.stringify(deviceinfo) !== JSON.stringify(prevBoolean);

      if (isDifferent) {
        setBoolean(deviceinfo);
        prevBooleanRef.current = deviceinfo; // Update the ref with the new deviceinfo
        if (deviceinfo[0]?.b === 1) {
          setShouldPlot(true); // Start plotting if b is 1
        } else {
          setShouldPlot(false); // Stop plotting if b is 0
        }
      }
    }
  }, [deviceinfo]);

  function spineValueCorrection(value) {
    if (value > 90) {
      return 180 - value;
    } else if (value < -90) {
      return -180 - value;
    } else {
      return value;
    }
  }

  const plotData = useMemo(() => {
    if (boolean.length > 0 && boolean[0]?.mode === 11) {
      return {
        x: spineValueCorrection(boolean[0]?.yaw),
        time: boolean[0]?.time,
        b: boolean[0]?.b,
      };
    }
    return null;
  }, [boolean]);

  useEffect(() => {
    if (plotData && shouldPlot && !isPaused) {
      setPlotValue((prev) => {
        // Create a new object with updated data
        const newPlotValue = {
          x: [...prev.x, plotData.x].slice(-MAX_DATA_POINTS),
          time: [...prev.time, plotData.time].slice(-MAX_DATA_POINTS),
        };

        return newPlotValue;
      });
    }
  }, [plotData, shouldPlot, isPaused]);

  const data = useMemo(
    () => ({
      labels: plotValue.time,
      datasets: [
        {
          label: "x",
          data: plotValue.x,
          fill: false,
          backgroundColor: "blue",
          borderColor: "blue",
        },
      ],
    }),
    [plotValue]
  );

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          title: {
            display: true,
            text: "Time",
          },
        },
        y: {
          title: {
            display: true,
            text: "Value",
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: true,
          text: "Multiple Axes ModeNine Chart",
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "x",
          },
        },
      },
    }),
    []
  );

  const handleStartPlotting = useCallback(() => {
    setIsPaused(false);
  }, []);

  const handlePausePlotting = useCallback(() => {
    setIsPaused(true);
  }, []);

  const handleStartClick = useCallback(() => {
    setPlotValue({
      x: [],
      time: [],
    });
  }, []);

  const handleMode0Click = useCallback(() => {
    mode0();
  }, []);
  const handleModeTare = useCallback(() => {
    tare(0);
  }, []);

  return (
    <>
      <div className="mb-2">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleStartClick}
        >
          Start
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleModeTare}
        >
          Tare
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleMode0Click}
        >
          Mode 0
        </button>
        <button
          type="button"
          className="btn btn-success"
          onClick={handleStartPlotting}
        >
          Start Plotting
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={handlePausePlotting}
        >
          Pause Plotting
        </button>
      </div>
      <div className={plotValue.b === 1 ? `bg-red` : `bg-primary`}>
        {plotValue.b}
      </div>
      <div style={{ width: "100%", height: "68vh" }}>
        <Line data={data} options={options} />
      </div>
    </>
  );
};

export default MultiAxesModeEleven;
